/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { IS_LOADING, REWARD_POINTS, REWARD_TIRES, REMAINING_POINT, CURRENT_TIER, NEXT_TIER, CART_ID, REWARDS_REFERRALS, SHARE_LINK } from './RewardsPoints.action';

export const getInitialState = () => ({
    loading: false,
    rewardPoints: {},
    rewardTires: [],
    currentTier: {},
    remainingPoint: null,
    nextTier: {},
    cartId: null,
    rewardsReferrals: {},
    shareLink: ""
});

export const RewardsPointsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {

    case IS_LOADING:
        const { loading } = action;
        return {
            ...state,
            loading
        };

    case REWARD_POINTS:
        const { rewardPoints } = action;
        return {
            ...state,
            rewardPoints
        };
    case REWARD_TIRES:
        const { rewardTires } = action;
        return {
            ...state,
            rewardTires
        };
    case REMAINING_POINT:
        const { remainingPoint } = action;
        return {
            ...state,
            remainingPoint
        };
    case CURRENT_TIER:
        const { currentTier } = action;
        return {
            ...state,
            currentTier
        };

    case NEXT_TIER:
        const { nextTier } = action;
        return {
            ...state,
            nextTier
        };

    case CART_ID:
        const { cartId } = action;
        return {
            ...state,
            cartId
        };

    case REWARDS_REFERRALS:
        const { rewardsReferrals } = action;
        return {
            ...state,
            rewardsReferrals
        };

    case SHARE_LINK:
        const { shareLink } = action;
        return {
            ...state,
            shareLink
        };

    default:
        return state;
    }
};

export default RewardsPointsReducer;
