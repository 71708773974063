/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable consistent-return */
// import PropTypes from 'prop-types';

import ClickOutside from 'Component/ClickOutside';
import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST
} from 'Component/Header/Header.config';
import Menu from 'Component/Menu';
import OfflineNotice from 'Component/OfflineNotice';
import ShareIcon from 'Component/ShareIcon';
import WishlistQuery from 'Query/Wishlist.query';
// import StoreSwitcher from 'Component/StoreSwitcher';
// import UserIcon from 'Component/UserIcon';
import { isSignedIn } from 'Util/Auth';
import { isCrawler, isSSR } from 'Util/Browser';
import history from 'Util/History';
import { fetchQuery } from 'Util/Request';
import { appendWithStoreCode, isHomePageUrl } from 'Util/Url';

import WhatsappIcon from '../../component/custom/Icons/WhatsappIcon';
import ShopMenu from '../../component/custom/ShopMenu';

export class HeaderPlugin {
    // aroundRenderMap = (originalMember, instance) => ({
    //     ...originalMember,
    //     compare: () => { },
    //     wish: this.renderFavorites.bind(instance)

    // });

    onRegisterButtonClickActions() {
        if (isSignedIn()) {
            history.push({
                pathname: appendWithStoreCode('/my-account/dashboard')
            });

            return;
        }

        history.push({ pathname: appendWithStoreCode('/account/signup/') });
    }

    aroundrenderAccountButton(args, callback, instance) {
        const { onMyAccountButtonClick, device } = instance.props;

        if (device.isMobile) {
            return null;
        }
        if (isSignedIn()) {
            return (
                <div block="Header" elem="MyAccount">
                    {/* <div block="Header" elem="MyAccount_right">
                    { instance.renderWelcomeMessage() }
                    </div> */}
                    <div block="Header" elem="MyAccount_left">
                        {/* <i className="icon-user" /> */}
                        <a
                          href="javascript:void()"
                          onClick={onMyAccountButtonClick}
                          aria-label="User"
                        >
                            <i className="icon-user" />
                        </a>
                    </div>
                </div>
            );
        }

        return (
            <div block="Header" elem="MyAccount">
                <div block="Header" elem="MyAccount_right">
                    <a
                      href="javascript:void()"
                      onClick={onMyAccountButtonClick}
                    >
                        Login
                    </a>
                    <span>/</span>
                    <a
                      href="javascript:void()"
                      onClick={onRegisterButtonClickActions}
                    >
                        Register
                    </a>
                </div>
                <div block="Header" elem="MyAccount_left">
                    <a
                      href="javascript:void()"
                      onClick={onMyAccountButtonClick}
                      aria-label="Profile"
                    >
                        <i className="icon-user" />
                    </a>
                </div>
            </div>
        );
    }

    aroundrenderLogo(args, callback, instance) {
        const {
            device: { isMobile },
            atTop,
            pathname,
            navigationState: { title }
        } = instance.props;

        if (!atTop && !isMobile) {
            return <ShopMenu />;
        }
        if (title) {
            return callback.apply(args, instance);
        }

        return (
            <>
                {callback.apply(args, instance)}
                {isHomePageUrl(pathname) && isMobile && (
                    <div block="Header" elem="slider-wrapper">
                        <div block="Header" elem="slider">
                            <div block="Header" elem="slider-text">
                                {__('Earn 5% Health points on every order')}
                            </div>
                            <div block="Header" elem="slider-text">
                                {__('Free delivery above min order value')}
                            </div>
                            <div block="Header" elem="slider-text">
                                {__(
                                    '360º Wellness Based products formulation'
                                )}
                            </div>
                            <div block="Header" elem="slider-text">
                                {__(
                                    '100% Natural & Stevia sweetened products'
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    renderLogoOrDepartments() {
        const {
            device: { isMobile },
            atTop
        } = this.props;

        function canShowDepartmentsButton() {
            return !atTop && !isMobile;
        }

        return canShowDepartmentsButton() ? (
            <div block="Header" elem="Product-Categories">
                <div block="Header" elem="Toggle">
                    <i className="icon-menu" />
                    <span> Shop by Department</span>
                </div>
                <div block="Header" elem="Content">
                    {/* <Menu
                  source={menuData.product_categories}
                  className="menu--dropdown"
                /> */}
                </div>
            </div>
        ) : (
            this.renderLogo(true)
        );
    }

    aroundrenderShareWishListButton(args, callback, instance) {
        const _args = { ...args[ 0 ] };
        const { isVisible } = _args;
        const { isWishlistLoading, shareWishlist } = instance.props;

        return (
            <button
              key="share"
              block="Header"
              elem="Button"
              mods={{ type: 'share', isVisible }}
              onClick={shareWishlist}
              aria-label="Share"
              aria-hidden={!isVisible}
              disabled={isWishlistLoading}
            >
                <ShareIcon />
            </button>
        );
    }

    // renderFavorites() {
    //     const {
    //         isCheckout,
    //         device: { isMobile }
    //     //     product
    //     } = this.props;
    //     const { wishListCount } = this.state;

    //     fetchQuery(WishlistQuery.getWishlistQuery()).then((response) => {
    //         this.setState({ wishListCount: response.wishlist.items_count });
    //     });
    //     if (isMobile || isCheckout) {
    //         return null;
    //     }
    //     if (!isSignedIn()) {
    //         return null;
    //     }

    //     return (
    //         <div block="Header" elem="MyFavorites">
    //             <a href="#myAccount" block="Header" elem="MyFavorites_button">
    //                 <i className="icon-heart" />
    //                 { true && (
    //                     <span>
    //                         <i>{ wishListCount }</i>
    //                     </span>
    //                 )}
    //             </a>
    //         </div>
    //     );
    // }

    aroundrenderMinicart(args, callback, instance) {
        const {
            onMinicartOutsideClick,
            isCheckout,
            device: { isMobile }
        } = instance.props;
        const [isVisible] = args;

        if (isMobile || isCheckout) {
            return null;
        }

        return (
            <ClickOutside onClick={onMinicartOutsideClick} key="minicart">
                <div mods={{ isVisible, type: 'minicart' }}>
                    {instance.renderMinicartButton()}
                    {instance.renderMinicartOverlay()}
                </div>
            </ClickOutside>
        );
    }

    aroundrenderMinicartButton(args, callback, instance) {
        const {
            onMinicartButtonClick,
            cartTotals: { items_qty }
        } = instance.props;

        if (!items_qty) {
            return (
                <div block="Header" elem="MyCart">
                    <a
                      href="javascript:void(0)"
                      onClick={onMinicartButtonClick}
                      block="Header"
                      elem="MyCart_button"
                      aria-label="Shopping Cart"
                    >
                        <i className="icon-bag2" />
                    </a>
                </div>
            );
        }

        return (
            <div block="Header" elem="MyCart">
                <a
                  href="javascript:void(0)"
                  onClick={onMinicartButtonClick}
                  block="Header"
                  elem="MyCart_button"
                >
                    <i className="icon-bag2" />
                    <span>
                        <i>{instance.renderMinicartItemsQty()}</i>
                    </span>
                </a>
            </div>
        );
    }

    renderAccount(args, callback, instance) {
        const {
            onMyAccountOutsideClick,
            isCheckout,
            device: { isMobile }
        } = instance.props;
        const [isVisible] = args;
        // on mobile hide button if not in checkout
        if (isMobile && !isCheckout) {
            return null;
        }

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <ClickOutside onClick={onMyAccountOutsideClick} key="account">
                <div aria-label="My account" block="Header" elem="MyAccount">
                    {instance.renderAccountButton(isVisible)}
                    {instance.renderAccountOverlay()}
                </div>
            </ClickOutside>
        );
    }

    aroundRenderTopMenu = (args, callback, instance) => {
        const {
            atTop,
            device: { isMobile }
        } = instance.props;

        if (isMobile || !atTop) {
            return null;
        }

        return (
            <div block="Header" elem="Top">
                <div block="Header" elem="Container">
                    {/* <div block="Header" elem="Left">
                        <p></p>
                    </div> */}
                    <div block="Header" elem="slider-wrapper">
                        <div block="Header" elem="slider">
                            <div block="Header" elem="slider-text">
                                <b>
                                    {__('Earn 5% Health points on every order')}
                                </b>
                            </div>
                            <div block="Header" elem="slider-text">
                                <b>{__('Free delivery above min order value')}</b>
                            </div>
                            <div block="Header" elem="slider-text">
                                <b>
                                    {__(
                                        '360º Wellness Based products formulation'
                                    )}
                                </b>
                            </div>
                            <div block="Header" elem="slider-text">
                                <b>
                                    {__(
                                        '100% Natural & Stevia sweetened products'
                                    )}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div block="Header" elem="Right">
                        <div block="Header" elem="Links">
                            <div block="Header" elem="Link">
                                <WhatsappIcon fill="green" />
                                +91 786-282-5003
                            </div>
                            {/* <div block="Header" elem="Link">
                                <a href="#store">Store Location</a>
                            </div>
                            <div block="Header" elem="Link">
                                <a href="#store">Track Your Order</a>
                            </div>
                            <div block="Header" elem="Link">
                                <StoreSwitcher />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    aroundRenderMenu(args, callback, instance) {
        const {
            isCheckout,
            device: { isMobile },
            atTop
        } = instance.props;

        if (isMobile || isCheckout || !atTop) {
            return null;
        }

        return <Menu />;
    }

    render(args, callback, instance) {
        const { stateMap } = instance;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device: { isMobile },
            pathname,
            atTop
        } = instance.props;

        // if (isMobile
        //     && isHomePageUrl(pathname) && !atTop) {
        //     return null;
        // }
        if (!isMobile) {
            // hide edit button on desktop
            stateMap[ CUSTOMER_WISHLIST ].edit = false;
            stateMap[ CUSTOMER_WISHLIST ].share = false;
            stateMap[ CART_OVERLAY ].edit = false;
        }

        return (
            <section
              block="Header"
              elem="Wrapper"
              mods={{ isPrerendered: isSSR() || isCrawler() }}
            >
                <header
                  block="Header"
                  mods={{ name, isHiddenOnMobile, isCheckout }}
                  mix={{ block: 'FixedElement', elem: 'Top' }}
                  ref={instance.logoRef}
                >
                    {instance.renderTopMenu()}
                    <nav block="Header" elem="Nav">
                        {instance.renderNavigationState()}
                    </nav>
                    {instance.renderMenu()}
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

const {
    aroundRenderTopMenu,
    aroundrenderLogo,
    aroundrenderMinicart,
    aroundrenderMinicartButton,
    aroundRenderMenu,
    aroundrenderAccountButton,
    onRegisterButtonClickActions,
    render
} = new HeaderPlugin();

export const config = {
    'Component/Header/Component': {
        'member-function': {
            renderTopMenu: aroundRenderTopMenu,
            renderMenu: aroundRenderMenu,
            renderAccountButton: aroundrenderAccountButton,
            renderLogo: [
                {
                    position: 100,
                    implementation: aroundrenderLogo
                }
            ],
            renderMinicartButton: aroundrenderMinicartButton,
            renderMinicart: aroundrenderMinicart,
            render
        }
    }
};

export default config;
