/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';
import { ChildrenType, MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';

import HamburgerIcon from '../custom/Icons/HamburgerIcon';

import './ExpandableContent.style';

/** @namespace LifelyScandi/Component/ExpandableContent/Component/ExpandableContentComponent */
export class ExpandableContentComponent extends SourceExpandableContent {
    static propTypes = {
        isContentExpanded: PropTypes.bool,
        isArrow: PropTypes.bool,
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        children: ChildrenType,
        menu: PropTypes.bool,
        mix: MixType.isRequired,
        mods: PropTypes.object,
        device: DeviceType.isRequired,
        onClick: (props, propName, componentName) => {
            const propValue = props[ propName ];
            if (propValue === null) {
                return;
            }
            if (typeof propValue === 'function') {
                return;
            }
            throw new Error(`${ componentName } only accepts null or string`);
        }
    };

    static defaultProps = {
        heading: '',
        subHeading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: false,
        mods: {}
    };

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;
        const { menu } = this.props;
        if (menu) {
            if (isContentExpanded) {
                return <ChevronIcon direction={ TOP } />;
            }

            return <HamburgerIcon />;
        }
        if (!menu) {
            if (isContentExpanded) {
                return <MinusIcon />;
            }

            return <AddIcon />;
        }

        return true;
    }

    renderItemCount() {
        const { itemCount } = this.props;
        return (
            <div
              block="ExpandableContent"
              elem="ItemCounterContainer"
            >
                <div
                  block="ExpandableContent"
                  elem="ItemCounter"
                >
                    { itemCount }
                </div>
            </div>
        );
    }

    renderButton() {
        const { isContentExpanded, buttonInVisible } = this.state;
        const {
            heading, mix, itemCount, subHeading
        } = this.props;
        let CustomPageExpandableButton = '';
        if (location
            && (location.pathname.toLowerCase().indexOf('/checkout') !== -1
                || location.pathname.toLowerCase().indexOf('/cart') !== -1)) {
            CustomPageExpandableButton = 'ShippingPageExpandableButton';
        }
        if (location
            && (location.pathname.toLowerCase().indexOf('.html') !== -1)) {
            CustomPageExpandableButton = 'ProductPageExpandableButton';
        }

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              className={ CustomPageExpandableButton }
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                <div
                  block="ExpandableContent"
                  elem="Header"
                >
                    <div
                      block="ExpandableContent"
                      elem="Heading"
                      mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                    >
                        { typeof heading === 'string' ? (
                            <TextPlaceholder content={ heading } length="medium" />
                        ) : (
                            heading
                        ) }
                    </div>
                    { subHeading
                        && subHeading !== ''
                        && (
                            <div
                              block="ExpandableContent"
                              elem="SubHeading"
                              mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                            >
                                { (subHeading && typeof subHeading === 'string') ? (
                                    <TextPlaceholder content={ subHeading } length="medium" />
                                ) : (
                                    subHeading
                                ) }
                            </div>
                        ) }
                </div>

                { itemCount && !isContentExpanded && this.renderItemCount() }
                { !buttonInVisible && this.renderButtonIcon() }
            </div>
        );
    }

    render() {
        const { mix, mods, key } = this.props;

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
              key={ key || '' }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;
