/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/prop-types */
import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import media from 'Util/Media';
import { getSortedItems } from 'Util/Menu';

import './MobileMenuPanel.style';

/** @namespace LifelyScandi/Component/Custom/MobileMenuPanel/Component/MobileMenuPanelComponent */
export class MobileMenuPanelComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired
    };

    state = {
        height: 0
    };

    componentDidMount() {
        // const { height } = this.state;
        if (document.querySelector('.menu_panel__inner')) {
            window.setTimeout(() => {
                const contentHeight = document.querySelector('.menu_panel__inner').scrollHeight;

                this.setState({
                    height: contentHeight
                });
            }, 333);
        }
    }

    // MENU SPECIFIC PANEL START

    renderMobileSecondLevelPanel() {
        const { panel, activateTab } = this.props;
        const { item } = panel;
        const customerTitleClass = item && item.item_class && item.item_class === 'unLaunchedItem'
            ? 'unLaunchedItemTitle' : 'menuTitle';
        const customerIconClass = item && item.item_class && item.item_class === 'unLaunchedItem'
            ? 'unLaunchedItemIcon' : 'menuIcon';
        const { icon_alt } = item;

        const iconAlt = (icon_alt && icon_alt !== '') ? JSON.parse(icon_alt) : {};

        let url = item.url.pathname;
        if (iconAlt && Object.keys(iconAlt).length > 0 && iconAlt.cms_block_id && iconAlt.cms_block_id !== '') {
            url = `${ item.url.pathname }?cms_block_id=${ iconAlt.cms_block_id }`;
        }

        return (
            <div>
                { item.url && !panel.isExpanded && (
                    <Link
                      to={ url }
                      className="menu_panel__title__with__image_empty_content menuContent"
                      id={ item.item_id }
                    >
                        <div className={ `menu_panel__parent__image ${ customerIconClass }` }>
                            <img src={ media(item.icon || '') } alt="" />
                        </div>
                        <div className={ `menu_panel__parent__title ${ customerTitleClass }` }>
                            { item.title || '' }
                        </div>
                    </Link>
                ) }
                { panel.isExpanded && (
                    <button
                      className="menu_panel__title__with__image menuContent"
                      role="tab"
                      onClick={ activateTab }
                    >
                        <div className={ `menu_panel__parent__image ${ customerIconClass }` }>
                            <img src={ media(item.icon || '') } alt="" />
                        </div>
                        <div className={ `menu_panel__parent__title ${ customerTitleClass }` }>
                            { item.title || '' }
                        </div>
                    </button>
                ) }
            </div>
        );
    }

    // MENU SPECIFIC PANEL END

    render() {
        const {
            key,
            activeTab,
            panel,
            index
        } = this.props;
        const { height } = this.state;
        const isActive = activeTab === index;
        const innerStyle = {
            height: isActive ? 'auto' : 0
        };

        const { item } = panel;
        const childrenArray = item && item.children ? getSortedItems(Object.values(item.children)) : {};
        return (
            <div block="MobileMenuPanel">
                <div
                  className={ panel.mobileMenuAccordian ? 'menu_panel menuPanel' : 'menu_panel' }
                  style={ { backgroundColor: panel.bgColor ? panel.bgColor : '#f5f5db' } }
                  role="tabpanel"
                  aria-expanded={ isActive }
                >

                    { /* MOBILE MENU PANEL START */ }

                    { panel.mobileMenuAccordian && this.renderMobileSecondLevelPanel() }
                    { panel.mobileMenuAccordian && (
                        <div
                          className="menu_panel__inner"
                          style={ innerStyle }
                          aria-hidden={ !isActive }
                        >
                            <div className="menu_panel__horizontalline" />
                            { childrenArray.map((item) => {
                                const { icon_alt } = item;

                                const iconAlt = (icon_alt && icon_alt !== '') ? JSON.parse(icon_alt) : {};

                                let url = item.url.pathname;
                                if (iconAlt
                                    && Object.keys(iconAlt).length > 0
                                    && iconAlt.cms_block_id
                                    && iconAlt.cms_block_id !== '') {
                                    url = `${ item.url.pathname }?cms_block_id=${ iconAlt.cms_block_id }`;
                                }

                                return (
                                    <Link
                                      to={ url }
                                      className={ `menu_panel__title__with__image_empty_content menuContent 
                                        ${ item.item_class === 'unLaunchedSubItem' ? 'unLaunchedSubItem' : '' }` }
                                      id={ item.item_id }
                                    >
                                        <div className="menu_panel__parent__image menuIcon">
                                            <img src={ media(item.icon || '') } alt="" />
                                        </div>
                                        <div className="menu_panel__parent__title">
                                            { item.title || '' }
                                        </div>
                                    </Link>
                                );
                            }) }
                        </div>
                    ) }

                    { /* MOBILE MENU PANEL END */ }

                </div>
            </div>
        );
    }
}

export default MobileMenuPanelComponent;
