/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
 import { PureComponent } from 'react';
 import { connect } from 'react-redux';
 import RewardPointsNotification from './RewardPointsNotification.component';
 export const RewardPointsDispatcher = import(
    '../../store/RewardsPoints/RewardsPoints.dispatcher'
);
 
/** @namespace Component/RewardPointsNotification/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartMstRewardPoints: state.RewardsPointsReducer.rewardPoints,
    itemsQty: state.CartReducer.cartTotals.items_qty
});

/** @namespace Component/RewardPointsNotification/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getRewardsPoints: () => RewardPointsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getRewardsPoints(dispatch)
    )
});

/** @namespace Component/RewardPointsNotification/Container */
export class RewardPointsNotificationContainer extends PureComponent {

    __construct(props) {
        super.__construct(props);
        const {getRewardsPoints}=this.props
        getRewardsPoints()
    }

    render() {
        const {itemsQty}=this.props
        if(itemsQty == 0){
            return null
        }
        return (
            <RewardPointsNotification
               { ...this.props }
            />
        );
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RewardPointsNotificationContainer);
 