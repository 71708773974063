/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MobileMenuAccordian from './MobileMenuAccordian.component';

/** @namespace LifelyScandi/Component/Custom/MobileMenuAccordian/Container/MobileMenuAccordianContainer */
export class MobileMenuAccordianContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        const { panels } = this.props;
        return {
            panels
        };
    };

    render() {
        return (
            <MobileMenuAccordian
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default MobileMenuAccordianContainer;
