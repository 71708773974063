import {
    lazy,
    Suspense
} from 'react';

export const SearchOverlay = lazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "overlay" */
        'Component/SearchOverlay'
    )
);
class SearchFieldPlugin {
    aroundRenderSearch(args, callback, instance) {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device
        } = instance.props;

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="search-field"
                  ref={ instance.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ instance.handleChange }
                  onKeyDown={ instance.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  placeholder={ __('Search superfood products') }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { instance.renderSearchIcon() }
                <Suspense fallback={ instance.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay={ !device.isMobile }
                      clearSearch={ instance.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </div>
        );
    }
}
const { aroundRenderSearch } = new SearchFieldPlugin();
export const config = {
    'Component/SearchField/Component': {
        'member-function': {
            renderSearch: [
                {
                    position: 100,
                    implementation: aroundRenderSearch
                }
            ]

        },
        'member-property': {
        }
    }
};

export default config;
