/* eslint-disable no-magic-numbers */
import { connect } from 'react-redux';

import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { getProductInStock } from 'Util/Product/Extract';

/** @namespace LifelyScandi/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    static defaultProps = {
        product: {},
        selectedFilters: {},
        isPreview: false,
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        siblingsHaveBrands: false,
        setSiblingsHaveBrands: () => null,
        siblingsHavePriceBadge: false,
        setSiblingsHavePriceBadge: () => null,
        siblingsHaveConfigurableOptions: false,
        layout: GRID_LAYOUT,
        isLinkedProduct: false,
        isCartPageLinkedProduct: false
    };

    containerProps() {
        const {
            children,
            device,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            isWishlistEnabled,
            layout,
            mix,
            product,
            renderContent,
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            siblingsHaveBrands,
            siblingsHaveConfigurableOptions,
            siblingsHavePriceBadge,
            isLinkedProduct,
            isCartPageLinkedProduct
        } = this.props;
        const { configurableVariantIndex, parameters } = this.state;

        return {
            children,
            device,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            isWishlistEnabled,
            layout,
            mix,
            product,
            renderContent,
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            siblingsHaveBrands,
            siblingsHaveConfigurableOptions,
            siblingsHavePriceBadge,
            configurableVariantIndex,
            parameters,
            currentVariantIndex: this._getCurrentVariantIndex(),
            productOrVariant: this._getProductOrVariant(),
            thumbnail: this._getThumbnail(),
            linkTo: this._getLinkTo(),
            inStock: getProductInStock(product, configurableVariantIndex),
            isLinkedProduct,
            isCartPageLinkedProduct
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
