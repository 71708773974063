/* eslint-disable max-len */
import PropTypes from 'prop-types';

import {
    CloseIcon as SourceCloseIcon
} from 'SourceComponent/CloseIcon/CloseIcon.component';

import './CloseIcon.style';

/** @namespace LifelyScandi/Component/CloseIcon/Component/CloseIconComponent */
export class CloseIconComponent extends SourceCloseIcon {
    static propTypes = {
        fill: PropTypes.string,
        height: PropTypes.string,
        width: PropTypes.string
    };

    static defaultProps = {
        fill: '#000000',
        height: '24',
        width: '24'
    };

    render() {
        const { fill, width, height } = this.props;
        return (
            <svg
              block="CloseIcon"
              width={ width }
              height={ height }
              viewBox="0 0 24 24"
              fill={ fill }
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M16.192 6.34399L11.949 10.586L7.707 6.34399L6.293 7.75799L10.535 12L6.293 16.242L7.707 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" />
            </svg>
        );
    }
}

export default CloseIconComponent;
