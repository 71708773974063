/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';

import OfferDetailBottomSheet from './OfferDetailBottomSheet.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace LifelyScandi/Component/Custom/OfferDetailBottomSheet/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace LifelyScandi/Component/Custom/OfferDetailBottomSheet/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    applyCouponToCart: (couponCode) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applyCouponToCart(dispatch, couponCode)
    )
});

/** @namespace LifelyScandi/Component/Custom/OfferDetailBottomSheet/Container/OfferDetailBottomSheetContainer */
export class OfferDetailBottomSheetContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        handleClickOutside: this.handleClickOutside.bind(this),
        tapToCopyCouponCode: this.tapToCopyCouponCode.bind(this),
        applyCouponCode: this.applyCouponCode.bind(this)
    };

    containerProps() {
        const { BottomSheetIsVisible, showAllOffersDetail, showSpecificOfferDetail } = this.props;
        const { isLoading } = this.state;
        return {
            BottomSheetIsVisible, showAllOffersDetail, showSpecificOfferDetail, isLoading
        };
    }

    handleClickOutside() {
        const { closeBottomSheet } = this.props;
        closeBottomSheet();
    }

    tapToCopyCouponCode(coupon) {
        const { showNotification } = this.props;
        const link = document.createElement('input');
        link.value = coupon;
        document.body.appendChild(link);
        link.select();
        document.execCommand('copy');
        document.body.removeChild(link);
        showNotification('success', 'Coupon Code Copied');
    }

    async applyCouponCode(couponCode) {
        const { applyCouponToCart, closeBottomSheet } = this.props;
        this.setState({ isLoading: true });
        await applyCouponToCart(couponCode).then(
            /** @namespace LifelyScandi/Component/Custom/OfferDetailBottomSheet/Container/applyCouponToCart/then */

            () => this.setState({ isLoading: false })
        );
        await closeBottomSheet();
    }

    render() {
        return (
            <OfferDetailBottomSheet
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailBottomSheetContainer);
