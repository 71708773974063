import {
    OfflineNotice as SourceOfflineNotice
} from 'SourceComponent/OfflineNotice/OfflineNotice.component';

import './OfflineNotice.style';

/** @namespace LifelyScandi/Component/OfflineNotice/Component/OfflineNoticeComponent */
export class OfflineNoticeComponent extends SourceOfflineNotice {
    // TODO implement logic
}

export default OfflineNoticeComponent;
