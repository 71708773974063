// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Menu from 'Component/Menu';

import './ShopMenu.style';

/** @namespace LifelyScandi/Component/Custom/ShopMenu/Component/ShopMenuComponent */
export class ShopMenuComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <div block="ShopMenu">
                { /* TODO: Implement render method */ }
                <Menu shopMenuOnly />
            </div>
        );
    }
}

export default ShopMenuComponent;
