import { connect } from 'react-redux';

import {
    CartCouponContainer as SourceCartCouponContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CartCoupon/CartCoupon.container';

/** @namespace LifelyScandi/Component/CartCoupon/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile

    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/CartCoupon/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/CartCoupon/Container/CartCouponContainer */
export class CartCouponContainer extends SourceCartCouponContainer {
    containerProps() {
        const { isLoading } = this.state;
        const {
            couponCode, mix, title, isMobile, BottomSheetIsVisible
        } = this.props;

        return {
            isLoading,
            couponCode,
            mix,
            title,
            isMobile,
            toggleOffersListBottomSheetAction: this.toggleOffersListBottomSheetAction.bind(this),
            BottomSheetIsVisible
        };
    }

    handleApplyCouponToCart(couponCode) {
        const { applyCouponToCart, onCouponCodeUpdate, getProductItemSpecialPrice } = this.props;

        this.setState({ isLoading: true });

        applyCouponToCart(couponCode).then(
            /** @namespace LifelyScandi/Component/CartCoupon/Container/applyCouponToCart/then */
            () => onCouponCodeUpdate()
        ).finally(
            /** @namespace Component/CartCoupon/Container/applyCouponToCartFinally */
            () => {
                this.setState({ isLoading: false });
                getProductItemSpecialPrice();
            }
        );
    }

    handleRemoveCouponFromCart() {
        const { removeCouponFromCart, onCouponCodeUpdate, getProductItemSpecialPrice } = this.props;

        this.setState({ isLoading: true });

        removeCouponFromCart().then(
            /** @namespace LifelyScandi/Component/CartCoupon/Container/removeCouponFromCart/then */
            () => onCouponCodeUpdate()
        ).finally(
            /** @namespace Component/CartCoupon/Container/removeCouponFromCartFinally */
            () => {
                this.setState({ isLoading: false });
                getProductItemSpecialPrice();
            }
        );
    }

    toggleOffersListBottomSheetAction() {
        const { toggleOffersListBottomSheet } = this.props;
        toggleOffersListBottomSheet();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartCouponContainer);
