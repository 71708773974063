/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getStoreConfigFieldsAction = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('website_id')
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _getStoreConfigFieldsAction
        }
    }
};