/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-undef */
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION, ERROR_NOTIFICATION_LIFETIME, ERROR_TYPE, NOTIFICATION_LIFETIME
} from './Notification.config';

import './Notification.style';

/** @namespace LifelyScandi/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotification {
    componentDidMount() {
        const { notification: { msgType, msgLifetimeNumber } } = this.props;
        // Make sure error notification stays a little longer
        if (msgLifetimeNumber === -1) {
            this.hideTimeout = () => false;
        } else if (msgType.toLowerCase() === ERROR_TYPE) {
            this.hideTimeout = setTimeout(() => this.hideNotification(), msgLifetimeNumber || ERROR_NOTIFICATION_LIFETIME);
        } else {
            this.hideTimeout = setTimeout(() => this.hideNotification(), msgLifetimeNumber || NOTIFICATION_LIFETIME);
        }

        CSS.setVariable(this.notification, 'animation-duration', `${ ANIMATION_DURATION }ms`);
    }
}

export default NotificationComponent;
