import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

import './Meta.style';

/** @namespace LifelyScandi/Component/Meta/Component/MetaComponent */
export class MetaComponent extends SourceMeta {
    renderMeta() {
        const { metadata } = this.props;
        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
            </>
        );
    }
}

export default MetaComponent;
