/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getAddressFields = (args, callback, instance) => [
    ...callback(...args),
    new Field('address_instructions'),
    new Field('address_security_code'),
    new Field('delivery_preferance_sat'),
    new Field('delivery_preferance_sun')
];

export default {
    'Query/MyAccount': {
        'member-function': {
            _getAddressFields: {
                position: 100,
                implementation: _getAddressFields
            }
        }
    }
};
