/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const TOGGLE_STICKY_HEADER = 'TOGGLE_STICKY_HEADER';

/** @namespace LifelyScandi/Store/Header/Action/toggleStickyHeader */
export const toggleStickyHeader = (atTop) => ({
    type: TOGGLE_STICKY_HEADER,
    atTop
});
