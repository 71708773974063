Mosaic.setPlugins([require('./plugin/App.plugin.js'),
require('./plugin/CheckoutOrderSummary/CheckoutOrderSummaryContainer.plugin.js'),
require('./plugin/DrawerCart/AddToCartContainer.plugin.js'),
require('./plugin/DrawerCart/Header.plugin.js'),
require('./plugin/DrawerCart/HeaderContainer.plugin.js'),
require('./plugin/DrawerCart/MyAccountMyWishlistContainer.plugin.js'),
require('./plugin/DrawerCart/WishlistItemContainer.plugin.js'),
require('./plugin/Header/Header.plugin.js'),
require('./plugin/Header/HeaderContainer.plugin.js'),
require('./plugin/Header/Menu.plugin.js'),
require('./plugin/Header/SearchField.plugin.js'),
require('./plugin/Header/StoreSwitcher.plugin.js'),
require('./plugin/Menu/Menu.plugin.js'),
require('./plugin/MyAccount/MyAccount.plugin.js'),
require('./plugin/ProductList/ProductListQuery.plugin.js'),
require('./plugin/SliderWidget/SliderQuery.plugin.js'),
require('./plugin/SliderWidget/SliderWidget.plugin.js'),
require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/@lifely/wellness-program/src/plugin/MyAccount.plugin.js'),
require('./../packages/@lifely/custom-fields/src/plugin/Address.plugin.js'),
require('./../packages/@lifely/custom-fields/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@lifely/custom-fields/src/plugin/MyAccountAddressForm.plugin.js'),
require('./../packages/@lifely/custom-fields/src/plugin/MyAccountQuery.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/App.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/CartCoupon.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/CartItem.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/CartPage.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/CheckoutBilling.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/CheckoutOrderSummary.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/MyAccount.plugin.js'),
require('./../packages/@lifely/mirasvit-reward-points/src/plugin/Router.plugin.js'),
require('./../packages/@lifely/update-mobileno/src/plugin/App.plugin.js'),
require('./../packages/@lifely/update-mobileno/src/plugin/MyAccountCustomerTable.plugin.js'),
require('./../packages/@lifely/update-mobileno/src/plugin/MyAccountQuery.plugin.js'),
require('./../packages/@lifely/auth/src/plugin/App.plugin.js'),
require('./../packages/@lifely/auth/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@lifely/auth/src/plugin/Header.plugin.js'),
require('./../packages/@lifely/auth/src/plugin/Reducer.plugin.js'),
require('./../packages/@lifely/auth/src/plugin/Router.plugin.js'),
require('./../packages/@scandipwa/razorpay-payments/src/plugin/CartQuery.plugin.js'),
require('./../packages/@scandipwa/razorpay-payments/src/plugin/CheckoutPayments.component.plugin.js'),
require('./../packages/@scandipwa/razorpay-payments/src/plugin/CheckoutPayments.container.plugin.js'),
require('./../packages/@scandipwa/razorpay-payments/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@scandipwa/stripe-payments/src/plugin/Checkout.plugin.js'),
require('./../packages/@scandipwa/stripe-payments/src/plugin/CheckoutBilling.plugin.js'),
require('./../packages/@scandipwa/stripe-payments/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/@scandipwa/stripe-payments/src/plugin/CheckoutContainer.plugin.js'),
require('./../packages/@scandipwa/stripe-payments/src/plugin/CheckoutPayments.plugin.js'),
require('./../packages/@scandipwa/stripe-payments/src/plugin/CheckoutPaymentsContainer.plugin.js')]);
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import React from 'react';
import { render } from 'react-dom';
import { register } from 'register-service-worker';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// TODO: move this out to i18-runtime
PropTypes.string = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        if (process.env.NODE_ENV === 'production') {
            const swUrl = '/service-worker.js';
            // navigator.serviceWorker.register(swUrl, { scope: '/' });
            register(swUrl, {
                registrationOptions: { scope: '/' },
                ready() {
                    console.log('Service worker is active.');
                },
                registered() {
                    console.log('Service worker has been registered.');
                },
                cached() {
                    console.log('Content has been cached for offline use.');
                },
                updatefound() {
                    window.location.reload(true);
                    console.log('New content is downloading.');
                },
                updated() {
                    console.log('New content is available; please refresh.');
                },
                offline() {
                    console.log('No internet connection found. App is running in offline mode.');
                },
                error(error) {
                    console.error('Error during service worker registration:', error);
                }
            });
        }
    });
}

render(<App />, document.getElementById('root'));
