import { isSignedIn } from 'Util/Auth';
import { toggleScroll } from 'Util/Browser';
import { CONFIGURABLE } from 'Util/Product';
import { appendWithStoreCode } from 'Util/Url';

import { currentSlidePane, toggleDrawerCart } from '../../store/custom/DrawerCart/DrawerCart.action';

export class WishlistItemContainerPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;
        return {
            ...callback(...args),
            isMobile: state.ConfigReducer.device.isMobile
        };
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;
        return {
            ...callback(...args),
            toggleDrawerCart: (type) => dispatch(toggleDrawerCart(type)),
            currentSlidePane: (slidePaneName) => dispatch(currentSlidePane(slidePaneName))
        };
    };

    aroundContainerProps(args, callback, instance) {
        const { isMobile } = instance.props;
        return { ...callback.apply(instance, args), isMobile };
    }

    addItemToCartActions = (args, callback, instance) => {
        const {
            product: item, addProductToCart, showNotification, toggleDrawerCart, isMobile, currentSlidePane
        } = instance.props;
        const {
            type_id,
            variants,
            wishlist: {
                id, sku, quantity, buy_request
            }
        } = item;

        if (!isSignedIn()) {
            return null;
        }

        if (type_id === CONFIGURABLE) {
            const configurableVariantIndex = instance.getConfigurableVariantIndex(sku, variants);

            if (!configurableVariantIndex) {
                history.push({ pathname: appendWithStoreCode(item.url) });
                showNotification('info', __('Please, select product options!'));

                return Promise.resolve();
            }

            item.configurableVariantIndex = configurableVariantIndex;
        }

        instance.setState({ isLoading: true });

        return addProductToCart({ product: item, quantity, buyRequest: buy_request })
            .then(
                /** @namespace Component/WishlistItem/Container/addItemToCartAddProductToCartThen */
                () => {
                    instance.removeItem(id);
                    if (!isMobile) {
                        currentSlidePane('cart');
                        toggleDrawerCart(true);
                        toggleScroll(false);
                    } else {
                        showNotification('success', __('Product Added To Cart'));
                    }
                },
                /** @namespace Component/WishlistItem/Container/addItemToCartAddProductToCartCatch */
                () => instance.showNotification('error', __('Error Adding Product To Cart'))
            )
            .catch(
                /** @namespace Component/WishlistItem/Container/addItemToCartAddProductToCartThenThenCatch */
                () => instance.showNotification('error', __('Error cleaning wishlist'))
            );
    };
}
export const {
    addItemToCartActions, aroundContainerProps, mapStateToProps, mapDispatchToProps
} = new WishlistItemContainerPlugin();
export default {
    'Component/WishlistItem/Container': {
        'member-function': {
            addItemToCart: [
                {
                    position: 100,
                    implementation: addItemToCartActions
                }
            ],
            containerProps: aroundContainerProps
        }
    },
    'Component/WishlistItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/WishlistItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
