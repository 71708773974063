import { Field } from 'Util/Query';

const _getCustomerFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('mobileNumber')
    ];
};

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: {
                position: 101,
                implementation: _getCustomerFields
            }
        }
    }
};