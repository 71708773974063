/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { ChevronIcon as SourceChevronIcon } from 'SourceComponent/ChevronIcon/ChevronIcon.component';
import { DirectionType } from 'Type/Direction';

import { RIGHT } from './ChevronIcon.config';

import './ChevronIcon.style';

/** @namespace LifelyScandi/Component/ChevronIcon/Component/ChevronIconComponent */
export class ChevronIconComponent extends SourceChevronIcon {
    static propTypes = {
        direction: DirectionType,
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        direction: RIGHT,
        fill: 'black',
        width: '20',
        height: '20'
    };

    render() {
        const {
            direction, fill, width, height
        } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width={ width }
              height={ height }
              viewBox="0 0 16 16"
              fill={ fill }
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
            >
                <path d="M5.8535 13.707L11.5605 7.99997L5.8535 2.29297L4.4395 3.70697L8.7325 7.99997L4.4395 12.293L5.8535 13.707Z" />
            </svg>
        );
    }
}

export default ChevronIconComponent;
