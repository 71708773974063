/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Link from 'Component/Link';
import {
    CheckoutOrderSummaryPriceLine as SourceCheckoutOrderSummaryPriceLine
} from 'SourceComponent/CheckoutOrderSummaryPriceLine/CheckoutOrderSummaryPriceLine.component';
import { ChildrenType } from 'Type/Common';

import './CheckoutOrderSummaryPriceLine.style';

/** @namespace LifelyScandi/Component/CheckoutOrderSummaryPriceLine/Component/CheckoutOrderSummaryPriceLineComponent */
export class CheckoutOrderSummaryPriceLineComponent extends SourceCheckoutOrderSummaryPriceLine {
    render() {
        const {
            price,
            title,
            mods,
            children
        } = this.props;

        if (!price) {
            return null;
        }

        return (
            <li block="CheckoutOrderSummary" elem="SummaryItem" mods={ mods }>
                <div className="TitleWrapper">
                    { title === 'Payable Amount' && (
                        <p block="CheckoutOrderSummary" elem="PayableAmountTitle">
                            { title }
                        </p>
                    ) }
                    { title !== 'Payable Amount' && (
                        <p block="CheckoutOrderSummary" elem="Text">
                            { title }
                        </p>
                    ) }
                </div>
                { title === 'Payable Amount' && (
                    <strong block="CheckoutOrderSummary" elem="PayableAmount">
                        { this.renderPrice() }
                        { this.renderSubPrice() }
                    </strong>
                ) }
                { title !== 'Payable Amount' && (
                    <strong block="CheckoutOrderSummary" elem="Text">
                        { this.renderPrice() }
                        { this.renderSubPrice() }
                    </strong>
                ) }
                { children }
            </li>
        );
    }
}

export default CheckoutOrderSummaryPriceLineComponent;
