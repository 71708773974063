/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import ContentWrapper from 'Component/ContentWrapper';
import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';
import media from 'Util/Media';

import './SomethingWentWrong.style';

/** @namespace LifelyScandi/Route/SomethingWentWrong/Component/SomethingWentWrongComponent */
export class SomethingWentWrongComponent extends SourceSomethingWentWrong {
    render() {
        const { onClick } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper
                  label="Something went wrong on the page."
                  wrapperMix={ { block: 'SomethingWentWrong', elem: 'Wrapper' } }
                >
                    <div block="SomethingWentWrong" elem="SomethingWentWrongImageWrapper">
                        <img
                          src={ media('assets/something-went-wrong.webp') }
                          alt=""
                        />
                    </div>
                    <h1 block="SomethingWentWrong" elem="Heading">{ __('Ooops!') }</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{ __('Something went wrong!') }</h2>
                    <a
                      href={ location.pathname }
                      block="SomethingWentWrong"
                      elem="Button"
                      mix={ { block: 'Button' } }
                      onClick={ onClick }
                    >
                        { __('Refresh Now') }
                    </a>
                    { this.renderErrorDetails() }
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrongComponent;
