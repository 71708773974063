import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';

/** @namespace LifelyScandi/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    // TODO implement logic
}

export default ProductPriceComponent;
