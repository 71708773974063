/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
import { lazy } from 'react';

import AddToCart from 'Component/AddToCart';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import media from 'Util/Media';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';

import Ribbon from '../custom/Ribbon';

import 'animate.css';
import './ProductCard.style';

export const animate = lazy(() => import('animate.css'));

/** @namespace LifelyScandi/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends SourceProductCard {
    renderProductActions() {
        const {
            product: { weight, description }
        } = this.props;

        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};

        if (descriptionHtml && descriptionHtml.externalOrderLink) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderVisibleOnHover() {
        const { product, device } = this.props;

        if (device && device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderConfigurableOptions() }
                <div block="ProductCard" elem="Footer">
                    { Object.keys(product).length > 0
                        && !device.isMobile
                        && this.renderProductDetailWrapper() }
                    { Object.keys(product).length > 0
                        && this.renderFooter() }
                </div>
            </>
        );
    }

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail, isCartPageLinkedProduct } = this.props;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ {
                  block: 'ProductCard', elem: 'Picture', mix, mods: { isCartPageLinkedProduct }
              } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderProductPrice() {
        const {
            product: {
                price_range, price_tiers = [], type_id
            },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
            isCartPageLinkedProduct
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <ProductPrice
                  price={ price_range }
                  price_tiers={ price_tiers }
                  mix={ { block: 'ProductCard', elem: 'Price', mods: { isCartPageLinkedProduct } } }
                  label={ this.renderProductTypePriceBadge() }
                />
            </div>
        );
    }

    renderFooter() {
        const { isCartPageLinkedProduct, isLinkedProduct } = this.props;
        return (
            <div block="ProductCard" elem="ActionFooter" mods={ { isCartPageLinkedProduct, isLinkedProduct } }>
                { this.renderAddToCart() }
            </div>
        );
    }

    renderProductDetailWrapper() {
        return (
            <div block="ProductCard" elem="ProductDetailWrapper">
                { this.renderProductDetail() }
            </div>
        );
    }

    renderProductWeightWrapper() {
        const {
            product: { weight, description }, isCartPageLinkedProduct
        } = this.props;

        if (!description) {
            return null;
        }
        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};
        return (
            <div block="ProductCard" elem="ProductWeight" mods={ { isCartPageLinkedProduct } }>
                { descriptionHtml
                    && descriptionHtml.meta
                    && !descriptionHtml.meta.unit && weight && (
                    <>
                            (
                            { weight < 1000 ? `${ weight }gm` : `${ weight / 1000 }kg` }
                            )
                    </>
                ) }
                { descriptionHtml
                    && descriptionHtml.meta
                    && descriptionHtml.meta.unit && (
                        <span>
                            (
                            { descriptionHtml.meta.unit }
                            )
                        </span>
                ) }
            </div>
        );
    }

    renderAddToCart() {
        const {
            product,
            product: {
                type_id,
                options = [],
                configurable_options: confOptions = {},
                description
            },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock,
            isCartPageLinkedProduct
        } = this.props;
        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};

        if (descriptionHtml && descriptionHtml.externalOrderLink) {
            return null;
        }

        const quantity = 1;
        const groupedProductQuantity = {};
        const requiredOptions = options.reduce(
            (acc, { option_id, required }) => {
                if (required) {
                    acc.push(option_id);
                }

                return acc;
            },
            []
        );

        const productOptionsData = {
            requiredOptions
        };

        const redirectOnConfig = type_id === CONFIGURABLE
            && Object.keys(confOptions).length
            !== Object.keys(this.getAttributesToShow()).length;

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button
                  block="ProductCard"
                  elem="AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'Button' } }
                >
                    { __('Go to Product') }
                </button>
            );
        }

        return (
            <AddToCart
              product={ product }
              isCartPageLinkedProduct={ isCartPageLinkedProduct }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductCard', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
              disabled={ !inStock }
              layout={ layout }
            />
        );
    }

    renderProductInfoWrapper() {
        return (
            <div block="ProductCard" elem="ProductInfoWrapper">
                <Ribbon title="You're avoiding" />
            </div>
        );
    }

    renderMainDetails() {
        const {
            product,
            product: { description, name },
            device
        } = this.props;

        if (!description) {
            return null;
        }
        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};

        return (
            <>
                <div block="ProductCard" elem="MainDetails">
                    <p
                      block="ProductCard"
                      elem="Name"
                      mods={ { isLoaded: !!name } }
                    >
                        <TextPlaceholder content={ name } length="medium" />
                        { device.isMobile
                            && Object.keys(product).length > 0
                            && this.renderProductWeightWrapper() }
                    </p>
                    { !device.isMobile && name && (
                        <div
                          block="ProductCard"
                          elem="ProductTagLine"
                          className="animate__animated"
                        >
                            { (descriptionHtml
                                && descriptionHtml.meta
                                && descriptionHtml.meta.tagLine) || 'Having Wellness Ingredients' }
                        </div>
                    ) }
                    { !device.isMobile
                        && Object.keys(product).length > 0
                        && this.renderProductWeightWrapper() }
                </div>
                { /* { !device.isMobile && this.renderProductActions() } */ }
            </>
        );
    }

    renderProductDetail() {
        const {
            product: { description }
        } = this.props;

        if (!description) {
            return null;
        }
        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};

        let singleItemClass = '';

        if (descriptionHtml
            && descriptionHtml.meta
            && descriptionHtml.meta.avoid
            && Object.keys(descriptionHtml.meta.avoid).length === 1) {
            singleItemClass = 'singleItemClass';
        }

        return (
            <div block="ProductCard" elem="ProductDetail" className={ singleItemClass }>

                { descriptionHtml
                    && descriptionHtml.meta
                    && descriptionHtml.meta.avoid
                    && descriptionHtml.meta.avoid.sugarCubes
                    && (
                        <div
                          block="ProductCard"
                          elem="Item"
                          className={ `animate__animated ${ singleItemClass ? 'down' : 'left' }` }
                        >
                            <div block="ProductCard" elem="ProductItem">
                                <img src={ media('assets/sugar-cubes.webp') } alt="" />
                            </div>
                            <div block="ProductCard" elem="ItemLabel">
                                { __('Sugar Cubes') }
                            </div>
                            <div block="ProductCard" elem="ItemDetail">
                                { descriptionHtml.meta.avoid.sugarCubes }
                            </div>
                        </div>
                    ) }
                { descriptionHtml
                    && descriptionHtml.meta
                    && descriptionHtml.meta.avoid
                    && (descriptionHtml.meta.avoid.calories || descriptionHtml.meta.avoid.calories === '')
                    && (
                        <div
                          block="ProductCard"
                          elem="Item"
                          className={ `animate__animated ${ singleItemClass ? 'down' : 'right' }` }
                        >
                            <div block="ProductCard" elem="ProductItem">
                                <img src={ media('assets/calories.webp') } alt="" />
                            </div>
                            <div block="ProductCard" elem="ItemLabel">
                                { __('Calories') }
                            </div>
                            <div block="ProductCard" elem="ItemDetail">
                                { descriptionHtml.meta.avoid.calories }
                            </div>
                        </div>
                    ) }
            </div>
        );
    }

    renderCardContent() {
        const {
            renderContent,
            product,
            product: { id, description },
            device,
            isLinkedProduct
        } = this.props;

        if (isLinkedProduct) {
            return null;
        }
        if (renderContent) {
            return renderContent(this.contentObject);
        }
        if (!description) {
            return null;
        }
        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};

        return this.renderCardLinkWrapper(
            <>
                { !device.isMobile && this.renderMainDetails() }
                { !device.isMobile && this.renderProductActions() }

                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>

                { /* <div block="ProductCard" elem="Content">
                    { this.renderAdditionalProductDetails() }
                </div> */ }
                { device.isMobile && (
                    <div block="ProductCard" elem="ProductDetailContainer">
                        <div>
                            { this.renderMainDetails() }
                            { this.renderReviews() }
                        </div>
                        <div>
                            { this.renderProductPrice() }
                        </div>
                    </div>
                ) }

                { !device.isMobile && (
                    <div block="ProductCard" elem="PriceContainer">
                        { this.renderProductPrice() }
                    </div>
                ) }

                <div block="ProductCard" elem="VisibleOnHover">
                    { Object.keys(product).length > 0
                        && this.renderProductInfoWrapper() }
                    { !device.isMobile && this.renderReviews() }
                    { this.renderVisibleOnHover() }
                </div>
            </>
        );
    }

    renderCartLinkedProductCard() {
        const {
            device: { isMobile }, product, product: { description, name }, isCartPageLinkedProduct
        } = this.props;

        if (!description) {
            return null;
        }
        const descriptionHtml = description && description.html !== '' ? JSON.parse(description.html) : {};
        return (
            <>
                { !isMobile && (
                    <div
                      block="ProductLinkedCard"
                      elem="Wrapper"
                    >
                        <div block="ProductLinkedCard" elem="FigureReview" mods={ { isCartPageLinkedProduct } }>
                            <figure block="ProductLinkedCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductLinkedCard" elem="CartItemRows">
                            <p
                              block="ProductCard"
                              elem="Name"
                              mods={ { isLoaded: !!name, isCartPageLinkedProduct } }
                            >
                                <TextPlaceholder content={ name } length="medium" />
                                { Object.keys(product).length > 0
                                    && this.renderProductWeightWrapper() }
                            </p>
                            <div block="ProductLinkedCard" elem="ProductActions">
                                <div
                                  block="ProductCard"
                                  elem="ProductTagLine"
                                  mods={ { isCartPageLinkedProduct } }
                                >
                                    { descriptionHtml
                                        && descriptionHtml.meta
                                        && descriptionHtml.meta.tagLine.length > 60
                                        && descriptionHtml.meta.tagLine
                                        ? `${ descriptionHtml.meta.tagLine.slice(0, 60) }...` : descriptionHtml.meta.tagLine }
                                </div>
                            </div>
                        </div>
                        { Object.keys(product).length > 0
                            && this.renderFooter() }
                    </div>
                ) }
                { isMobile
                    && (
                        <div
                          block="ProductLinkedCard"
                          elem="Wrapper"
                        >
                            <div block="ProductLinkedCard" elem="FigureReview" mods={ { isCartPageLinkedProduct } }>
                                <figure block="ProductLinkedCard" elem="Figure">
                                    { this.renderPicture() }
                                </figure>
                            </div>
                            <div block="ProductLinkedCard" elem="CartItemRows">
                                <p
                                  block="ProductCard"
                                  elem="Name"
                                  mods={ { isLoaded: !!name, isCartPageLinkedProduct } }
                                >
                                    <TextPlaceholder content={ name } length="medium" />
                                    { Object.keys(product).length > 0
                                        && this.renderProductWeightWrapper() }
                                </p>
                                <div
                                  block="ProductCard"
                                  elem="ProductTagLine"
                                  className="animate__animated"
                                >
                                    { descriptionHtml
                                        && descriptionHtml.meta
                                        && descriptionHtml.meta.tagLine.length > 40
                                        && descriptionHtml.meta.tagLine
                                        ? `${ descriptionHtml.meta.tagLine.slice(0, 40) }...` : descriptionHtml.meta.tagLine }
                                </div>
                            </div>
                            { Object.keys(product).length > 0
                                && this.renderFooter() }
                        </div>
                    ) }
            </>
        );
    }

    // renderProductTypePriceBadge() {
    //     const {
    //         product: { type_id, price_tiers = [] },
    //         siblingsHavePriceBadge,
    //         setSiblingsHavePriceBadge
    //     } = this.props;

    //     const label = getPriceLabel(type_id, price_tiers);
    //     if (label && !siblingsHavePriceBadge) {
    //         setSiblingsHavePriceBadge();
    //     }

    //     return label;
    // }

    renderLinkedProductCard() {
        const { device: { isMobile }, product } = this.props;
        return (
            <>
                { !isMobile && this.renderCardLinkWrapper(
                    <>
                        { this.renderMainDetails() }
                        { this.renderProductActions() }
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="PriceContainer">
                            { this.renderProductPrice() }
                        </div>
                        <div block="ProductCard" elem="VisibleOnHover">
                            { Object.keys(product).length > 0
                                && this.renderProductInfoWrapper() }
                            { this.renderReviews() }
                            { this.renderVisibleOnHover() }
                        </div>
                    </>
                ) }
                { isMobile
                    && (
                        <>
                            { this.renderCardLinkWrapper(
                                <>
                                    <div block="ProductCard" elem="FigureReview">
                                        <figure block="ProductCard" elem="Figure">
                                            { this.renderPicture() }
                                        </figure>
                                    </div>
                                    <div block="ProductCard" elem="ProductDetailContainer">
                                        <div>
                                            { this.renderMainDetails() }
                                            { this.renderReviews() }
                                        </div>
                                        <div>
                                            { this.renderProductPrice() }
                                        </div>
                                    </div>
                                </>
                            ) }
                            { Object.keys(product).length > 0
                                && this.renderFooter() }
                        </>
                    ) }
            </>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
            isLinkedProduct,
            isCartPageLinkedProduct
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
            isLinkedProduct
        };

        if (isLinkedProduct && isCartPageLinkedProduct) {
            return (
                <li
                  block="ProductLinkedCard"
                  mods={ { isLinkedProduct, isCartPageLinkedProduct } }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCartLinkedProductCard() }
                </li>
            );
        }

        if (isLinkedProduct && !isCartPageLinkedProduct) {
            return (
                <li
                  block="ProductCard"
                  mods={ mods }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderLinkedProductCard() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
