import UpdateMobileReducer from '../store/UpdateMobile/UpdateMobile.reducer';

const getStaticReducers = (args, callback, instance) => ({
    ...callback(...args),
    UpdateMobileReducer
 });

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

