/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const IS_LOADING = 'IS_LOADING';
export const TAB = 'TAB';

/** @namespace Lifely/Auth/Store/Signup/Action/isLoading */
export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading
});

/** @namespace Lifely/Auth/Store/Signup/Action/updateTab */
export const updateTab = (tab) => ({
    type: TAB,
    tab
});
