import {
    StoreSwitcher as SourceStoreSwitcher
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import './StoreSwitcher.style';

/** @namespace LifelyScandi/Component/StoreSwitcher/Component/StoreSwitcherComponent */
export class StoreSwitcherComponent extends SourceStoreSwitcher {
    // TODO implement logic
}

export default StoreSwitcherComponent;
