/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EditWithoutFrameIcon.style';

/** @namespace LifelyScandi/Component/Custom/Icons/EditWithoutFrameIcon/Component/EditWithoutFrameIconComponent */
export class EditWithoutFrameIconComponent extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        fill: '#000000',
        width: '25',
        height: '25'
    };

    render() {
        const { fill, width, height } = this.props;
        return (

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={ width }
              height={ height }
              zoomAndPan="magnify"
              viewBox="0 0 37.5 37.499999"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
            >
                <defs>
                    <clipPath id="EditWithoutFrameIcon1">
                        <path d="M 0 0 L 37.007812 0 L 37.007812 37.007812 L 0 37.007812 Z M 0 0 " clipRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath="url(#EditWithoutFrameIcon1)">
                    <path fill={ fill } d="M 30.265625 0.488281 C 29.796875 0.0234375 28.96875 0.0234375 28.503906 0.488281 L 3.128906 25.863281 C 3.128906 25.863281 3.128906 25.863281 3.128906 25.867188 L 3.125 25.871094 C 3.121094 25.871094 3.121094 25.875 3.117188 25.875 C 3.019531 25.980469 2.941406 26.101562 2.882812 26.234375 C 2.867188 26.265625 2.851562 26.296875 2.839844 26.332031 C 2.828125 26.359375 2.8125 26.386719 2.804688 26.417969 L 0.167969 35.921875 C 0.0507812 36.355469 0.171875 36.816406 0.488281 37.136719 C 0.726562 37.371094 1.042969 37.5 1.371094 37.5 C 1.480469 37.5 1.59375 37.484375 1.703125 37.453125 L 11.207031 34.820312 C 11.238281 34.8125 11.265625 34.796875 11.292969 34.785156 C 11.328125 34.773438 11.355469 34.757812 11.390625 34.742188 C 11.523438 34.683594 11.644531 34.605469 11.746094 34.503906 C 11.75 34.503906 11.753906 34.503906 11.753906 34.5 L 11.757812 34.496094 C 11.757812 34.496094 11.761719 34.496094 11.761719 34.492188 L 37.136719 9.121094 C 37.367188 8.886719 37.5 8.570312 37.5 8.242188 C 37.5 7.910156 37.367188 7.59375 37.136719 7.359375 Z M 25.132812 7.382812 L 30.242188 12.492188 L 10.875 31.859375 L 5.765625 26.75 Z M 3.160156 34.464844 L 4.632812 29.144531 L 8.480469 32.988281 Z M 32.003906 10.730469 L 26.894531 5.621094 L 29.382812 3.132812 L 34.492188 8.242188 Z M 32.003906 10.730469 " fillOpacity="1" fillRule="nonzero" />
                </g>
            </svg>
        );
    }
}

export default EditWithoutFrameIconComponent;
