import { isSignedIn } from 'Util/Auth';
export const RewardPointsDispatcher = import(
    '../store/RewardsPoints/RewardsPoints.dispatcher'
);

const handleApplyCouponToCart = async (args, callback, instance) => {
    if(isSignedIn()) {
        const {getRewardsPoints}=instance.props
        await callback(...args)
        await getRewardsPoints()
        return 
    }

    return callback(...args)
}

const handleRemoveCouponFromCart = async (args, callback, instance) => {
    if(isSignedIn()) {
        const {getRewardsPoints}=instance.props
        await callback(...args)
        await getRewardsPoints()
        return 
    }

    return callback(...args)
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
 
    return {
        ...callback(...args),
        getRewardsPoints: () => RewardPointsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getRewardsPoints(dispatch)
        )
    };
 };

export default {
    'Component/CartCoupon/Container': {
        'member-function': {
            handleApplyCouponToCart: {
                position: 100,
                implementation: handleApplyCouponToCart
            },
            handleRemoveCouponFromCart: {
                position: 100,
                implementation: handleRemoveCouponFromCart
            }
        }
    },
    'Component/CartCoupon/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};