import {
    DEFAULT_MAX_PRODUCTS as SOURCE_DEFAULT_MAX_PRODUCTS,
    ONE_HUNDRED_PERCENT as SOURCE_ONE_HUNDRED_PERCENT
} from 'SourceComponent/ProductActions/ProductActions.config';

// TODO: implement DEFAULT_MAX_PRODUCTS
export const DEFAULT_MAX_PRODUCTS = SOURCE_DEFAULT_MAX_PRODUCTS;

// TODO: implement ONE_HUNDRED_PERCENT
export const ONE_HUNDRED_PERCENT = SOURCE_ONE_HUNDRED_PERCENT;
