import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/NotificationList/NotificationList.container';
import NotificationReducer from 'Store/Notification/Notification.reducer';
import { withReducers } from 'Util/DynamicReducer';

import NotificationList from './NotificationList.component';

/** @namespace LifelyScandi/Component/NotificationList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/NotificationList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

export default withReducers({
    NotificationReducer
})(connect(mapStateToProps, mapDispatchToProps)(NotificationList));
