/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { isSignedIn } from 'Util/Auth';
import media from 'Util/Media';
import { appendWithStoreCode } from 'Util/Url';

import CopyIcon from '../Icons/CopyIcon';
import WhatsappIcon from '../Icons/WhatsappIcon';

import './ReferLifely.style';

/** @namespace LifelyScandi/Component/Custom/ReferLifely/Component/ReferLifelyComponent */
export class ReferLifelyComponent extends PureComponent {
    static propTypes = {
        redirectToLoginAction: PropTypes.func.isRequired
    };

    renderReferralCard() {
        const {
            redirectToLoginAction, shareLink, base_url, copyReferralLinkUrlHandler, redirectToRegisterAction
        } = this.props;
        const referUrl = base_url.slice(0, -1) + appendWithStoreCode(shareLink);
        return (
            <div block="ReferLifely" elem="ReferralCardContainer">
                <div block="ReferLifely" elem="ReferralCardSectionOne">
                    <img
                      src={ media('assets/refer-lifely.webp') }
                      alt=""
                      loading="lazy"
                    />
                </div>
                <div block="ReferLifely" elem="ReferralCardSectionTwo">
                    { isSignedIn() && (
                        <div block="ReferLifely" elem="ReferralLinkSection">
                            <div block="ReferLifely" elem="ReferralUrlDescription">
                                <ul>
                                    <li>Earn 15 points for every referral signup.</li>
                                    <li>Get additional 15 points when they first order.</li>
                                </ul>
                            </div>
                            <div block="ReferLifely" elem="ReferralUrlViewContainer">
                                <p>{ referUrl }</p>
                            </div>
                            <div block="ReferLifely" elem="ReferralSharingOptionsContainer">
                                <div block="ReferLifely" elem="ReferralSharingOption">
                                    <div
                                      style={ { display: 'noen' } }
                                      type="text"
                                      id="ReferLifelyLinkInputField"
                                    />
                                    <a
                                      href={ `https://api.whatsapp.com/send?text= Hey! I tried these Healthy Foods from Lifely. They are the cleanest and nutrition rich daily life style food you'll ever find! Sign Up using this link and get 30% off on your first order! ${ referUrl }` }
                                      target="_blank"
                                      rel="noreferrer"
                                      aria-label="Whatsapp Sharing"
                                    >
                                        <WhatsappIcon width="40" height="40" />
                                    </a>
                                    <span>
                                        Share Via Whatsapp
                                    </span>
                                </div>
                                <div block="ReferLifely" elem="ReferralSharingOption">
                                    <button
                                      block="ReferLifely"
                                      elem="ReferralCopyLinkAction"
                                      onClick={ copyReferralLinkUrlHandler }
                                      aria-label="Copy"
                                    >
                                        <CopyIcon width="40" height="40" />
                                    </button>
                                    <span>
                                        Copy Link
                                    </span>
                                </div>
                            </div>

                        </div>
                    ) }
                    { !isSignedIn() && (
                        <div block="ReferLifely" elem="GuestUserSection">
                            <p>Please Login / Register to start referring.</p>
                            <div block="ReferLifely" elem="GuestUserAuthSection">
                                <button block="Button" onClick={ redirectToLoginAction }>
                                    Login
                                </button>
                                <button block="Button" onClick={ redirectToRegisterAction }>
                                    Register
                                </button>
                            </div>
                        </div>
                    ) }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="ReferLifely">
                { this.renderReferralCard() }
            </div>
        );
    }
}

export default ReferLifelyComponent;
