/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import Field from 'Component/Field';
import { VALIDATION_STATUS } from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import {
    CartCoupon as SourceCartCoupon
} from 'SourceComponent/CartCoupon/CartCoupon.component';

import CancelIcon from '../custom/Icons/CancelIcon';
import OfferIcon from '../custom/Icons/OfferIcon';

import './CartCoupon.style';

/** @namespace LifelyScandi/Component/CartCoupon/Component/CartCouponComponent */
export class CartCouponComponent extends SourceCartCoupon {
    renderOfferIcon() {
        const { toggleOffersListBottomSheetAction } = this.props;
        return (
            <button
              block="CartCoupon"
              aria-label="Offer"
              elem="OffersIcon"
              onClick={ toggleOffersListBottomSheetAction }
            >
                <OfferIcon fill="rgb(53,95,63)" width="30" height="30" />
            </button>
        );
    }

    renderApplyCoupon() {
        const { isMobile } = this.props;
        const { enteredCouponCode } = this.state;
        const { skip, success } = VALIDATION_STATUS;

        return (
            <div
              block="CartCoupon"
              elem="ButtonContainer"
            >
                <Field
                  type="text"
                  id="couponCode"
                  name="couponCode"
                  value={ enteredCouponCode }
                  placeholder={ __('COUPON CODE') }
                  onChange={ this.handleCouponCodeChange }
                  customValidationStatus={ !enteredCouponCode ? skip : success }
                  mix={ { block: 'CartCoupon', elem: 'Input', mods: { emptyField: !enteredCouponCode } } }
                  aria-label={ __('Your Coupon code') }
                />
                { isMobile
                    && enteredCouponCode
                    && (
                        <div
                          block="CartCoupon"
                          elem="ButtonWrapper"
                        >
                            <button
                              block="CartCoupon"
                              elem="Button"
                              className="btnHollowSecondaryBase"
                              type="button"
                              mods={ { isHollow: true } }
                              disabled={ !enteredCouponCode }
                              onClick={ this.handleApplyCoupon }
                            >
                                { __('Apply') }
                            </button>
                        </div>
                    ) }
                { !isMobile && (
                    <button
                      block="CartCoupon"
                      elem="Button"
                      className="btnHollowSecondaryBase"
                      type="button"
                      mods={ { isHollow: true } }
                      disabled={ !enteredCouponCode }
                      onClick={ this.handleApplyCoupon }
                    >
                        { __('Apply') }
                    </button>
                ) }
                { isMobile && !enteredCouponCode && this.renderOfferIcon }
            </div>
        );
    }

    renderRemoveCoupon() {
        const { couponCode } = this.props;

        return (
            <div block="CartCoupon" elem="AppliedCouponCardContainer">
                <p block="CartCoupon" elem="Message">
                    { __('Applied Coupon : ') }
                    <span>{ couponCode.toUpperCase() }</span>
                </p>
                <button
                  block="CartCoupon"
                  elem="Remove"
                  aria-label="Remove Coupon"
                  type="button"
                  onClick={ this.handleRemoveCoupon }
                >
                    <CancelIcon fill="white" />
                </button>
            </div>
        );
    }

    render() {
        const {
            isLoading, couponCode, mix, isMobile
        } = this.props;

        const { enteredCouponCode } = this.state;

        if (!isMobile) {
            return (
                <form block="CartCoupon" onSubmit={ this.handleFormSubmit } mix={ mix }>
                    <Loader isLoading={ isLoading } />
                    { this.renderTitle() }
                    { couponCode ? this.renderRemoveCoupon() : this.renderApplyCoupon() }
                </form>
            );
        }

        return (
            <div
              block="CartCoupon"
              mods={ {
                  MobileViewCouponContainer: !!(!enteredCouponCode && !couponCode),
                  appliedValidCoupon: couponCode
              } }
            >
                <form onSubmit={ this.handleFormSubmit } mix={ mix } style={ isMobile ? { width: '100%' } : {} }>
                    <Loader isLoading={ isLoading } />
                    { this.renderTitle() }
                    { couponCode ? this.renderRemoveCoupon() : this.renderApplyCoupon() }
                </form>
                { !enteredCouponCode && !couponCode && this.renderOfferIcon() }
            </div>
        );
    }
}

export default CartCouponComponent;
