/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import MenuItem from 'Component/MenuItem';
import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';

import './Menu.style';

/** @namespace LifelyScandi/Component/Menu/Component/MenuComponent */
export class MenuComponent extends SourceMenu {
    renderSubMenuDesktopItems = (item) => {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                    { /* { this.renderAdditionalInformation() } */ }
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    };

    renderSubLevelItems = (item, isSecondLevel) => {
        const {
            activeMenuItemsStack,
            closeMenu
        } = this.props;

        const { item_id } = item;

        const subcategoryMods = { type: 'subcategory', isSecondLevel };

        let withIconMenuItem = '';

        const iconAlt = (item && item.icon_alt !== '') ? JSON.parse(item.icon_alt) : {};

        if (!iconAlt || iconAlt.top_parent_id !== 2) {
            withIconMenuItem = 'withIconMenuItem';
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              onClick={ this.stopPropagation }
              role="button"
              tabIndex="-1"
              className={ withIconMenuItem }
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  itemMods={ subcategoryMods }
                  closeMenu={ closeMenu }
                  isLink
                  isSubLevelItems
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);
        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    { /* { this.renderAdditionalInformation(true) } */ }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu
        } = this.props;
        const itemMods = { type: 'main' };

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ itemMods }
              onCategoryHover={ onCategoryHover }
              closeMenu={ closeMenu }
              isLink
              isFirstLevelItems
            />
        );
    }

    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;
        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderCurrencySwitcher() }
                { this.renderStoreSwitcher() }
                { /* { this.renderComparePageLink() } */ }
            </>
        );
    }

    // MOBILE SPECIFIC MENU START

    renderMobileTopLevel() {
        const {
            menu, topLevelItemBtnAction, device, firstLevelItemId
        } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }
        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));
        let itemId = '';
        if (firstLevelItemId !== '') {
            itemId = firstLevelItemId;
        }
        if (firstLevelItemId === undefined || firstLevelItemId === '') {
            itemId = '2';
        }

        return (
            <>
                <div block="Menu" elem="MobileMenuMainCategories">
                    <ul
                      block="Menu"
                      elem="MobileTopLevelItem"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >

                        { childrenArray.map((firstLevelItem) => {
                            if (device.isMobile
                                && (firstLevelItem.title.toLowerCase() === 'shop'
                                    || firstLevelItem.title.toLowerCase() === 'healthiest ingredients'
                                    || firstLevelItem.title.toLowerCase() === 'health goals')) {
                                return (
                                    <button id="topLevelBtn" className={ firstLevelItem.item_id === itemId ? 'isActiveFirstLevelCategoryItem' : 'isInActiveFirstLevelCategoryItem' } onClick={ () => topLevelItemBtnAction(firstLevelItem) }>
                                        <li>
                                            { firstLevelItem.title.toLowerCase() === 'shop' ? 'Category' : null }
                                            { firstLevelItem.title.toLowerCase() === 'healthiest ingredients' ? 'Ingredients' : null }
                                            { firstLevelItem.title.toLowerCase() === 'health goals' ? 'Health Goals' : null }
                                        </li>
                                    </button>
                                );
                            }

                            return null;
                        }) }
                    </ul>
                </div>
                <div>
                    { childrenArray.map(this.renderMobileFirstLevel) }
                </div>
            </>
        );
    }

    renderMobileFirstLevel = (item) => {
        const { firstLevelItemId } = this.props;

        let itemId = '';
        if (firstLevelItemId !== '') {
            itemId = firstLevelItemId;
        }
        if (firstLevelItemId === undefined || firstLevelItemId === '') {
            itemId = '2';
        }

        return (
            <>
                { item.item_id === itemId
                    && (
                        <li
                          block="Menu"
                          elem="FirstLevelItem"
                        >
                            { this.renderMobileFirstLevelItems(item) }
                        </li>
                    ) }
            </>
        );
    };

    renderMobileFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { item_id } = item;
        const itemMods = { type: 'main' };

        return (
            <div
              onClick={ (e) => handleSubcategoryClick(e, item) }
              tabIndex="0"
              block="Menu"
              elem="SubCatLink"
              role="button"
              aria-label="Menu"
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  itemMods={ { ...itemMods, isExpanded: activeMenuItemsStack.includes(item_id) } }
                  onCategoryHover={ onCategoryHover }
                  closeMenu={ closeMenu }
                  isExpandable
                />
            </div>
        );
    }

    renderMobileMenu() {
        return (
            <div
              block="Menu"
              elem="MenuWrapper"
            >
                { this.renderMobileTopLevel() }
            </div>
        );
    }

    // MOBILE SPECIFIC MENU END

    render() {
        const { closeMenu, device: { isMobile } } = this.props;
        if (isMobile) {
            return this.renderMobileMenu();
        }

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >
                { this.renderTopLevel() }
            </div>
        );
    }
}

export default MenuComponent;
