/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-unused-vars */
import validationConfig from 'Component/Form/Form.config';
import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

import {
    CHECKBOX_TYPE
} from './Field.config';
/** @namespace LifelyScandi/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    updateValidationStatus() {
        const validationRule = this.validateField();
        if (Object.keys(validationRule).length > 0) {
            this.setState({
                validationStatus: !validationRule.validate,
                validationMessage: validationRule.message
            });
        }
    }
}

export default FieldContainer;
