import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/StarIcon/StarIcon.component';

import './StarIcon.style';

/** @namespace LifelyScandi/Component/StarIcon/Component/StarIconComponent */
export class StarIconComponent extends SourceAddIcon {
    // TODO implement logic
}

export default StarIconComponent;
