import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { appendWithStoreCode } from 'Util/Url';

import {
    REFERRAL_INVITED,
    REFERRAL_LINK
} from '../route/Url.config';

export const Invited = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    '../route/Invited'
));

export const Linked = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    '../route/Linked'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: <Route
          path={ appendWithStoreCode(REFERRAL_INVITED) }
          render={ (props) => <Invited { ...props } /> }
        />,
        position: 100
    },
    {
        component: <Route
          path={ appendWithStoreCode(REFERRAL_LINK) }
          render={ (props) => <Linked { ...props } /> }
        />,
        position: 101
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
