import { RAZORPAY } from '../component/Razorpay/Razorpay.config';

export class CheckoutPaymentsContainer {
  aroundContainerFunctions = (originalMember, instance) => ({
      ...originalMember,
      setRazorpayRef: this.setRazorpayRef.bind(instance)
  });

  aroundDataMap = (originalMember, instance) => ({
      ...originalMember,
      [RAZORPAY]: this.getRazorpayData.bind(instance)
  });

  containerProps = (args, callback, instance) => {
      const { totals } = instance.props;
      return {
          ...callback.apply(args, instance),
          totals
      };
  };

  getRazorpayData() {
      const asyncData = this.razorpayRef.rzpInit(this.props);
      return {
          asyncData
      };
  }

  /** Instance context */
  setRazorpayRef(ref) {
      this.razorpayRef = ref;
  }
}

const {
    aroundContainerFunctions,
    aroundDataMap,
    containerProps
} = new CheckoutPaymentsContainer();

export const config = {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            containerFunctions: aroundContainerFunctions,
            dataMap: aroundDataMap
        },
        'member-function': {
            containerProps
        }
    }
};

export default config;
