import RewardsPointsReducer from '../store/RewardsPoints/RewardsPoints.reducer';

const getStaticReducers = (args, callback, instance) => ({
    ...callback(...args),
    RewardsPointsReducer
 });

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};
