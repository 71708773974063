import { RAZORPAY } from '../component/Razorpay/Razorpay.config';
import RazorpayContainer from '../component/Razorpay/Razorpay.container';

export class CheckoutPaymentsComponent {
  aroundPaymentRenderMap = (originalMember, instance) => ({
      ...originalMember,
      [RAZORPAY]: this.renderRazorpay.bind(instance)
  });

  renderRazorpay() {
      const {
          setRazorpayRef, totals
      } = this.props;

      return (
            <RazorpayContainer
              setRazorpayRef={ setRazorpayRef }
              totals={ totals }
            />
      );
  }
}

const {
    aroundPaymentRenderMap
} = new CheckoutPaymentsComponent();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: aroundPaymentRenderMap
        }
    }
};

export default config;
