/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React from 'react';

import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

export const SummaryRewardsDiscount = React.lazy(() => import('../component/SummaryRewardsDiscount'));
export const CheckoutTotalRewardPoints = React.lazy(() => import('../component/CheckoutTotalRewardPoints'));
export const CartUseRewardPoints = React.lazy(() => import('../component/CartUseRewardPoints'));

const renderSubTotal = (args, callback, instance) => {
    if (isSignedIn()) {
        return (
            <>
                { callback.apply(instance, args) }
                { <CheckoutTotalRewardPoints /> }
            </>
        );
    }

    return callback.apply(instance, args);
};

const renderDiscount = (args, callback, instance) => {
    if (isSignedIn()) {
        return (
            <>
                { callback.apply(instance, args) }
                { <SummaryRewardsDiscount /> }
            </>
        );
    }

    return callback.apply(instance, args);
};

const renderHeading = (args, callback, instance) => {
    if (isSignedIn()) {
        if (instance.isMobile) {
            const getCartPage = history.location.pathname;
            const arr = getCartPage.split('/');
            if (arr[ arr.length - 1 ] === 'cart') {
                return (
                    <>
                        { callback.apply(instance, args) }
                        { /* { <CartUseRewardPoints /> } */ }
                    </>
                );
            }
        } else {
            return (
                <>
                    { callback.apply(instance, args) }
                    { /* { <CartUseRewardPoints /> } */ }
                </>
            );
        }
    }

    return callback.apply(instance, args);
};

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderSubTotal: {
                position: 100,
                implementation: renderSubTotal
            },
            renderDiscount: {
                position: 110,
                implementation: renderDiscount
            },
            renderHeading: {
                position: 120,
                implementation: renderHeading
            }
        }
    }
};
