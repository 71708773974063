export class CheckoutOrderSummaryContainerPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;
        return {
            ...callback(...args),
            isMobile: state.ConfigReducer.device.isMobile
        };
    };

    aroundContainerProps(args, callback, instance) {
        const { isMobile } = instance.props;
        return { ...callback.apply(instance, args), isMobile };
    }
}
export const {
    aroundContainerProps, mapStateToProps
} = new CheckoutOrderSummaryContainerPlugin();
export default {
    'Component/CheckoutOrderSummary/Container': {
        'member-function': {
            containerProps: aroundContainerProps
        }
    },
    'Component/CheckoutOrderSummary/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
