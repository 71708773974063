/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable fp/no-delete */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import getStore from 'Util/Store';

const INCLUDING_ADDRESS_STORE_CODE = 'en_US';

const renderfieldMap = (args, callback, instance) => {
    const state = getStore().getState();

    const {
        ConfigReducer: { store_code }
    } = state;
    let original = { ...args };

    let address_instructions = '';
    let address_security_code = '';
    let delivery_preferance_sat = false;
    let delivery_preferance_sun = false;

    // Manage Edit Form Start
    const { address } = callback.props;

    if (Object.keys(address).length > 0 && address.address_instructions) {
        address_instructions = address.address_instructions;
    }
    if (Object.keys(address).length > 0 && address.address_security_code) {
        address_security_code = address.address_security_code;
    }
    if (Object.keys(address).length > 0 && address.delivery_preferance_sat) {
        delivery_preferance_sat = true;
    }
    if (Object.keys(address).length > 0 && address.delivery_preferance_sun) {
        delivery_preferance_sun = true;
    }

    // Add New Fields
    const customFields = {
        address_instructions: {
            label: __('Address Instructions'),
            type: 'text',
            value: address_instructions,
            validation: ['notEmpty']
        },
        address_security_code: {
            label: __('Address Security Code'),
            type: 'text',
            value: address_security_code,
            validation: ['notEmpty']
        },
        delivery_preferance_sat: {
            label: __('Delivery Preferance Sat'),
            type: 'checkbox',
            value: delivery_preferance_sat,
            checked: Boolean(delivery_preferance_sat)
        },
        delivery_preferance_sun: {
            label: __('Delivery Preferance Sun'),
            type: 'checkbox',
            value: delivery_preferance_sun,
            checked: Boolean(delivery_preferance_sun)
        }
    };

    // Remove unwated address here
    if (INCLUDING_ADDRESS_STORE_CODE !== store_code) {
        delete customFields.address_instructions;
        delete customFields.address_security_code;
        delete customFields.delivery_preferance_sat;
        delete customFields.delivery_preferance_sun;
    }

    original = { ...args, ...customFields };

    return original;
};

export default {
    'Component/MyAccountAddressForm/Component': {
        'member-function': {
            fieldMap: {
                position: 150,
                implementation: renderfieldMap
            }
        }
    }
};
