import SignupReducer from '../store/Signup/Signup.reducer';
import SignInReducer from '../store/SignIn/SignIn.reducer';

const getStaticReducers = (args, callback, instance) => ({
    ...callback(...args),
    SignupReducer,
    SignInReducer
});

export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

export default config;
