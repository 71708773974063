/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-bind */
import ChevronIcon from 'Component/ChevronIcon';
import Link from 'Component/Link';
import {
    MenuItem as SourceMenuItem
} from 'SourceComponent/MenuItem/MenuItem.component';
import media from 'Util/Media';
import { getSortedItems } from 'Util/Menu';

import MobileMenuAccordian from '../custom/MobileMenuAccordian';

import './MenuItem.style';

/** @namespace LifelyScandi/Component/MenuItem/Component/MenuItemComponent */
export class MenuItemComponent extends SourceMenuItem {
    renderPlusMinusIcon() {
        const {
            itemMods: { isExpanded }
        } = this.props;

        if (isExpanded) {
            return <ChevronIcon direction="top" />;
        }

        return <ChevronIcon direction="bottom" />;
    }

    renderItemContent(item, itemMods) {
        const { isSubLevelItems, isFirstLevelItems } = this.props;
        const {
            title, icon, item_class, children
        } = item;
        const iconAlt = (item && item.icon_alt !== '') ? JSON.parse(item.icon_alt) : {};

        if (iconAlt && iconAlt.top_parent_id === 2) {
            return (
                <figcaption
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                  className={ item_class }
                >
                    { title }
                </figcaption>
            );
        }

        return (
            <figcaption
              block="Menu"
              elem="ItemCaption"
              mods={ itemMods }
              className={ item_class }
            >
                { (!iconAlt || iconAlt.top_parent_id !== 2) && (
                    <div block="MenuItem" elem="MenuWrapper">
                        { icon && (
                            <div block="MenuItem" elem="MenuIconWrapper">
                                <img
                                  src={ media(icon) }
                                  alt=""
                                  block="MenuItem"
                                  elem="MenuIcon"
                                />
                            </div>
                        ) }
                        { icon && (
                            <h4 block="MenuItem" elem="MenuTitle">
                                { title }
                            </h4>
                        ) }
                    </div>
                ) }

                { !icon && title }
            </figcaption>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            handleLinkLeave,
            onItemClick
        } = this.props;

        const {
            url, item_id, title, item_class
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <Link
              to={ title.toLowerCase() === 'home' ? '/' : url }
              block="Menu"
              elem="Link"
              id={ item_id }
              mix={ { block: 'Menu', elem: item_class } }
              onMouseEnter={ handleCategoryHover }
              onMouseLeave={ handleLinkLeave }
              mods={ { isHovered } }
              onClick={ onItemClick }
            >
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }

    // MOBILE MENU ITEM START

    renderMobileItemContent(item, itemMods) {
        const { children } = item;
        const childrenArray = getSortedItems(Object.values(children));
        return (
            <div
              block="MenuItem"
              elem="MobileMenuItemCardWrapper"
              mods={ itemMods }
            >
                { this.renderLaunchedCategory(childrenArray) }
                { this.renderUnLaunchedCategory(childrenArray) }
            </div>
        );
    }

    renderLaunchedCategory(childrenArray) {
        const { secondLevelItemId, mobileMenuItemCardAction } = this.props;
        let itemLaunched = [];
        let launched = childrenArray.filter((item) => item.item_class === '');
        itemLaunched.push(launched);
        return (
            <>
                { itemLaunched[ 0 ].map((item) => {
                    const { icon_alt } = item;

                    const iconAlt = (icon_alt && icon_alt !== '') ? JSON.parse(icon_alt) : {};

                    let url = item.url.pathname;

                    if (iconAlt && Object.keys(iconAlt).length > 0 && iconAlt.cms_block_id && iconAlt.cms_block_id !== '') {
                        url = `${ item.url.pathname }?cms_block_id=${ iconAlt.cms_block_id }`;
                    }

                    return (
                        <>
                            { item
                                && item.children
                                && Object.keys(item.children).length > 0
                                && (
                                    <>
                                        <button
                                          block="MenuItem"
                                          elem={ item.item_class === 'unLaunchedCategory' ? 'UnLaunchedCategory' : 'MobileMenuItemCard' }
                                          className={ item.item_id === secondLevelItemId ? 'CardIsActive' : '' }
                                          onClick={ () => mobileMenuItemCardAction(item) }
                                        >
                                            <div
                                              block="MenuItem"
                                              elem="MobileMenuIcon"
                                            >
                                                <img
                                                  src={ media(item.icon) }
                                                  alt=""
                                                />
                                            </div>
                                            <h5>{ item.title || '' }</h5>
                                        </button>
                                        { secondLevelItemId === item.item_id
                                            && this.renderExpandedSecondLevelItems(item) }
                                    </>
                                ) }
                            { item
                                && item.children
                                && Object.keys(item.children).length === 0
                                && (
                                    <Link
                                      to={ url }
                                      block="MenuItem"
                                      elem="MobileMenuItemCard"
                                      id={ item.item_id }
                                    >
                                        <div
                                          block="MenuItem"
                                          elem="MobileMenuIcon"
                                        >
                                            <img
                                              src={ media(item.icon || '') }
                                              alt=""

                                            />
                                        </div>
                                        <h5>{ item.title || '' }</h5>
                                    </Link>
                                ) }
                        </>
                    );
                }) }
            </>
        );
    }

    renderUnLaunchedCategory(childrenArray) {
        const { secondLevelItemId, mobileMenuItemCardAction } = this.props;
        let itemUnLaunched = [];
        let unLaunched = childrenArray.filter((item) => item.item_class === 'unLaunchedCategory');
        itemUnLaunched.push(unLaunched);

        return (
            <>
                { itemUnLaunched[ 0 ].map((item) => (
                    <>
                        { item
                            && item.children
                            && Object.keys(item.children).length > 0
                            && (
                                <>
                                    <button
                                      block="MenuItem"
                                      elem={ item.item_class === 'unLaunchedCategory' ? 'UnLaunchedCategory' : 'MobileMenuItemCard' }
                                      className={ item.item_id === secondLevelItemId ? 'CardIsActive' : '' }
                                      onClick={ () => mobileMenuItemCardAction(item) }
                                    >
                                        <div
                                          block="MenuItem"
                                          elem="MobileMenuIcon"
                                        >
                                            <img
                                              src={ media(item.icon) }
                                              alt=""
                                            />
                                        </div>
                                        <h5>{ item.title || '' }</h5>
                                    </button>
                                    { secondLevelItemId === item.item_id
                                        && this.renderExpandedSecondLevelItems(item) }
                                </>
                            ) }
                        { item
                            && item.children
                            && Object.keys(item.children).length === 0
                            && (
                                <Link
                                  to={ item.url.pathname }
                                  block="MenuItem"
                                  elem={ item.item_class === 'unLaunchedCategory' ? 'UnLaunchedCategory' : 'MobileMenuItemCard' }
                                  id={ item.item_id }
                                >
                                    <div
                                      block="MenuItem"
                                      elem="MobileMenuIcon"
                                    >
                                        <img
                                          src={ media(item.icon || '') }
                                          alt=""
                                        />
                                    </div>
                                    <h5>{ item.title || '' }</h5>
                                </Link>
                            ) }
                    </>
                )) }
            </>
        );
    }

    renderExpandedSecondLevelItems(item) {
        const { children } = item;
        const childrenArray = getSortedItems(Object.values(children));
        const secondLevelExpandedData = [];
        let expanded = '';
        childrenArray.map((item, index) => {
            if (item.children && Object.keys(item.children).length === 0) {
                expanded = false;
            }
            if (item.children && Object.keys(item.children).length > 0) {
                expanded = true;
            }
            secondLevelExpandedData.push({
                isExpanded: expanded,
                bgColor: 'transparent',
                item,
                index,
                mobileMenuAccordian: true
            });

            return true;
        });

        return (
            <div
              block="MenuItem"
              elem="MobileMenuExpandedItems"
              id="expandeditems"
            >
                <MobileMenuAccordian panels={ secondLevelExpandedData } />
            </div>
        );
    }

    // MOBILE MENU ITEM END

    render() {
        const {
            item, itemMods, isLink, isMobile
        } = this.props;

        if (isMobile) {
            return this.renderMobileItemContent(item, itemMods);
        }

        if (!isMobile && isLink) {
            return this.renderItemLinkContent();
        }

        if (!isMobile && !isLink) {
            return this.renderItemLinkContent(item, itemMods);
        }

        return false;
    }
}

export default MenuItemComponent;
