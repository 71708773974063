import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.style';

/** @namespace LifelyScandi/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    // TODO implement logic
}

export default ProductAttributeValueComponent;
