/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const QUICK_DELIVERY_PRODUCT_LIST = 'QUICK_DELIVERY_PRODUCT_LIST';

/**
 * Append page to the list.
 * @param {Array<Object>} items List of products returned from fetch
 * @namespace LifelyScandi/Store/QuickDeliveryProductList/Action/quickDeliveryProductItemList */
export const quickDeliveryProductItemList = (items) => ({
    type: QUICK_DELIVERY_PRODUCT_LIST,
    items
});
