import { isSignedIn } from 'Util/Auth';
export const RewardPointsDispatcher = import(
    '../store/RewardsPoints/RewardsPoints.dispatcher'
);

const handleChangeQuantity = async (args, callback, instance) => {
    if(isSignedIn()) {
        const {getRewardsPoints}=instance.props
        await callback(...args)
        setTimeout(function(){
            getRewardsPoints()
        }, 1000)
        
        return 
    }

    return callback(...args)
}

const handleRemoveItem = async (args, callback, instance) => {
    if(isSignedIn()) {
        const {getRewardsPoints}=instance.props
        await callback(...args)
        await getRewardsPoints()
        return 
    }

    return callback(...args)
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
 
    return {
        ...callback(...args),
        getRewardsPoints: () => RewardPointsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getRewardsPoints(dispatch)
        )
    };
 };

export default {
    'Component/CartItem/Container': {
        'member-function': {
            handleChangeQuantity: {
                position: 100,
                implementation: handleChangeQuantity
            },
            handleRemoveItem: {
                position: 100,
                implementation: handleRemoveItem
            }
        }
    },
    'Component/CartItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};