/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import ReferLifely from './ReferLifely.component';

export const RewardPointsDispatcher = import(
    '../../../../packages/@lifely/mirasvit-reward-points/src/store/RewardsPoints/RewardsPoints.dispatcher'
);
/** @namespace LifelyScandi/Component/Custom/ReferLifely/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    shareLink: _state.RewardsPointsReducer.shareLink,
    base_url: _state.ConfigReducer.base_url
});
/** @namespace LifelyScandi/Component/Custom/ReferLifely/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    showNotification: (type, message) => _dispatch(showNotification(type, message)),
    getMstReferralLink: () => RewardPointsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getMstReferralLink(_dispatch)
    )
});

/** @namespace LifelyScandi/Component/Custom/ReferLifely/Container/ReferLifelyContainer */
export class ReferLifelyContainer extends PureComponent {
    static propTypes = {
        getMstReferralLink: PropTypes.func.isRequired
    };

    containerFunctions = {
        redirectToLoginAction: this.redirectToLoginAction.bind(this),
        redirectToRegisterAction: this.redirectToRegisterAction.bind(this),
        copyReferralLinkUrlHandler: this.copyReferralLinkUrlHandler.bind(this)
    };

    componentDidMount() {
        const { getMstReferralLink } = this.props;
        if (isSignedIn()) {
            getMstReferralLink();
        }
    }

    containerProps() {
        const { shareLink, base_url } = this.props;
        return {
            shareLink,
            base_url
        };
    }

    redirectToLoginAction() {
        history.push('/login?redirect=refer-lifely');
    }

    redirectToRegisterAction() {
        history.push('/signup?redirect=refer-lifely');
    }

    copyReferralLinkUrlHandler() {
        const { showNotification, shareLink, base_url } = this.props;

        const parent = document.getElementById('ReferLifelyLinkInputField');
        const inputField = parent.appendChild(document.createElement('input'));
        inputField.value = `Hey! I tried these Healthy Foods from Lifely.They are the cleanest and nutrition rich daily life style food you'll ever find! Sign Up using this link and get 30% off on your first order! ${ base_url.slice(0, -1) + appendWithStoreCode(shareLink) }`;
        inputField.select();
        document.execCommand('copy');
        showNotification('success', 'Copied');
        inputField.parentNode.removeChild(inputField);
    }

    render() {
        return (
            <ReferLifely
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferLifelyContainer);
