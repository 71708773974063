/* eslint-disable eqeqeq */
/* eslint-disable fp/no-delete */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

export const SHOP_MENU_ONLY = 'shopMenuOnly';

const renderPhoneNumber = () => (
    <div block="Menu" elem="Hotline">
        <i className="icon-telephone" />
        <p>
            Hotline:
            <strong> 1-800-234-5678</strong>
        </p>
    </div>
);

const render = (args, callback) => (
    <div block="Menu" elem="Navigation">
        { callback() }
        { /* { renderPhoneNumber() } */ }
    </div>
);

const aroundContainerProps = (args, callback, instance) => {
    const { shopMenuOnly } = instance.props;
    const newElement = { ...callback.apply(instance, args), shopMenuOnly };
    return newElement;
};
const aroundRenderTopLevel = (args, callback, instance) => {
    const {
        shopMenuOnly, menu
    } = instance.props;

    if (shopMenuOnly) {
        for (const [key, value] of Object.entries(menu)) {
            for (const [innerKey, innerValue] of Object.entries(value.children)) {
                if (innerValue.item_class != 'shopClass') {
                    delete value.children[ innerKey ];
                }
            }
        }
    }

    return (
        <>
            { callback.apply(instance, args) }
        </>
    );
};

export const propTypes = (originalMember) => ({
    ...originalMember,
    [ SHOP_MENU_ONLY ]: PropTypes.bool
});
export const config = {
    'Component/Menu/Container': {
        'member-function': {
            containerProps: aroundContainerProps

        }
    },
    'Component/Menu/Component': {
        'member-function': {
            render,
            renderTopLevel: aroundRenderTopLevel
        },
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    }
};

export default config;
