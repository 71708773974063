/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import LocationIcon from '../Icons/LocationIcon';

import './CheckEstimatedDelivery.style';

/** @namespace LifelyScandi/Component/Custom/CheckEstimatedDelivery/Component/CheckEstimatedDeliveryComponent */
export class CheckEstimatedDeliveryComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderEstimatedLocation() {
        const {
            enteredPincode, handlePinCodeChange, isIntoCard, checkEstimateLocation
        } = this.props;

        return (
            <div
              block="CheckEstimatedDelivery"
              elem="LocationMainContainer"
              mods={ { isIntoCard } }
            >
                <div
                  block="CheckEstimatedDelivery"
                  elem="LocationHeadingWrapper"
                  mods={ { isIntoCard } }
                >
                    <LocationIcon fill="white" />
                    <h3>
                        When it will be delivered?
                    </h3>
                </div>
                { !isIntoCard && (
                    <div
                      block="CheckEstimatedDelivery"
                      elem="Divider"
                    />
                ) }
                <div
                  block="CheckEstimatedDelivery"
                  elem="LocationInputFieldContainer"
                  mods={ { isIntoCard } }
                >
                    <div style={ { width: '100%' } }>
                        <input
                          type="number"
                          id="pincode"
                          name="pincode"
                          value={ enteredPincode }
                          minLength={ 6 }
                          placeholder="Enter Your Pincode"
                          onInput={ (e) => e.target.value = e.target.value.slice(0, 6) }
                          onChange={ (e) => handlePinCodeChange(e) }
                        />
                    </div>
                    <div>
                        <button
                          block="CheckEstimatedDelivery"
                          elem="LocationCheckBtn"
                          disabled={ !enteredPincode }
                          onClick={ checkEstimateLocation }
                        >
                            Check
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderDeliveryMessage() {
        const {
            changePinInput, isIntoCard, enteredPincode, description
        } = this.props;

        return (
            <div
              block="CheckEstimatedDelivery"
              elem="EstimatedLocationDescriptionContainer"
              mods={ { isIntoCard } }
            >
                <div
                  block="CheckEstimatedDelivery"
                  elem="DeliveryOptionContainer"
                >
                    <div
                      block="CheckEstimatedDelivery"
                      elem="EstimatedLocationPinOption"
                    >
                        <LocationIcon fill="white" />
                        <h3>
                            Delivery at
                            <span>
                                { enteredPincode }
                            </span>
                        </h3>
                    </div>
                    <button
                      block="CheckEstimatedDelivery"
                      elem="ChangePinActionBtn"
                      onClick={ changePinInput }
                    >
                        Change
                    </button>
                </div>
                <div
                  block="CheckEstimatedDelivery"
                  elem="MessageDescription"
                >
                    <h4>
                        Shipping To:
                        { ' ' }
                        <span>
                            { `${ description.to_city },${ description.to_state }` }
                        </span>
                    </h4>
                    <h4>
                        Estimated Delivery by
                        { ' ' }
                        <span>{ description.edd_stamp }</span>
                    </h4>
                </div>
            </div>
        );
    }

    render() {
        const { message, isLoading } = this.props;
        return (
            <div block="CheckEstimatedDelivery">
                <Loader isLoading={ isLoading } />
                { !message && this.renderEstimatedLocation() }
                { message && this.renderDeliveryMessage() }
            </div>
        );
    }
}

export default CheckEstimatedDeliveryComponent;
