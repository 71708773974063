/* eslint-disable max-len */
import DrawerCartReducer from '../store/custom/DrawerCart/DrawerCart.reducer';
import ProductDetailStickyHeaderReducer from '../store/custom/ProductDetailStickyHeader/ProductDetailStickyHeader.reducer';
import StickyHeaderReducer from '../store/Header/Header.reducer';
import QuickDeliveryProductListReducer from '../store/QuickDeliveryProductList/QuickDeliveryProductList.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    StickyHeaderReducer,
    DrawerCartReducer,
    ProductDetailStickyHeaderReducer,
    QuickDeliveryProductListReducer
});

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};
