/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import media from 'Util/Media';

import './RewardPointsNotification.style';

/** @namespace Lifely/MirasvitRewardPoints/Component/RewardPointsNotification/Component/RewardPointsNotificationComponent */
export class RewardPointsNotificationComponent extends PureComponent {
    static propTypes = {};

    renderSuccesImage() {
        return (
            <div block="RewardPointsNotification" elem="RewardPointSuccessImg">
                <img
                  src={ media('assets/right.webp') }
                  alt=""
                />
            </div>
        );
    }

    renderSuccessNotification() {
        const { cartMstRewardPoints: { earn_points } } = this.props;
        const NotificationText1 = __('Checkout now and earn ');
        const NotificationText2 = earn_points + __(' Reward Points');
        const NotificationText3 = __(' for this order.');

        return (
            <div block="RewardPointsNotification" elem="MessageSuccess">
                { NotificationText1 }
                <strong>{ NotificationText2 }</strong>
                { NotificationText3 }
            </div>
        );
    }

    render() {
        const { cartMstRewardPoints: { earn_points } } = this.props;
        if (earn_points == 0 || earn_points == undefined) {
            return null;
        }

        return (
            <div block="RewardPointsNotification" elem="Message">
                { this.renderSuccesImage() }
                { this.renderSuccessNotification() }
            </div>
        );
    }
}
export default RewardPointsNotificationComponent;
