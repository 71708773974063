/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-lines */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, { PureComponent, Suspense } from 'react';

import Loader from 'Component/Loader';
import { DeviceType } from 'Type/Device';
import media from 'Util/Media';

import './LifelyStoryDesktop.style';

export const SlidingPane = React.lazy(() => import('../SlidingPane'));
export const SugarVariants = React.lazy(() => import('../SugarVariants'));
export const SugarImpacts = React.lazy(() => import('../SugarImpacts'));

/** @namespace LifelyScandi/Component/Custom/LifelyStoryDesktop/Component/LifelyStoryDesktopComponent */
export class LifelyStoryDesktopComponent extends PureComponent {
    static propTypes = {
        animationNoRepeat: PropTypes.bool,
        redirectToShopMenu: PropTypes.func,
        differentName: PropTypes.func.isRequired,
        dangerousImpact: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        animationNoRepeat: true
    };

    componentDidMount() {
        const { device } = this.props;
        gsap.registerPlugin(ScrollTrigger);
        // Start: Progress bar
        this.masterTl = gsap.timeline();
        this.storyHeading();

        // section 1
        this.s1b1();
        this.s1b2();
        this.s1b3();
        this.s1b4();

        this.s1b5ContentTitle();
        this.s1b5IconPart();

        this.s1b6ContentTitle();
        this.s1b6IconPart();

        // section 2

        this.s2b1ImageTitle();
        this.s2b1ContentRound();

        this.s2b2ImageTitle();
        this.s2b2Contenttext();

        this.s2b3ImageTitle();
        this.s2b3Contenttext();
        // section 3
        this.s3b1Contenttext();

        // this.s3b2ImageTitle();
        this.s3b2Contenttext();

        this.s4b1Contenttext();

        this.section1 = document.getElementById('section1');
        this.section2 = document.getElementById('section2');
        this.section3 = document.getElementById('section3');
        this.section4 = document.getElementById('section4');
        this.progressLine = document.getElementsByClassName('progressLine');
        this.progressFirst = document.getElementById('progressFirst');
        this.progressStevia = document.getElementById('progressStevia');
        this.progressHourglass = document.getElementById('progressHourglass');
        this.progressLogo = document.getElementById('progressLogo');
        this.progressbarWrapper = document.getElementsByClassName('progressbarWrapper')[ 0 ];

        if (!device.safari) {
            this.masterTl.set(
                [
                    this.progressFirst,
                    this.progressStevia,
                    this.progressHourglass,
                    this.progressLogo
                ],
                { opacity: 0.3 }
            );
            this.masterTl.add(this.sec1());
            this.masterTl.add(this.sec2());
            this.masterTl.add(this.sec3());
            this.masterTl.add(this.sec4());
            this.masterTl.add(this.sec5());
        }

        // End : Progress bar
    }

    componentWillUnmount() {
        // this.tl5.kill();
        // gsap.killTweensOf('#section2');
        // gsap.killTweensOf('#section3');
    }

    __construct(props) {
        super.__construct(props);
    }

    storyHeading() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.Heading',
                scrub: 1,
                start: 'top 80%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.storySectionTitleDesktop',
                { scale: 0 },
                {
                    duration: 1,
                    scale: 1.5
                }
            )
            .fromTo(
                '.storySectionSubTitleDesktop',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            );
    }

    s1b1() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b1',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.worldImg',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b1',
                { backgroundColor: '#ffffff' },
                {
                    duration: 1,
                    backgroundColor: '#f6ede8'
                }
            )
            .fromTo(
                '.sugarImg',
                { y: -200, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            );

        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b1',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s1b1spantag',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b1h2tag',
                { x: 190, opacity: 0, fontWeight: 100 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b1ptag',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .to('.s1b1h2tag', {
                color: '#923e16',
                duration: 1,
                fontWeight: 800,
                scale: 1.1,
                marginLeft: '5%'
            });
    }

    s1b2() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b2',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '#s1b2ImageContent',
                { scale: 0.8, opacity: 0, rotateY: 180 },
                {
                    duration: 1,
                    scale: 1,
                    opacity: 1,
                    rotateY: 0
                }
            )
            .fromTo(
                '.s1b2spantag',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b2h2tag',
                { opacity: 0, x: -50 },
                {
                    duration: 1,
                    opacity: 1,
                    x: 0,
                    color: '#923e16'
                }
            )
            .fromTo(
                '.s1b2ptag',
                { y: 190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            );
    }

    s1b3() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b3',
                scrub: 1.5,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '#s1b3ImageContent',
                { x: -100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b3spantag',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b3h2tag',
                { x: 50, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b3ptag',
                {
                    x: 60,
                    opacity: 0,
                    scale: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1,
                    scale: 1
                }
            )
            .fromTo(
                '.s1b3pFootertag',
                { y: 50, scale: 0 },
                {
                    duration: 1,
                    y: 0,
                    scale: 1,
                    x: 10
                }
            )
            .fromTo(
                '.s1b3btntag',
                { y: 50, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b3ptag',
                { y: 15 },
                {
                    duration: 1,
                    y: 0
                }
            )
            .to('.s1b3h2tag', {
                duration: 1,
                color: '#923e16'
            });
    }

    s1b4() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b4',
                scrub: 2,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '#s1b4ImageContent',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b4spantag',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b4h2tag',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b4ptag',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b4ptag',
                { y: 50 },
                {
                    duration: 1,
                    y: 0
                }
            )
            .fromTo(
                '.s1b4h2tag',
                {
                    y: 30
                },
                {
                    duration: 1,
                    y: 0,
                    color: '#923e16'
                }
            );
    }

    s1b5ContentTitle() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b5',
                scrub: 1,
                start: 'top center',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s1b5spantag',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5h2tag',
                { opacity: 0, scale: 0 },
                {
                    duration: 1,
                    color: '#923e16',
                    opacity: 1,
                    scale: 1,
                    marginLeft: '0%'
                }
            )
            .fromTo(
                '.s1b5btntag',
                { y: -10, opacity: 1 },
                {
                    duration: 1,
                    y: 20,
                    opacity: 1
                }
            );
    }

    s1b5IconPart() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b5',
                scrub: 2,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s1b5Icon1',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5Icon2',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5Icon3',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5Icon4',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5Icon5',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5Icon6',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b5Icon1',
                { y: 30, scale: 1 },
                {
                    duration: 1,
                    y: 0,
                    scale: 0.98
                }
            )
            .to('.s1b5Icon1h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b5Icon2',
                { y: 30, scale: 1 },
                {
                    duration: 1,
                    y: 0,
                    scale: 0.98
                }
            )
            .to('.s1b5Icon2h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b5Icon3',
                { y: 30, scale: 1 },
                {
                    duration: 1,
                    y: 0,
                    scale: 0.98
                }
            )
            .to('.s1b5Icon3h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b5Icon4',
                { y: 30, scale: 1 },
                {
                    duration: 1,
                    y: 0,
                    scale: 0.98
                }
            )
            .to('.s1b5Icon4h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b5Icon5',
                { y: 30, scale: 1 },
                {
                    duration: 1,
                    y: 0,
                    scale: 0.98
                }
            )
            .to('.s1b5Icon5h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b5Icon6',
                { y: 30, scale: 1 },
                {
                    duration: 1,
                    y: 0,
                    scale: 0.98
                }
            )
            .to('.s1b5Icon6h3tag', { color: '#923e16' });
    }

    s1b6ContentTitle() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b6',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s1b6h2tag',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1,
                    color: '#923e16'
                }
            )
            .fromTo(
                '.s1b6ptag',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            );
    }

    s1b6IconPart() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b6',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s1b6Icon1',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b6Icon2',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b6Icon3',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b6Icon4',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b6Icon5',
                { x: 190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s1b6Icon1',
                { y: -20, scale: 0.98, x: 0 },
                {
                    duration: 1,
                    y: 0,
                    scale: 1,
                    x: 10
                }
            )
            .to('.s1b6Icon1h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b6Icon2',
                { y: -20, scale: 0.98, x: 0 },
                {
                    duration: 1,
                    y: 0,
                    scale: 1,
                    x: 10
                }
            )
            .to('.s1b6Icon2h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b6Icon3',
                { y: -20, scale: 0.98, x: 0 },
                {
                    duration: 1,
                    y: 0,
                    scale: 1,
                    x: 10
                }
            )
            .to('.s1b6Icon3h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b6Icon4',
                { y: -20, scale: 0.98, x: 0 },
                {
                    duration: 1,
                    y: 0,
                    scale: 1,
                    x: 10
                }
            )
            .to('.s1b6Icon4h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b6Icon5',
                { y: -20, scale: 0.98, x: 0 },
                {
                    duration: 1,
                    y: 0,
                    scale: 1,
                    x: 10
                }
            )
            .to('.s1b6Icon5h3tag', { color: '#923e16' })
            .fromTo(
                '.s1b6pFootertag',
                {
                    y: -20,
                    opacity: 0,
                    fontSize: '0px',
                    fontWeight: '600'
                },
                {
                    duration: 1,
                    y: 15,
                    opacity: 1,
                    fontSize: '20px',
                    fontWeight: '600',
                    color: '#923e16'
                }
            );
    }

    s2b1ImageTitle() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b1',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '#s2b1ImageContent',
                { x: -190, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1h2tag',
                { scale: 0, opacity: 0 },
                {
                    color: '#2c4c32',
                    duration: 1,
                    fontWeight: 800,
                    scale: 1.2,
                    opacity: 1,
                    marginLeft: '9%'
                }
            )
            .fromTo(
                '.s2b1ptag',
                { y: 50, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1h2tag',
                { y: 0 },
                {
                    duration: 1,
                    y: 10
                }
            );
    }

    s2b1ContentRound() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b1',
                scrub: 2,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '#s2b1TextContent',
                {
                    y: 190,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1Round1',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1Round2',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1Round3',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1Round4',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s2b1Round5',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            );
    }

    s2b2ImageTitle() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b2',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s2b2spantag',
                { y: 0, opacity: 0 },
                {
                    duration: 1,
                    y: -50,
                    opacity: 1
                }
            )
            .fromTo(
                '#s2b2ImageContent',
                { x: 0, scale: 0 },
                {
                    duration: 1,
                    x: 0,
                    scale: 1
                }
            );
    }

    s2b2Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b2',
                scrub: 2,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s2b2h2tag',
                {
                    y: 200,
                    opacity: 0,
                    scale: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1,
                    scale: 1,
                    color: '#2c4c32'
                }
            )
            .fromTo(
                '.s2b2ptag',
                {
                    y: 35,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '#s2b2ListContent',
                {
                    x: 100,
                    opacity: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            );
    }

    s2b3ImageTitle() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b3',
                scrub: 1,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s2b3spantag',
                { opacity: 0 },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '#s2b3ImageContent',
                { x: 0, scale: 0 },
                {
                    duration: 1,
                    x: 0,
                    scale: 1
                }
            );
    }

    s2b3Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b3',
                scrub: 2,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s2b3h2tag',
                {
                    y: -100,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1,
                    color: '#2c4c32'
                }
            )
            .fromTo(
                '.s2b3ptag',
                {
                    y: 20,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '#s2b3ListContent',
                {
                    x: -50,
                    opacity: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '#s2b3ListContent',
                {
                    y: 0,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 20,
                    opacity: 1
                }
            );
    }

    s3b1Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s3b1h2tag',
                scrub: 2,
                start: 'top 60%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '#s3b1ImageContent',
                { x: 0, scale: 0 },
                {
                    duration: 1,
                    x: 0,
                    scale: 1
                }
            )
            .fromTo(
                '.s3b1h2tag',
                {
                    y: -50,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1,
                    color: '#2c4c32'
                }
            )
            .fromTo(
                '.s3b1ptag',
                {
                    opacity: 0
                },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b1spantag',
                { y: -60, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b1h2Footertag',
                { y: 50, opacity: 0, scale: 0.7 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1,
                    color: '#2c4c32',
                    scale: 1.1
                }
            );
    }

    s3b2Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s3b2',
                scrub: 2,
                start: 'top 60%',
                end: 'center center+=50px'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.IngredientHeading',
                {
                    y: -50,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 10,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b2ptag',
                {
                    y: 50,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.IngredientHeading',
                {
                    y: 10
                },
                {
                    duration: 1,
                    y: -10,
                    fontSize: '30px',
                    color: '#2c4c32'
                }
            )
            .fromTo(
                '.s3b2Round1',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b2Round2',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b2Round3',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b2Round4',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b2Round5',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.s3b2Round6',
                { y: -190, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            );

        gsap.timeline({
            onComplete: () => {
                gsap.timeline({ repeat: -1, yoyo: true }).fromTo(
                    '.imgthumbsUp',
                    { y: 5 },
                    { ease: 'power0.easeNone', duration: 1, y: -5 }
                );
                gsap.timeline({ repeat: -1, yoyo: true }).fromTo(
                    '.imgthumbsDown',
                    { y: -5 },
                    { ease: 'power0.easeNone', duration: 1, y: 5 }
                );
            },
            scrollTrigger: {
                trigger: '.s3b2',
                scrub: 1,
                start: 'top 60%',
                end: 'center center-=40px'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.VerticalLine',
                { y: -100, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.Improvesh2tag',
                { x: 100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.ImprovesList',
                { x: 100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.imgbgImprove',
                { y: -100, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.imgthumbsUp',
                { x: -100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.ImprovesHeadingHorizontalLine',
                { width: 0 },
                {
                    duration: 1,
                    width: '30%'
                }
            )
            .fromTo(
                '.Controlsh2tag',
                { x: 100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.ControlsList',
                { x: 100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.imgbgControl',
                { y: 100, opacity: 0 },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.imgthumbsDown',
                { x: -100, opacity: 0 },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.ControlsHeadingHorizontalLine',
                { width: 0 },
                {
                    duration: 1,
                    width: '30%'
                }
            );
    }

    s4b1Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s4b1',
                scrub: 3,
                start: 'top center',
                end: 'center 70%'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.s4b1spantag',
                {
                    opacity: 0
                },
                {
                    duration: 1,
                    opacity: 1
                }
            )
            .fromTo(
                '#s4b1hrLine',
                {
                    width: 0
                },
                {
                    duration: 1,
                    width: '17%'
                }
            )
            .fromTo(
                '.s4b1h2tag',
                {
                    scale: 0
                },
                {
                    duration: 1,
                    scale: 1.2
                }
            );

        gsap.timeline({
            scrollTrigger: {
                trigger: '.s4b1SuperHero',
                scrub: 3,
                start: 'top center',
                end: 'center 70%'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.HeroRibbon',
                {
                    x: -200,
                    opacity: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.LifelyHero',
                {
                    x: -100,
                    opacity: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            );

        gsap.timeline({
            scrollTrigger: {
                trigger: '.s4b1SuperHero',
                scrub: 3,
                start: 'top center',
                end: 'center 60%'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo(
                '.HeroineRibbon',
                {
                    x: 200,
                    opacity: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.LifelyHeroine',
                {
                    x: 100,
                    opacity: 0
                },
                {
                    duration: 1,
                    x: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.lifelyStoreImg',
                {
                    y: 150,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 0,
                    opacity: 1
                }
            )
            .fromTo(
                '.superFoodBtn',
                {
                    y: 150,
                    opacity: 0
                },
                {
                    duration: 1,
                    y: 70,
                    opacity: 1
                }
            );
    }

    sec5() {
        this.tl5 = gsap.timeline({
            scrollTrigger: {
                trigger: '#sectionContainer',
                scrub: 0.2,
                start: 'top top',
                end: 'bottom bottom',
                toggleClass: {
                    targets: '.progressbarWrapper',
                    className: 'visible'
                }
            }
        });

        return this.tl5;
    }

    sec1() {
        let tl1 = gsap
            .timeline({
                scrollTrigger: {
                    trigger: this.section1,
                    scrub: 0.2,
                    start: `top ${ document.getElementsByClassName(
                        'progressbarContainer'
                    )[ 0 ].offsetTop
                    }px`,
                    end: '100% center'
                }
            })
            .fromTo(this.progressFirst, 0.002, { opacity: 0.3 }, { opacity: 1 })
            .to(this.progressLine, 1, { strokeDashoffset: -580 })
            .fromTo(
                this.progressFirst,
                0.002,
                { opacity: 1 },
                { opacity: 0.3 }
            );

        return tl1;
    }

    sec2() {
        let tl2 = gsap
            .timeline({
                scrollTrigger: {
                    trigger: this.section2,
                    scrub: 0.2,
                    start: 'top 70%',
                    end: '100% center'
                }
            })
            .fromTo(
                this.progressStevia,
                0.002,
                { opacity: 0.3 },
                { opacity: 1 }
            )
            .set(this.progressLine, { strokeDashoffset: -494 })
            .to(this.progressLine, 1, { strokeDashoffset: -280 })
            .fromTo(
                this.progressStevia,
                0.002,
                { opacity: 1 },
                { opacity: 0.3 }
            );

        return tl2;
    }

    sec3() {
        let tl3 = gsap
            .timeline({
                scrollTrigger: {
                    trigger: this.section3,
                    scrub: 0.2,
                    start: 'top 70%',
                    end: '100% center'
                }
            })

            .fromTo(
                this.progressHourglass,
                0.002,
                { opacity: 0.3 },
                { opacity: 1 }
            )
            .to(this.progressLine, 1, { strokeDashoffset: -50 })
            .fromTo(
                this.progressHourglass,
                0.002,
                { opacity: 1 },
                { opacity: 0.3 }
            );

        return tl3;
    }

    sec4() {
        let tl4 = gsap
            .timeline({
                scrollTrigger: {
                    trigger: this.section4,
                    scrub: 0.2,
                    start: 'top 70%',
                    end: '100% center'
                }
            })

            .fromTo(this.progressLogo, 0.002, { opacity: 0.3 }, { opacity: 1 })
            .to(this.progressLine, 1, { strokeDashoffset: -50 });

        return tl4;
    }

    renderStoryHeading() {
        return (
            <div className="Heading">
                <h1 className="storySectionTitleDesktop">{ __('The Real Story') }</h1>
                <span className="storySectionSubTitleDesktop">
                    { __('We want to convey openly') }
                </span>
            </div>
        );
    }

    renderStorySection1() {
        const {
            differentName, dangerousImpact, isPaneOpen, slidePaneName, closeSlidePane
        } = this.props;

        return (
            <div className="part" id="section1">
                { /* s1b1 */ }

                <div className="s1b1 UnhealthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="imgWorld">
                            <img
                              src={ media('wysiwyg/lifely_story/world.webp') }
                              alt=""
                              loading="lazy"
                              className="worldImg"
                            />
                            <div className="imgSugerCubes">
                                <img
                                  src={ media(
                                      'wysiwyg/lifely_story/sugar-cubes.webp'
                                  ) }
                                  loading="lazy"
                                  alt=""
                                  className="sugarImg"
                                />
                            </div>
                        </div>
                        <div className="TextContent">
                            <span className="s1b1spantag">
                                { __('We are living in') }
                            </span>
                            <h2 className="s1b1h2tag">
                                { __('The Sugar Age World') }
                            </h2>
                            <p className="s1b1ptag">
                                { __(
                                    "Bitter truth is most of people are not even aware of it and it's serious effect on their health and life "
                                ) }
                            </p>
                        </div>
                    </div>
                </div>

                { /* s1b2 */ }

                <div className="s1b2 UnhealthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="TextContent">
                            <span className="s1b2spantag">
                                { __("It's disturbing, it's") }
                            </span>
                            <h2 className="s1b2h2tag">{ __('Everywhere') }</h2>
                            <p className="s1b2ptag">
                                { __(
                                    'in this form of ADDED sugar Not even your sugar free sweeteners are really free of sugar'
                                ) }
                            </p>
                        </div>
                        <div className="ImageContent" id="s1b2ImageContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/its-everywhere.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                    </div>
                </div>

                { /* s1b3  */ }

                <div className="s1b3 UnhealthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="ImageContent" id="s1b3ImageContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/differant-labels.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                        <div className="TextContent">
                            <span className="s1b3spantag">
                                { __('Labeled with,') }
                            </span>
                            <h2 className="s1b3h2tag">
                                { __('Different Names') }
                            </h2>
                            <p className="s1b3ptag">
                                { __(
                                    'like Sugar, Sugar Alchohol, Artificial Sweetners makes even harder to recognize it '
                                ) }
                            </p>
                            <button
                              className="Button listBtn s1b3btntag"
                              onClick={ differentName }
                            >
                                More About Sugar &#187;
                            </button>
                        </div>
                    </div>
                    <div className="CmsFooter">
                        <p className="s1b3pFootertag">
                            { __(
                                'A normal consumer may not even know what this strange ingredients are related to'
                            ) }
                        </p>
                    </div>
                </div>

                { /* s1b4 */ }

                <div className="s1b4 UnhealthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="TextContent">
                            <span className="s1b4spantag">
                                { __('Your everyday food choice  is creating') }
                            </span>
                            <h2 className="s1b4h2tag">
                                { __('Flood Of Sugar') }
                            </h2>
                            <p className="s1b4ptag">
                                { __(
                                    'In your body. And you earn yourself real dangers of sugar impact upon your body'
                                ) }
                            </p>
                        </div>
                        <div className="ImageContent" id="s1b4ImageContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/sugar-flood.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                    </div>
                </div>

                { /* s1b5 */ }

                <div className="s1b5 UnhealthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="IconContainer">
                            <div className="s1b5Icon1">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/addiction.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon1h3tag">
                                        Addiction
                                    </h3>
                                    <p>
                                        Sugar is additive,more you eat more you
                                        get addicted
                                    </p>
                                </div>
                            </div>
                            <div className="s1b5Icon2">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/increased-hunger.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon2h3tag">
                                        Increased Hunger
                                    </h3>
                                    <p>
                                        It does not make you full , instead
                                        creates more hunger
                                    </p>
                                </div>
                            </div>
                            <div className="s1b5Icon3">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/weight-gain.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon3h3tag">
                                        Weight Gain
                                    </h3>
                                    <p>Sugar Causes weight gain and obesity</p>
                                </div>
                            </div>
                            <div className="s1b5Icon4">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/heart-disease.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon4h3tag">
                                        Heart Disease
                                    </h3>
                                    <p>Sugar causes hardening of artaries</p>
                                </div>
                            </div>
                            <div className="s1b5Icon5">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/diabetes.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon5h3tag">Diabetes</h3>
                                    <p>Sugar is leading cause for Diabetes</p>
                                </div>
                            </div>
                            <div className="s1b5Icon6">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/metabolic-disorder.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon6h3tag">
                                        Metabolic disorders
                                    </h3>
                                    <p>Sugar Causes weight gain and obesity</p>
                                </div>
                            </div>
                        </div>
                        <div className="TextContent">
                            <span className="s1b5spantag">{ __("Sugar's") }</span>
                            <h2 className="s1b5h2tag">
                                { __('Dangerous Impact') }
                            </h2>
                            <button
                              className="Button listBtn s1b5btntag"
                              onClick={ dangerousImpact }
                            >
                                Sugar Impact  &#187;
                            </button>
                        </div>
                    </div>
                </div>

                { /* s1b6 */ }

                <div className="s1b6 UnhealthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="TextContent">
                            <h2 className="s1b6h2tag">
                                { __('The Real Reason') }
                            </h2>
                            <p className="s1b6ptag">
                                { __(
                                    'Why most of the brand products are loaded with additional sugar of many forms'
                                ) }
                            </p>
                        </div>
                        <div className="IconContainer">
                            <div className="s1b6Icon1">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/addiction.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon1h3tag">
                                        Addiction
                                    </h3>
                                    <p>
                                        Sugar is additive,more you eat more you
                                        get addicted
                                    </p>
                                </div>
                            </div>
                            <div className="s1b6Icon2">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/increased-hunger.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon2h3tag">
                                        Increased Hunger
                                    </h3>
                                    <p>
                                        It does not make you full,instead
                                        creates more hunger
                                    </p>
                                </div>
                            </div>
                            <div className="s1b6Icon3">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/weight-gain.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon3h3tag">Craving</h3>
                                    <p>
                                        Sugar Causes Resistance to a Hormone
                                        called Leptin,which is the Fullness
                                        Signal
                                    </p>
                                </div>
                            </div>
                            <div className="s1b6Icon4">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/heart-disease.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon4h3tag">
                                        Cheap Bulking Agent
                                    </h3>
                                    <p>Sugar is the cheapest bulking agent,</p>
                                </div>
                            </div>
                            <div className="s1b6Icon5">
                                <div className="IconBox">
                                    <img
                                      src={ media(
                                          'wysiwyg/lifely_story/diabetes.webp'
                                      ) }
                                      loading="lazy"
                                      alt=""
                                    />
                                </div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon5h3tag">
                                        Cheap Price and Discounts
                                    </h3>
                                    <p>
                                        More Sugar means less price, less price
                                        means more business
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="CmsFooter">
                        <p className="s1b6pFootertag">
                            { __(
                                "They don't care what impact sugar creates in consumer's body in long time"
                            ) }
                        </p>
                    </div>
                </div>
                { (slidePaneName === 'sugarVariants' || slidePaneName === 'sugarImpact') && (
                    <SlidingPane
                      key={ slidePaneName }
                      isOpen={ isPaneOpen }
                      title={ slidePaneName === 'sugarVariants' ? 'Types Of Sugar' : 'Sugar\'s Dangerous Impact' }
                      width="80%"
                      from="right"
                      footer="Close"
                      btnPrimaryBase
                      onRequestClose={ () => {
                          closeSlidePane();
                      } }
                      onButtonClick={ () => {
                          closeSlidePane();
                      } }
                    >
                        { slidePaneName === 'sugarVariants' && (<SugarVariants />) }
                        { slidePaneName === 'sugarImpact' && (<SugarImpacts />) }

                    </SlidingPane>
                ) }
            </div>
        );
    }

    renderStorySection2() {
        return (
            <div className="part" id="section2">
                { /* s2b1 */ }

                <div className="s2b1 healthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="ImageContent" id="s2b1ImageContent">
                            <img
                              src={ media('wysiwyg/lifely_story/stevia.webp') }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                        <div className="TextContent">
                            <h2 className="s2b1h2tag">{ __('Stevia') }</h2>
                            <p className="s2b1ptag">
                                { __(
                                    'A true blessing from mother nature to defeat sugar devil'
                                ) }
                            </p>
                            <div className="TextContent" id="s2b1TextContent">
                                <h2>{ __('100% Natural Sweetner') }</h2>
                                <p>{ __('which is,') }</p>
                                <ul>
                                    <li>{ __('As pure as mother nature') }</li>
                                    <li>{ __('Safe for all age ') }</li>
                                    <li>{ __('Safe for Diebetic patients') }</li>
                                    <li>{ __('Zero calories') }</li>
                                    <li>{ __('Zero Glycemic index(GI)') }</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="CmsFooter">
                        <div className="RoundShape">
                            <div className="s2b1Round1">
                                <img
                                  src={ media(
                                      'wysiwyg/lifely_story/natural.webp'
                                  ) }
                                  loading="lazy"
                                  alt=""
                                />
                                <p>{ __('Natural') }</p>
                            </div>
                            <div className="s2b1Round2">
                                <img
                                  src={ media(
                                      'wysiwyg/lifely_story/safe-for-kids.webp'
                                  ) }
                                  loading="lazy"
                                  alt=""
                                />
                                <p>{ __('Safe For Children') }</p>
                            </div>
                            <div className="s2b1Round3">
                                <img
                                  src={ media(
                                      'wysiwyg/lifely_story/safe-for-diabetic.webp'
                                  ) }
                                  loading="lazy"
                                  alt=""
                                />
                                <p>{ __('Safe For Diabetic') }</p>
                            </div>
                            <div className="s2b1Round4">
                                <img
                                  src={ media(
                                      'wysiwyg/lifely_story/safe-for-elders.webp'
                                  ) }
                                  loading="lazy"
                                  alt=""
                                />
                                <p>{ __('Safe For Elders') }</p>
                            </div>
                            <div className="s2b1Round5">
                                <img
                                  src={ media(
                                      'wysiwyg/lifely_story/safe-for-pregnant-women.webp'
                                  ) }
                                  loading="lazy"
                                  alt=""
                                />
                                <p>{ __('Safe For Pregnant Women') }</p>
                            </div>
                        </div>
                    </div>
                </div>

                { /* s2b2 */ }

                <div className="s2b2 healthySectionBackground">
                    <span className="s2b2spantag">
                        { __('Natural is our only inspiration for research') }
                    </span>
                    <div className="StorySectionContent">
                        <div className="ImageContent" id="s2b2ImageContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/cleansweet-research-variety.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                        <div className="TextContent">
                            <h2 className="s2b2h2tag">{ __('Clean Sweet') }</h2>
                            <p className="s2b2ptag">
                                { __('Our Researched Stevia Variety') }
                            </p>
                            <ul id="s2b2ListContent">
                                <li>{ __('No Bitterness') }</li>
                                <li>{ __('No typical aftertaste of stevia') }</li>
                                <li>{ __('Non GMO Proprietary') }</li>
                            </ul>
                        </div>
                    </div>
                </div>

                { /* s2b3 */ }

                <div className="s2b3 healthySectionBackground">
                    <span className="s2b3spantag">
                        { __('Natural is our only inspiration for innovation') }
                    </span>
                    <div className="StorySectionContent">
                        <div className="TextContent">
                            <h2 className="s2b3h2tag">
                                { __('100% Natural extraction process') }
                            </h2>
                            <p className="s2b3ptag">
                                { __(
                                    'Stevia extraction by our world patented iNEx process, using only water as medium'
                                ) }
                            </p>
                            <ul id="s2b3ListContent">
                                <li>{ __('No chemical') }</li>
                                <li>{ __('No solvent') }</li>
                            </ul>
                        </div>
                        <div className="ImageContent" id="s2b3ImageContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/natural-extraction.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStorySection3() {
        return (
            <div className="part" id="section3">
                { /* s3b1 */ }

                <div className="s3b1 healthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="ImageContent" id="s3b1ImageContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/prebiotic-dietary-fibers.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                        <div className="TextContent" id="s3b1TextContent">
                            <h2 className="s3b1h2tag">
                                { __('Prebiotic dietry fibres') }
                            </h2>
                            <p className="s3b1ptag">
                                { __(
                                    'extracted using our iNEx natural process '
                                ) }
                            </p>
                            <span className="s3b1spantag">
                                { __(
                                    "Added from natural sources in all our products It's food for 100 trillion good Gut bacteria army"
                                ) }
                            </span>
                        </div>
                    </div>
                    <div className="CmsFooter">
                        <h2 className="s3b1h2Footertag">
                            { __('Healthy Gut flora, Healthy you') }
                        </h2>
                    </div>
                </div>

                { /* s3b2 */ }

                <div className="s3b2 healthySectionBackground">
                    <div className="StorySectionContent">
                        <div className="TitleContent">
                            <div className="TitlePart">
                                <div className="IngredientCount IngredientHeading">
                                    <h3 className="s3b2h3tag">{ __('6') }</h3>
                                </div>
                                <h2 className="s3b2h2tag IngredientHeading">
                                    { __('Wellness Ingredients') }
                                </h2>
                                <p className="s3b2ptag">
                                    { __(
                                        'extracted using our iNEx natural process '
                                    ) }
                                </p>
                            </div>
                            <div className="IngredientPart">
                                <div className="s3b2Round1">
                                    <div className="ImgWrapper">
                                        <img
                                          src={ media(
                                              'wysiwyg/ingredients/used/fenugreek-extract.webp'
                                          ) }
                                          loading="lazy"
                                          alt=""
                                        />
                                    </div>
                                    <p>{ __('Fenugreek extract') }</p>
                                </div>
                                <div className="s3b2Round2">
                                    <div className="ImgWrapper">
                                        <img
                                          src={ media(
                                              'wysiwyg/ingredients/used/ala-dha.webp'
                                          ) }
                                          loading="lazy"
                                          alt=""
                                        />
                                    </div>
                                    <p>{ __('Omega 3 Ala & DHA') }</p>
                                </div>
                                <div className="s3b2Round3">
                                    <div className="ImgWrapper">
                                        <img
                                          src={ media(
                                              'wysiwyg/ingredients/used/white-kidney-beans-extract.webp'
                                          ) }
                                          loading="lazy"
                                          alt=""
                                        />
                                    </div>
                                    <p>{ __('White kidney bean extract') }</p>
                                </div>
                                <div className="s3b2Round4">
                                    <div className="ImgWrapper">
                                        <img
                                          src={ media(
                                              'wysiwyg/ingredients/used/green-coffee-beans-extract.webp'
                                          ) }
                                          loading="lazy"
                                          alt=""
                                        />
                                    </div>
                                    <p>{ __('Green coffeebean extract') }</p>
                                </div>
                                <div className="s3b2Round5">
                                    <div className="ImgWrapper">
                                        <img
                                          src={ media(
                                              'wysiwyg/ingredients/used/mct-oil.webp'
                                          ) }
                                          loading="lazy"
                                          alt=""
                                        />
                                    </div>
                                    <p>{ __('MCT Oil') }</p>
                                </div>
                                <div className="s3b2Round6">
                                    <div className="ImgWrapper">
                                        <img
                                          src={ media(
                                              'wysiwyg/ingredients/used/cla.webp'
                                          ) }
                                          loading="lazy"
                                          alt=""
                                        />
                                    </div>
                                    <p>{ __('Conjugated Linoleic acid') }</p>
                                </div>
                            </div>
                        </div>
                        <div className="VerticalLine" />
                        <div className="TextContent">
                            <div
                              className="ImprovesPart"
                              id="s3b2ImprovesContent"
                            >
                                <h2 className="Improvesh2tag">Improves</h2>
                                <div className="ImprovesHeadingHorizontalLine" />
                                <div className="Improves">
                                    <div className="ImprovesThumbsUp">
                                        <div className="imgbgImprove">
                                            <img
                                              src={ media(
                                                  'wysiwyg/lifely_story/bgImprove.webp'
                                              ) }
                                              loading="lazy"
                                              alt=""
                                            />
                                            <div className="imgthumbsUp">
                                                <img
                                                  src={ media(
                                                      'wysiwyg/lifely_story/thumbsUp.webp'
                                                  ) }
                                                  loading="lazy"
                                                  alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ImprovesList">
                                        <ul>
                                            <li>
                                                Blood glucose level regulation
                                            </li>
                                            <li>Immune system</li>
                                            <li>Digestive system</li>
                                            <li>Metabolism</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                              className="ControlsPart"
                              id="s3b2ControlsContent"
                            >
                                <h2 className="Controlsh2tag">Controls</h2>
                                <div className="ControlsHeadingHorizontalLine" />
                                <div className="Controls">
                                    <div className="ControlsThumbsDown">
                                        <div className="imgbgControl">
                                            <img
                                              src={ media(
                                                  'wysiwyg/lifely_story/bgControl.webp'
                                              ) }
                                              loading="lazy"
                                              alt=""
                                            />
                                            <div className="imgthumbsDown">
                                                <img
                                                  src={ media(
                                                      'wysiwyg/lifely_story/thumbsDown.webp'
                                                  ) }
                                                  loading="lazy"
                                                  alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ControlsList">
                                        <ul>
                                            <li>Body Weight</li>
                                            <li>Mood Swing</li>
                                            <li>Depression</li>
                                            <li>Anxiety</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStorySection4() {
        const { redirectToShopMenu } = this.props;
        return (
            <div className="part" id="section4">
                <div className="s4b1">
                    <div>
                        <span className="s4b1spantag">Welcome To</span>
                        <div className="HorizontalLine" id="s4b1hrLine" />
                        <h2 className="s4b1h2tag">Lifely's Truly</h2>
                    </div>
                    <div className="s4b1SuperHero">
                        <div className="LifelyHero">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/lifely-hero.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                        <div className="ShopNowContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/lifely-store-desktop.webp'
                              ) }
                              loading="lazy"
                              className="lifelyStoreImg"
                              alt=""
                            />
                            <button
                              className="Button superFoodBtn"
                              onClick={ redirectToShopMenu }
                            >
                                shop now
                            </button>
                        </div>
                        <div className="LifelyHeroine">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/lifely-heroine.webp'
                              ) }
                              loading="lazy"
                              alt=""
                            />
                        </div>
                    </div>
                    <div className="s4b1SuperHeroin">
                        <div className="HeroRibbon">
                            <span className="ribbon-content">Super Tasty</span>
                        </div>
                        <div className="HeroineRibbon">
                            <span className="ribbon-content">
                                Super Healthy
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStoryContent() {
        return (
            <>
                { this.renderStoryHeading() }
                { this.renderStorySection1() }
                { this.renderStorySection2() }
                { this.renderStorySection3() }
                { this.renderStorySection4() }
            </>
        );
    }

    renderProgressbarWrapper() {
        return (
            <div className="progressbarContainer">
                <div className="progressbarWrapper">
                    { this.renderProgressbar() }
                </div>
            </div>
        );
    }

    renderProgressbar() {
        return (
            <svg
              id="desktopProgressbarSvg"
              x="0px"
              y="0px"
              viewBox="0 0 935 161.9"
              enableBackground="new 0 0 935 161.9"
            >
                <g id="progressLineG">
                    <path
                      className="progressLine"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      d="
						M846.5,73.2c0,0-11.3-0.1-15.4,7.7c-4.2,7.8-15.7,7.7-15.7,7.7h0c0,0-11.3,0.1-15.4-7.7c-4.2-7.8-15.7-7.7-15.7-7.7
						s-11.2-0.1-15.4,7.7s-15.7,7.8-15.7,7.8h0c0,0-11.3,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8l0,0c0,0-11.3,0-15.4,7.8
						s-15.7,7.8-15.7,7.8h0c0,0-11.3,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8s-11.2-0.1-15.4,7.7c-4.2,7.8-15.7,7.8-15.7,7.8h0
						c0,0-11.3,0-15.4-7.8S597.9,73,597.9,73L598,73c0,0-11.3,0-15.4,7.8c-4.2,7.8-15.7,7.8-15.7,7.8h0c0,0-11.3-0.1-15.4-7.9
						c-4.2-7.8-15.7-7.8-15.7-7.8s-11.2,0-15.4,7.8s-15.7,7.9-15.7,7.9h0c0,0-11.3-0.1-15.4-7.9c-4.2-7.8-15.7-7.8-15.7-7.8l0-0.1
						c0,0-11.3,0.1-15.4,7.9c-4.2,7.8-15.7,7.9-15.7,7.9h0c0,0-11.3-0.1-15.4-7.9c-4.2-7.8-15.7-7.8-15.7-7.8s-11.2,0-15.4,7.8
						c-4.2,7.8-15.7,7.9-15.7,7.9h0c0,0-11.3-0.2-15.4-8s-15.7-7.9-15.7-7.9l0-0.1c0,0-11.3,0.1-15.4,8c-4.2,7.8-15.7,7.9-15.7,7.9h0
						c0,0-11.3-0.2-15.4-8s-15.7-7.9-15.7-7.9s-11.2,0.1-15.4,7.9c-4.2,7.8-15.7,8-15.7,8h0c0,0-11.3-0.2-15.4-8
						c-4.2-7.8-15.7-7.9-15.7-7.9l0-0.1c0,0-11.3,0.2-15.4,8c-4.2,7.8-15.7,8-15.7,8h0c0,0-11.3-0.2-15.4-8s-15.7-7.9-15.7-7.9
						s-11.2,0.1-15.4,7.9c-4.2,7.8-15.7,8-15.7,8h0c0,0-11.3-0.3-15.4-8.1c-4.2-7.8-15.7-7.9-15.7-7.9"
                    />

                    <path
                      className="progressLine"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      d="
						M846.5,88.7c0,0-11.3-0.2-15.4-8c-4.2-7.8-15.7-8-15.7-8h0c0,0-11.3,0.2-15.4,8c-4.2,7.8-15.7,7.9-15.7,7.9s-11.2-0.1-15.4-7.9
						s-15.7-8-15.7-8h0c0,0-11.3,0.2-15.4,8c-4.2,7.8-15.7,8-15.7,8s-11.3-0.2-15.4-8s-15.7-8-15.7-8h0c0,0-11.3,0.2-15.4,8
						c-4.2,7.8-15.7,7.9-15.7,7.9s-11.2-0.1-15.4-7.9c-4.2-7.8-15.7-7.9-15.7-7.9h0c0,0-11.3,0.1-15.4,7.9S598,88.4,598,88.4
						s-11.2,0-15.4-7.8c-4.2-7.8-15.7-7.9-15.7-7.9h0c0,0-11.3,0.1-15.4,7.9c-4.2,7.8-15.7,7.8-15.7,7.8s-11.2,0-15.4-7.8
						s-15.7-7.9-15.7-7.9h0c0,0-11.3,0-15.4,7.8c-4.2,7.8-15.7,7.9-15.7,7.9s-11.3-0.1-15.4-7.9c-4.2-7.8-15.7-7.9-15.7-7.9h0
						c0,0-11.3,0-15.4,7.8c-4.2,7.8-15.7,7.8-15.7,7.8s-11.2,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8h0c0,0-11.3,0.2-15.4,8
						s-15.7,8-15.7,8h0c0,0-11.3-0.2-15.4-8c-4.2-7.8-15.7-8-15.7-8h0c0,0-11.3,0-15.4,7.8s-15.7,7.8-15.7,7.8s-11.2,0.1-15.4-7.8
						c-4.2-7.8-15.7-7.8-15.7-7.8h0c0,0-11.3-0.1-15.4,7.7c-4.2,7.8-15.7,7.8-15.7,7.8s-11.3,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8h0
						c0,0-11.3-0.1-15.4,7.7s-15.7,7.7-15.7,7.7l0.1-0.1c0,0-11.3,0.1-15.4-7.7c-4.2-7.8-15.7-7.7-15.7-7.7h0c0,0-11.3-0.1-15.4,7.7
						c-4.2,7.8-15.7,7.7-15.7,7.7"
                    />

                    <path
                      id="XMLID_243_"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      stroke="#335E40"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      d="
						M136.5,75.7v9 M130.5,74.7v11 M139.5,76.7v7 M127.5,75.7v9 M124.5,76.7v7 M133.5,74.7v11 M121.5,77.7v5 M142.5,77.7v5 M198.5,75.7
						v9 M192.5,74.7v11 M201.5,76.7v7 M189.5,75.7v9 M186.5,76.7v7 M195.5,74.7v11 M183.5,77.7v5 M204.5,77.7v5 M158.5,75.7v9
						 M164.5,74.7v11 M155.5,76.7v7 M167.5,75.7v9 M170.5,76.7v7 M161.5,74.7v11 M173.5,77.7v5 M152.5,77.7v5 M229.5,75.7v9 M223.5,74.7
						v11 M232.5,76.7v7 M220.5,75.7v9 M217.5,76.7v7 M226.5,74.7v11 M214.5,77.7v5 M235.5,77.7v5 M260.5,75.7v9 M254.5,74.7v11
						 M263.5,76.7v7 M251.5,75.7v9 M248.5,76.7v7 M257.5,74.7v11 M245.5,77.7v5 M266.5,77.7v5 M291.5,75.7v9 M285.5,74.7v11 M294.5,76.7
						v7 M282.5,75.7v9 M279.5,76.7v7 M288.5,74.7v11 M276.5,77.7v5 M297.5,77.7v5 M353.5,75.7v9 M347.5,74.7v11 M356.5,76.7v7
						 M344.5,75.7v9 M341.5,76.7v7 M350.5,74.7v11 M338.5,77.7v5 M359.5,77.7v5 M313.5,75.7v9 M319.5,74.7v11 M310.5,76.7v7 M322.5,75.7
						v9 M325.5,76.7v7 M316.5,74.7v11 M328.5,77.7v5 M307.5,77.7v5 M384.5,75.7v9 M378.5,74.7v11 M387.5,76.7v7 M375.5,75.7v9
						 M372.5,76.7v7 M381.5,74.7v11 M369.5,77.7v5 M390.5,77.7v5 M415.5,75.7v9 M409.5,74.7v11 M418.5,76.7v7 M406.5,75.7v9 M403.5,76.7
						v7 M412.5,74.7v11 M400.5,77.7v5 M421.5,77.7v5 M447.5,75.7v9 M441.5,74.7v11 M450.5,76.7v7 M438.5,75.7v9 M435.5,76.7v7
						 M444.5,74.7v11 M432.5,77.7v5 M453.5,77.7v5 M509.5,75.7v9 M503.5,74.7v11 M512.5,76.7v7 M500.5,75.7v9 M497.5,76.7v7 M506.5,74.7
						v11 M494.5,77.7v5 M515.5,77.7v5 M469.5,75.7v9 M475.5,74.7v11 M466.5,76.7v7 M478.5,75.7v9 M481.5,76.7v7 M472.5,74.7v11
						 M484.5,77.7v5 M463.5,77.7v5 M540.5,75.7v9 M534.5,74.7v11 M543.5,76.7v7 M531.5,75.7v9 M528.5,76.7v7 M537.5,74.7v11 M525.5,77.7
						v5 M546.5,77.7v5 M571.5,75.7v9 M565.5,74.7v11 M574.5,76.7v7 M562.5,75.7v9 M559.5,76.7v7 M568.5,74.7v11 M556.5,77.7v5
						 M577.5,77.7v5 M602.5,75.7v9 M596.5,74.7v11 M605.5,76.7v7 M593.5,75.7v9 M590.5,76.7v7 M599.5,74.7v11 M587.5,77.7v5 M608.5,77.7
						v5 M664.5,75.7v9 M658.5,74.7v11 M667.5,76.7v7 M655.5,75.7v9 M652.5,76.7v7 M661.5,74.7v11 M649.5,77.7v5 M670.5,77.7v5
						 M624.5,75.7v9 M630.5,74.7v11 M621.5,76.7v7 M633.5,75.7v9 M636.5,76.7v7 M627.5,74.7v11 M639.5,77.7v5 M618.5,77.7v5 M695.5,75.7
						v9 M689.5,74.7v11 M698.5,76.7v7 M686.5,75.7v9 M683.5,76.7v7 M692.5,74.7v11 M680.5,77.7v5 M701.5,77.7v5 M726.5,75.7v9
						 M720.5,74.7v11 M729.5,76.7v7 M717.5,75.7v9 M714.5,76.7v7 M723.5,74.7v11 M711.5,77.7v5 M732.5,77.7v5 M757.5,75.7v9 M751.5,74.7
						v11 M760.5,76.7v7 M748.5,75.7v9 M745.5,76.7v7 M754.5,74.7v11 M742.5,77.7v5 M763.5,77.7v5 M819.5,75.7v9 M813.5,74.7v11
						 M822.5,76.7v7 M810.5,75.7v9 M807.5,76.7v7 M816.5,74.7v11 M804.5,77.7v5 M825.5,77.7v5 M779.5,75.7v9 M785.5,74.7v11 M776.5,76.7
						v7 M788.5,75.7v9 M791.5,76.7v7 M782.5,74.7v11 M794.5,77.7v5 M773.5,77.7v5 M844.5,74.7v11 M841.5,75.7v9 M838.5,76.7v7
						 M835.5,77.7v5 M106.5,75.7v9 M109.5,76.7v7 M103.5,74.7v11 M112.5,77.7v5"
                    />

                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      stroke="#FFF"
                      strokeOpacity="0.3"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="
						M846.5,73.2c0,0-11.3-0.1-15.4,7.7c-4.2,7.8-15.7,7.7-15.7,7.7h0c0,0-11.3,0.1-15.4-7.7c-4.2-7.8-15.7-7.7-15.7-7.7
						s-11.2-0.1-15.4,7.7s-15.7,7.8-15.7,7.8h0c0,0-11.3,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8l0,0c0,0-11.3,0-15.4,7.8
						s-15.7,7.8-15.7,7.8h0c0,0-11.3,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8s-11.2-0.1-15.4,7.7c-4.2,7.8-15.7,7.8-15.7,7.8h0
						c0,0-11.3,0-15.4-7.8S597.9,73,597.9,73L598,73c0,0-11.3,0-15.4,7.8c-4.2,7.8-15.7,7.8-15.7,7.8h0c0,0-11.3-0.1-15.4-7.9
						c-4.2-7.8-15.7-7.8-15.7-7.8s-11.2,0-15.4,7.8s-15.7,7.9-15.7,7.9h0c0,0-11.3-0.1-15.4-7.9c-4.2-7.8-15.7-7.8-15.7-7.8l0-0.1
						c0,0-11.3,0.1-15.4,7.9c-4.2,7.8-15.7,7.9-15.7,7.9h0c0,0-11.3-0.1-15.4-7.9c-4.2-7.8-15.7-7.8-15.7-7.8s-11.2,0-15.4,7.8
						c-4.2,7.8-15.7,7.9-15.7,7.9h0c0,0-11.3-0.2-15.4-8s-15.7-7.9-15.7-7.9l0-0.1c0,0-11.3,0.1-15.4,8c-4.2,7.8-15.7,7.9-15.7,7.9h0
						c0,0-11.3-0.2-15.4-8s-15.7-7.9-15.7-7.9s-11.2,0.1-15.4,7.9c-4.2,7.8-15.7,8-15.7,8h0c0,0-11.3-0.2-15.4-8
						c-4.2-7.8-15.7-7.9-15.7-7.9l0-0.1c0,0-11.3,0.2-15.4,8c-4.2,7.8-15.7,8-15.7,8h0c0,0-11.3-0.2-15.4-8s-15.7-7.9-15.7-7.9
						s-11.2,0.1-15.4,7.9c-4.2,7.8-15.7,8-15.7,8h0c0,0-11.3-0.3-15.4-8.1c-4.2-7.8-15.7-7.9-15.7-7.9"
                    />

                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      stroke="#FFF"
                      strokeOpacity="0.3"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="
						M846.5,88.7c0,0-11.3-0.2-15.4-8c-4.2-7.8-15.7-8-15.7-8h0c0,0-11.3,0.2-15.4,8c-4.2,7.8-15.7,7.9-15.7,7.9s-11.2-0.1-15.4-7.9
						s-15.7-8-15.7-8h0c0,0-11.3,0.2-15.4,8c-4.2,7.8-15.7,8-15.7,8s-11.3-0.2-15.4-8s-15.7-8-15.7-8h0c0,0-11.3,0.2-15.4,8
						c-4.2,7.8-15.7,7.9-15.7,7.9s-11.2-0.1-15.4-7.9c-4.2-7.8-15.7-7.9-15.7-7.9h0c0,0-11.3,0.1-15.4,7.9S598,88.4,598,88.4
						s-11.2,0-15.4-7.8c-4.2-7.8-15.7-7.9-15.7-7.9h0c0,0-11.3,0.1-15.4,7.9c-4.2,7.8-15.7,7.8-15.7,7.8s-11.2,0-15.4-7.8
						s-15.7-7.9-15.7-7.9h0c0,0-11.3,0-15.4,7.8c-4.2,7.8-15.7,7.9-15.7,7.9s-11.3-0.1-15.4-7.9c-4.2-7.8-15.7-7.9-15.7-7.9h0
						c0,0-11.3,0-15.4,7.8c-4.2,7.8-15.7,7.8-15.7,7.8s-11.2,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8h0c0,0-11.3,0.2-15.4,8
						s-15.7,8-15.7,8h0c0,0-11.3-0.2-15.4-8c-4.2-7.8-15.7-8-15.7-8h0c0,0-11.3,0-15.4,7.8s-15.7,7.8-15.7,7.8s-11.2,0.1-15.4-7.8
						c-4.2-7.8-15.7-7.8-15.7-7.8h0c0,0-11.3-0.1-15.4,7.7c-4.2,7.8-15.7,7.8-15.7,7.8s-11.3,0-15.4-7.8c-4.2-7.8-15.7-7.8-15.7-7.8h0
						c0,0-11.3-0.1-15.4,7.7s-15.7,7.7-15.7,7.7l0.1-0.1c0,0-11.3,0.1-15.4-7.7c-4.2-7.8-15.7-7.7-15.7-7.7h0c0,0-11.3-0.1-15.4,7.7
						c-4.2,7.8-15.7,7.7-15.7,7.7"
                    />
                </g>
                <path
                  className="nodeBckgrnd"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M68,37.2h107c0,0,10.9,23.7-7.1,44s-45.7,45-46.6,45.2c-0.9,0.2-47.5-45.8-47.5-45.8S57,63.2,68,37.2z"
                />

                <path
                  className="nodeBckgrnd"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="
					M329.3,126.2c0,0,105.6,0,105.6,0c0.7,0,2.2-6.7,2.4-7.2c1.4-5.4,1.9-11.3,1.2-16.9c-1.9-14.7-13.4-25.2-23.1-35.3
					c-8.9-9.2-18.1-18.1-27.5-26.9c-1.8-1.7-3.6-3.4-5.4-5.1c-5.3,5.3-10.5,10.6-15.8,15.9c-6.3,6.4-12.7,12.7-19.1,19
					c-5.6,5.6-12,11.1-16,18.1c-3.7,6.6-5.6,14.8-5.5,22.4C326.3,115.6,327.4,121.2,329.3,126.2z"
                />
                <path
                  className="nodeBckgrnd"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="
					M613.6,80.6c1-0.9,2-1.8,2.9-2.7c5.5-5.2,10.9-10.4,16.2-15.8c5.7-5.9,12.5-12.1,13.6-20.7c0.4-3.3,0.1-6.8-0.7-10
					c-0.1-0.3-1-4.2-1.4-4.2c0,0-62.3,0-62.3,0c-1.1,3-1.8,6.1-1.9,9.3c-0.1,4.5,1,9.1,3.2,13c2.3,4.1,6.1,7.4,9.4,10.7
					c3.7,3.8,7.5,7.5,11.2,11.2c3,3.1,6.1,6.1,9.1,9.2l0.1,0.1c-3.1,3.1-6.1,6.1-9.2,9.2c-3.7,3.8-7.5,7.5-11.2,11.2
					c-3.3,3.3-7.1,6.5-9.4,10.7c-2.2,3.9-3.3,8.6-3.2,13.1c0.1,3.1,0.7,6.4,1.9,9.3c0,0,62.3,0,62.3,0c0.4,0,1.3-3.8,1.4-4.2
					c0.8-3.2,1.1-6.6,0.7-9.9c-1.1-8.6-7.9-14.9-13.6-20.8c-5.3-5.4-10.7-10.7-16.2-15.9C615.5,82.6,614.5,81.6,613.6,80.6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className="nodeBckgrnd"
                  d="
					M835,140.7c31.2,0,36.4-32.2,36.4-32.2c0.8-7.4,0.4-40.9,0.4-50.2c0-4.8-0.6-9.7-2.1-14.3c-4.7-14.8-19.7-24.9-35-25.4
					c-18.3-0.7-37.7,13.5-39,32.5c-0.1,0.9-0.1,1.8-0.2,2.7c-0.9,14.6-0.4,41.1,0,55.7C795.5,109.5,799.4,140.7,835,140.7z"
                />
                <g id="progressLogo" className="progressInactive">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      className="progressStrokesCol"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      d="
					M835,140.7c31.2,0,36.4-32.2,36.4-32.2c0.8-7.4,0.4-40.9,0.4-50.2c0-4.8-0.6-9.7-2.1-14.3c-4.7-14.8-19.7-24.9-35-25.4
					c-18.3-0.7-37.7,13.5-39,32.5c-0.1,0.9-0.1,1.8-0.2,2.7c-0.9,14.6-0.4,41.1,0,55.7C795.5,109.5,799.4,140.7,835,140.7z"
                    />
                    <path
                      className="progressStrokesCol"
                      fill="none"
                      strokeMiterlimit="10"
                      d="M833.5,23.5c-8.9,0-17.3,3.5-23.7,9.9
					c-6.4,6.4-9.9,14.8-9.9,23.7v45.5c0,8.9,3.5,17.3,9.9,23.7c6.4,6.4,14.8,9.9,23.7,9.9s17.3-3.5,23.7-9.9c6.4-6.4,9.9-14.8,9.9-23.7
					V57.1c0-8.9-3.5-17.3-9.9-23.7C850.8,27,842.4,23.5,833.5,23.5z"
                    />

                    <path
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#B88523"
                      stroke="#2C4C32"
                      strokeMiterlimit="10"
                      d="
						M833.4,63.3c0.5-0.5,1-0.9,1.5-1.4c2.9-2.7,5.7-5.4,8.4-8.2c3-3.1,6.5-6.3,7.1-10.8c0.2-1.7,0.1-3.2-0.4-4.8
						c0-0.2-0.5-1.8-0.7-1.8c0,0-32.4,0-32.4,0c-0.6,1-0.9,2.9-1,4.5c0,2.3,0.5,4.6,1.7,6.6c1.2,2.1,3.2,3.8,4.9,5.5
						c1.9,2,3.9,3.9,5.9,5.8c1.6,1.6,3.2,3.2,4.7,4.7l0,0c-1.6,1.6-3.2,3.2-4.8,4.8c-1.9,2-3.9,3.9-5.9,5.8c-1.7,1.7-3.7,3.4-4.9,5.6
						c-1.1,2-1.7,4.5-1.7,6.8c0,1.6,0.4,4.8,1,4.8c0,0,32.4,0,32.4,0c0.2,0,0.7-2,0.7-2.2c0.4-1.7,0.6-3.4,0.4-5.2
						c-0.6-4.5-4.1-7.7-7.1-10.8c-2.7-2.8-5.6-5.6-8.4-8.3C834.4,64.3,833.8,63.8,833.4,63.3"
                    />

                    <line
                      id="bottomShadowL"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      stroke="#2C4C32"
                      strokeWidth="2px"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="90.9"
                      x2="808.5"
                      y2="90.9"
                    />

                    <line
                      id="bottomShadowR"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      stroke="#2C4C32"
                      strokeWidth="2px"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="90.9"
                      x2="858.5"
                      y2="90.9"
                    />

                    <line
                      id="leafR1_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="89"
                      x2="845.1"
                      y2="81.4"
                    />

                    <line
                      id="leafL1_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="89"
                      x2="821.8"
                      y2="81.4"
                    />

                    <line
                      id="leafR2_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="81.7"
                      x2="840.6"
                      y2="76.9"
                    />

                    <line
                      id="leafL2_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="81.7"
                      x2="826.3"
                      y2="76.9"
                    />

                    <line
                      id="leafR3"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="74.8"
                      x2="836.4"
                      y2="72.6"
                    />

                    <line
                      id="leafL3_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="74.8"
                      x2="830.6"
                      y2="72.6"
                    />

                    <line
                      id="vertLineLeaf"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="833.5"
                      y1="90.9"
                      x2="833.5"
                      y2="67.1"
                    />

                    <path
                      id="leafStrokeLeft"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="
							M819.7,90.9c0,0-3.2-6.5,2.4-12.6l11.7-11.7"
                    />

                    <path
                      id="leafStrokeRiht"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="
							M847.3,90.6c0,0,3.2-6.5-2.4-12.6l-11.7-11.4"
                    />

                    <g id="topLines_1_">
                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="829.2"
                          y1="56.9"
                          x2="837.6"
                          y2="56.9"
                        />

                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="823.9"
                          y1="51.6"
                          x2="842.9"
                          y2="51.6"
                        />

                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="819.7"
                          y1="46.4"
                          x2="847.4"
                          y2="46.4"
                        />

                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="818.6"
                          y1="40.9"
                          x2="848.1"
                          y2="40.9"
                        />
                    </g>

                    <path
                      className="progressStrokesFill"
                      fillRule="evenodd"
                      fill="none"
                      clipRule="evenodd"
                      d="M809.4,98.4v18.1c0,1.2,0.9,2.1,2.1,2.1h4v1.9h-4
					c-1,0-1.9-0.4-2.6-1c-0.1-0.1-0.3-0.2-0.4-0.4c-0.6-0.7-1-1.7-1-2.6V98.4H809.4L809.4,98.4z M818.7,108.5h1.9v12h-1.9V108.5
					L818.7,108.5z M834.3,100.3v8.2h6v1.9h-6v5.8l0,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0.1,1,1,1.8,2.1,1.8h3.9v1.9h-3.9
					c-1,0-1.9-0.3-2.6-1c-0.8-0.7-1.3-1.6-1.4-2.6V98.4h7.9v1.9H834.3L834.3,100.3z M819.7,105.6c0.7,0,1.2,0.5,1.2,1.2
					c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2C818.4,106.2,819,105.6,819.7,105.6L819.7,105.6z M856.5,116.1v4.4h-1.9v-4.4
					c-1.8-0.4-3-2-3-3.9V98.4h1.9l0,13.7c0,0.1,0,0.1,0,0.2c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1V98.4h1.9v13.8
					C859.5,114.1,858.2,115.7,856.5,116.1L856.5,116.1z M824.8,108.5h5.9v1.9h-5.9v5.7l0,0c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.4l0,0
					v3.6h-1.9v-18.7l0,0c0.1-0.8,0.4-1.5,1-2.1c0.1-0.1,0.3-0.3,0.4-0.4c0.7-0.6,1.6-1,2.6-1h4v1.9h-4c-1,0-1.9,0.7-2.1,1.7
					c0,0.1,0,0.2,0,0.4s0,0.2,0,0.4l0,0V108.5L824.8,108.5z M844.6,98.4v18.1c0,1.2,0.9,2.1,2.1,2.1h4v1.9h-4c-1,0-1.9-0.4-2.6-1
					c-0.1-0.1-0.3-0.2-0.4-0.4c-0.6-0.7-1-1.7-1-2.6V98.4H844.6z"
                    />

                    <path
                      className="progressStrokesCol"
                      d="M871.5,19.2c-0.8-0.8-1.8-1.2-2.9-1.2s-2.1,0.4-2.9,1.2c-0.8,0.8-1.2,1.8-1.2,2.9
						c0,1.1,0.4,2.1,1.2,2.9c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c0.8-0.8,1.2-1.8,1.2-2.9C872.7,21,872.3,20,871.5,19.2z
						 M868.6,25.5c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4C872.1,24,870.5,25.5,868.6,25.5z"
                    />
                    <path
                      id="XMLID_20_"
                      fill="white"
                      d="M869.6,22.2c0.5-0.2,0.8-0.6,0.8-1.1c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.8-0.3-1.4-0.3
						c-0.4,0-0.8,0-1.3,0.1v4.5h0.6v-2h0.8c0.7,0,1.1,0.7,1.3,2h0.6c-0.1-0.7-0.2-1.2-0.4-1.5C870,22.6,869.8,22.4,869.6,22.2z
						 M868.6,21.9h-0.8v-1.6c0.2,0,0.4,0,0.7,0c0.8,0,1.3,0.3,1.3,0.8C869.7,21.7,869.4,21.9,868.6,21.9z"
                    />

                    <rect
                      id="XMLID_287_"
                      x="775"
                      y="150"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      width="114.7"
                      height="22"
                    />
                    <text
                      className="progressText"
                      transform="matrix(1 0 0 1 783.3047 158.5915)"
                    >
                        <tspan x="-26" y="0">
                            Taste of SuperLife
                        </tspan>
                    </text>
                </g>
                <g id="progressHourglass" className="progressInactive">
                    <path
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      d="
					M613.6,80.6c1-0.9,2-1.8,2.9-2.7c5.5-5.2,10.9-10.4,16.2-15.8c5.7-5.9,12.5-12.1,13.6-20.7c0.4-3.3,0.1-6.8-0.7-10
					c-0.1-0.3-1-4.2-1.4-4.2c0,0-62.3,0-62.3,0c-1.1,3-1.8,6.1-1.9,9.3c-0.1,4.5,1,9.1,3.2,13c2.3,4.1,6.1,7.4,9.4,10.7
					c3.7,3.8,7.5,7.5,11.2,11.2c3,3.1,6.1,6.1,9.1,9.2l0.1,0.1c-3.1,3.1-6.1,6.1-9.2,9.2c-3.7,3.8-7.5,7.5-11.2,11.2
					c-3.3,3.3-7.1,6.5-9.4,10.7c-2.2,3.9-3.3,8.6-3.2,13.1c0.1,3.1,0.7,6.4,1.9,9.3c0,0,62.3,0,62.3,0c0.4,0,1.3-3.8,1.4-4.2
					c0.8-3.2,1.1-6.6,0.7-9.9c-1.1-8.6-7.9-14.9-13.6-20.8c-5.3-5.4-10.7-10.7-16.2-15.9C615.5,82.6,614.5,81.6,613.6,80.6"
                    />
                    <path
                      id="leafStrokeLeft_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeMiterlimit="10"
                      d="
					M586,134.2c0,0-6.9-12.7,4.1-24.6l23.2-23.2"
                    />
                    <path
                      id="leafStrokeRiht_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeMiterlimit="10"
                      d="
					M640.7,133.2c0,0,6.8-12.4-4.2-24.3l-23.1-22.5"
                    />

                    <line
                      id="vertLineLeaf_1_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeMiterlimit="10"
                      x1="613.3"
                      y1="133.2"
                      x2="613.3"
                      y2="86.4"
                    />

                    <line
                      id="leafR1_3_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="613.5"
                      y1="130.1"
                      x2="636.5"
                      y2="115.2"
                    />

                    <line
                      id="leafL1_3_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="613.1"
                      y1="130.1"
                      x2="590.2"
                      y2="115.2"
                    />

                    <line
                      id="leafR2_3_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="613.5"
                      y1="115.9"
                      x2="627.6"
                      y2="106.5"
                    />

                    <line
                      id="leafL2_3_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="613"
                      y1="115.8"
                      x2="599"
                      y2="106.4"
                    />

                    <line
                      id="leafR3_2_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="613.5"
                      y1="102.3"
                      x2="619.3"
                      y2="97.9"
                    />

                    <line
                      id="leafL3_3_"
                      className="progressStrokesCol"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="613"
                      y1="102.2"
                      x2="607.3"
                      y2="97.8"
                    />
                    <g id="topLines_3_">
                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="605.1"
                          y1="67"
                          x2="621.8"
                          y2="67"
                        />
                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="594.8"
                          y1="56.7"
                          x2="632.1"
                          y2="56.7"
                        />
                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="586.6"
                          y1="46.3"
                          x2="641.1"
                          y2="46.3"
                        />
                        <line
                          className="progressStrokesCol"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="584.3"
                          y1="35.5"
                          x2="642.5"
                          y2="35.5"
                        />
                    </g>
                    <rect
                      id="XMLID_253_"
                      x="552.2"
                      y="8.5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      width="123.3"
                      height="22.7"
                    />
                    <text
                      className="progressText"
                      transform="matrix(1 0 0 1 565.4875 17.0721)"
                    >
                        <tspan x="-30" y="-3">
                            Natural processes
                        </tspan>
                    </text>
                    <rect
                      id="XMLID_258_"
                      x="551.7"
                      y="142.3"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      width="123.3"
                      height="23.3"
                    />
                    <text
                      className="progressText"
                      transform="matrix(1 0 0 1 554.2867 150.9248)"
                    >
                        <tspan x="-37" y="8">
                            Superfood Ingredients
                            { ' ' }
                        </tspan>
                    </text>
                </g>
                <g id="progressStevia" className="progressInactive">
                    <path
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      fill="none"
                      clipRule="evenodd"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      d="
					M329.3,126.2c0,0,105.6,0,105.6,0c0.7,0,2.2-6.7,2.4-7.2c1.4-5.4,1.9-11.3,1.2-16.9c-1.9-14.7-13.4-25.2-23.1-35.3
					c-8.9-9.2-18.1-18.1-27.5-26.9c-1.8-1.7-3.6-3.4-5.4-5.1c-5.3,5.3-10.5,10.6-15.8,15.9c-6.3,6.4-12.7,12.7-19.1,19
					c-5.6,5.6-12,11.1-16,18.1c-3.7,6.6-5.6,14.8-5.5,22.4C326.3,115.6,327.4,121.2,329.3,126.2z"
                    />
                    <line
                      id="leafR1_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.5"
                      y1="118.5"
                      x2="422.9"
                      y2="92.3"
                    />
                    <line
                      id="leafL1_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.6"
                      y1="118.5"
                      x2="342.2"
                      y2="92.3"
                    />
                    <line
                      id="leafR2_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.6"
                      y1="93.5"
                      x2="407.3"
                      y2="77"
                    />
                    <line
                      id="leafL2_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.4"
                      y1="93.4"
                      x2="357.7"
                      y2="76.8"
                    />
                    <line
                      id="leafR3_1_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.6"
                      y1="69.6"
                      x2="392.7"
                      y2="61.8"
                    />
                    <line
                      id="leafL3_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.6"
                      y1="69.6"
                      x2="372.5"
                      y2="61.8"
                    />
                    <line
                      id="vertLineLeaf_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      x1="382.6"
                      y1="126.4"
                      x2="382.6"
                      y2="43.7"
                    />
                    <path
                      id="leafStrokeRiht_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="
					M429.5,125.3c0,0,11.3-22.9-7.5-43.2l-39.4-38.5"
                    />
                    <path
                      id="leafStrokeLeft_2_"
                      className="progressStrokesCol prgStevia"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="
					M334.9,125.2c0,0-10.7-21.7,8.1-42l39.6-39.6"
                    />
                    <rect
                      id="XMLID_245_"
                      x="552.2"
                      y="8.5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      width="123.3"
                      height="22.7"
                    />
                    <text
                      className="progressText prgSteviaText"
                      transform="matrix(1 0 0 1 357 17.0721)"
                    >
                        <tspan x="0" y="0">
                            Stevia
                            { ' ' }
                        </tspan>
                    </text>
                    <rect
                      id="XMLID_248_"
                      x="320"
                      y="133.7"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      width="122.5"
                      height="21.3"
                    />
                    <text
                      className="progressText prgSteviaText"
                      transform="matrix(1 0 0 1 323.8867 144.2887)"
                    >
                        <tspan x="-3" y="14.4">
                            The Magic leaf
                        </tspan>
                    </text>
                </g>
                <g id="progressFirst">
                    <path
                      className="progressStrokesCol prgFirst"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      strokeWidth="3"
                      strokeMiterlimit="10"
                      d="
					M68,37.2h107c0,0,10.9,23.7-7.1,44s-45.7,45-46.6,45.2c-0.9,0.2-47.5-45.8-47.5-45.8S57,63.2,68,37.2z"
                    />
                    <g id="topLines_2_">
                        <line
                          className="progressStrokesCol prgFirst"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="106.8"
                          y1="102.8"
                          x2="135"
                          y2="102.8"
                        />

                        <line
                          className="progressStrokesCol prgFirst"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="89.3"
                          y1="85.3"
                          x2="152.5"
                          y2="85.3"
                        />

                        <line
                          className="progressStrokesCol prgFirst"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="75.3"
                          y1="67.7"
                          x2="167.9"
                          y2="67.7"
                        />

                        <line
                          className="progressStrokesCol prgFirst"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="none"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          x1="71.5"
                          y1="49.3"
                          x2="170.2"
                          y2="49.3"
                        />
                    </g>
                    <text
                      className="progressText prgFirstText"
                      transform="matrix(1 0 0 1 90.7271 17.0721)"
                    >
                        <tspan x="-9" y="0">
                            The Need
                        </tspan>
                    </text>
                    <rect
                      id="XMLID_245_"
                      x="71.5"
                      y="130.3"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="none"
                      width="100.5"
                      height="31.5"
                    />
                    <text
                      className="progressText prgFirstText"
                      transform="matrix(1 0 0 1 93.7271 144.9255)"
                    >
                        <tspan x="-41" y="14.4">
                            like never before
                        </tspan>
                    </text>
                </g>
            </svg>
        );
    }

    renderContent() {
        const { device } = this.props;
        return (
            <Suspense fallback={ <Loader /> }>
                { !device.safari && this.renderProgressbarWrapper() }
                { this.renderStoryContent() }
            </Suspense>
        );
    }

    render() {
        return (
            <div id="sectionContainer" block="cms LifelyStory-in">
                { this.renderContent() }
            </div>
        );
    }
}

export default LifelyStoryDesktopComponent;
