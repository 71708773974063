/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CancelIcon.style';

/** @namespace LifelyScandi/Component/Custom/Icons/CancelIcon/Component/CancelIconComponent */
export class CancelIconComponent extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        fill: 'grey',
        width: '25',
        height: '25'
    };

    render() {
        const { fill, width, height } = this.props;
        return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={ width }
              height={ height }
              zoomAndPan="magnify"
              viewBox="0 0 37.5 37.499999"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
            >
                <defs>
                    <clipPath id="cancelIcon1">
                        <path d="M 0.710938 0.710938 L 36.710938 0.710938 L 36.710938 36.710938 L 0.710938 36.710938 Z M 0.710938 0.710938 " clipRule="nonzero" />
                    </clipPath>
                </defs>
                <g clipPath="url(#cancelIcon1)">
                    <path fill={ fill } d="M 18.707031 0.707031 C 8.78125 0.707031 0.707031 8.78125 0.707031 18.707031 C 0.707031 28.632812 8.78125 36.707031 18.707031 36.707031 C 28.632812 36.707031 36.707031 28.632812 36.707031 18.707031 C 36.707031 8.78125 28.632812 0.707031 18.707031 0.707031 Z M 18.707031 34.820312 C 9.824219 34.820312 2.597656 27.59375 2.597656 18.707031 C 2.597656 9.824219 9.824219 2.597656 18.707031 2.597656 C 27.59375 2.597656 34.820312 9.824219 34.820312 18.707031 C 34.820312 27.59375 27.59375 34.820312 18.707031 34.820312 Z M 18.707031 34.820312 " fillOpacity="1" fillRule="nonzero" />
                </g>
                <path fill={ fill } d="M 27.265625 10.148438 C 26.898438 9.78125 26.300781 9.78125 25.929688 10.148438 L 18.707031 17.375 L 11.484375 10.148438 C 11.117188 9.78125 10.519531 9.78125 10.148438 10.148438 C 9.78125 10.519531 9.78125 11.117188 10.148438 11.484375 L 17.375 18.707031 L 10.148438 25.929688 C 9.78125 26.300781 9.78125 26.898438 10.148438 27.265625 C 10.332031 27.449219 10.574219 27.542969 10.816406 27.542969 C 11.058594 27.542969 11.300781 27.449219 11.484375 27.265625 L 18.707031 20.042969 L 25.929688 27.265625 C 26.117188 27.449219 26.355469 27.542969 26.597656 27.542969 C 26.839844 27.542969 27.082031 27.449219 27.265625 27.265625 C 27.632812 26.898438 27.632812 26.300781 27.265625 25.929688 L 20.042969 18.707031 L 27.265625 11.484375 C 27.632812 11.117188 27.632812 10.519531 27.265625 10.148438 Z M 27.265625 10.148438 " fillOpacity="1" fillRule="nonzero" />
            </svg>
        );
    }
}

export default CancelIconComponent;
