/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { UPDATE_MOBILE_POPUP_ID } from '../../component/UpdateMobilePopup/UpdateMobilePopup.config';
import { showPopup } from 'Store/Popup/Popup.action';

import UpdateMobileno from './UpdateMobileno.component';

/** @namespace Component/UpdateMobileno/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
   showUpdateMobilePopup: (payload) => dispatch(showPopup(UPDATE_MOBILE_POPUP_ID, payload))
});


/** @namespace Component/UpdateMobileno/Container */
export class UpdateMobilenoContainer extends PureComponent {
   static propTypes = {
       showUpdateMobilePopup: PropTypes.func.isRequired,
   };

   containerFunctions = {
       showUpdateMobilePopup: this.showUpdateMobilePopup.bind(this)
   };

   showUpdateMobilePopup() {
       const { showUpdateMobilePopup } = this.props;
   
       showUpdateMobilePopup({
         title: __("Update Mobile Number")
       });
     }

   render() {
       return (
           <UpdateMobileno
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
           />
       );
   }
}

export default connect(null, mapDispatchToProps)(UpdateMobilenoContainer);