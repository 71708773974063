/* eslint-disable no-magic-numbers */
// import PropTypes from 'prop-types';

import { toggleStickyHeader } from '../../store/Header/Header.action';

export class HeaderContainerPlugin {
    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            toggleStickyHeader: (value) => dispatch(toggleStickyHeader(value))
        };
    };

    mapStateToProps = (args, callback) => {
        const [state] = args;
        return {
            ...callback(...args),
            atTop: state.StickyHeaderReducer.atTop
        };
    };

    aroundContainerProps(args, callback, instance) {
        // eslint-disable-next-line no-unused-vars
        const { atTop, toggleStickyHeader } = instance.props;
        const newElement = { ...callback.apply(instance, args), atTop };
        return newElement;
    }

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        stickyHeader: this.stickyHeader.bind(instance)
    });

    componentDidMount(args, callback, instance) {
        callback.apply(args, instance);
        window.addEventListener('scroll', () => instance.containerFunctions.stickyHeader(instance));
    }

    componentWillUnmount(args, callback, instance) {
        callback.apply(args, instance);
        window.removeEventListener('scroll', () => instance.containerFunctions.stickyHeader(instance));
    }

    stickyHeader(instance) {
        const { toggleStickyHeader } = instance.props;
        const number = window.pageXOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop
        || 0;

        if (number >= 10) {
            toggleStickyHeader(false);
        } else {
            toggleStickyHeader(true);
        }
    }
}

const {
    mapStateToProps, containerFunctions,
    aroundContainerProps, componentDidMount, componentWillUnmount, mapDispatchToProps
} = new HeaderContainerPlugin();

export const config = {
    'Component/Header/Container': {
        'member-function': {
            componentDidMount,
            componentWillUnmount,
            containerProps: aroundContainerProps
        },
        'member-property': {
            containerFunctions
        }
    },
    'Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Header/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
