/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-redundant-should-component-update */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { PureComponent, Suspense } from 'react';
import Modal from 'react-modal';

import { ChildrenType } from 'Type/Common';

// import BrowserDatabase from 'Util/BrowserDatabase';
import './SlidingPane.style';

export const Loader = React.lazy(() => import('Component/Loader'));

/** @namespace LifelyScandi/Component/Custom/SlidingPane/Component/SlidingPaneComponent */
export class SlidingPaneComponent extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        message: PropTypes.object,
        from: PropTypes.string,
        children: ChildrenType,
        footer: PropTypes.string,
        className: PropTypes.string,
        overlayClassName: PropTypes.string,
        width: PropTypes.string, // CSS string for width
        user: PropTypes.bool,
        shouldCloseOnEsc: PropTypes.bool,
        hideHeader: PropTypes.bool,
        onRequestClose: PropTypes.func,
        onButtonClick: PropTypes.func,
        onAfterOpen: PropTypes.func,
        onAfterClose: PropTypes.func,
        payableAmount: PropTypes.string,
        informationText: PropTypes.string,
        key: PropTypes.string,
        btnPrimaryDark: PropTypes.bool,
        btnPrimaryBase: PropTypes.bool,
        btnSecondaryDark: PropTypes.bool,
        btnSecondaryBase: PropTypes.bool
    };

    static defaultProps = {
        isOpen: false,
        title: 'Sliding Panel',
        subtitle: '',
        message: '',
        className: '',
        overlayClassName: '',
        from: 'right',
        children: [],
        footer: '',
        width: '80%', // CSS string for width
        user: false,
        shouldCloseOnEsc: true,
        hideHeader: false,
        onRequestClose: () => { },
        onButtonClick: () => { },
        onAfterOpen: () => { },
        onAfterClose: () => { },
        payableAmount: '',
        informationText: '',
        key: '',
        btnPrimaryDark: '',
        btnPrimaryBase: '',
        btnSecondaryDark: '',
        btnSecondaryBase: ''
    };

    state = {
        wasOpen: false
    };

    iconClose() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 22">
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M4 11l8 8c.6.5.6 1.5 0 2-.5.6-1.5.6-2 0l-9-9c-.6-.5-.6-1.5 0-2l9-9c.5-.6 1.5-.6 2 0 .6.5.6 1.5 0 2l-8 8z"
                />
            </svg>
        );
    }

    renderSlindingPanel() {
        const {
            isOpen,
            title,
            subtitle,
            message,
            onRequestClose,
            onButtonClick,
            onAfterOpen,
            onAfterClose,
            children,
            footer,
            className,
            overlayClassName,
            user,
            from,
            width,
            shouldCloseOnEsc,
            hideHeader,
            payableAmount,
            informationText,
            key,
            btnPrimaryDark,
            btnPrimaryBase,
            btnSecondaryDark,
            btnSecondaryBase
        } = this.props;

        const { wasOpen } = this.state;
        const CLOSE_TIMEOUT = 1000;

        const handleAfterOpen = () => {
            // Timeout fixes animation in Safari
            onAfterOpen();
            setTimeout(() => {
                this.setState({ wasOpen: true });
            }, 0);
        };

        const handleAfterClose = () => {
            onAfterClose();
            setTimeout(() => {
                this.setState({ wasOpen: false });
            }, 0);
        };

        const directionClass = `slide-pane_from_${ from }`;
        return (
            <Modal
              key={ key }
              ariaHideApp={ false }
              overlayClassName={ {
                  base: `slide-pane__overlay ${ overlayClassName || '' }`,
                  afterOpen: wasOpen ? 'overlay-after-open' : '',
                  beforeClose: 'overlay-before-close'
              } }
              className={ {
                  base: `slide-pane ${ directionClass } ${ className || '' }`,
                  afterOpen: wasOpen ? 'content-after-open' : '',
                  beforeClose: 'content-before-close'
              } }
              style={ {
                  content: { width: width || '80%' }
              } }
              closeTimeoutMS={ CLOSE_TIMEOUT }
              isOpen={ isOpen }
              shouldCloseOnEsc={ shouldCloseOnEsc }
              onAfterOpen={ handleAfterOpen }
              onAfterClose={ handleAfterClose }
              onRequestClose={ onRequestClose }
              contentLabel={ `Modal "${ title || '' }"` }
            >
                { !hideHeader && (
                    <div className="slide-pane__header">
                        <div className="slide-pane__close" onClick={ onRequestClose }>
                            { this.iconClose() }
                        </div>
                        <div className="slide-pane__title-wrapper">
                            <h2 className="slide-pane__title">{ title }</h2>
                            <div className="slide-pane__subtitle">{ subtitle }</div>
                        </div>
                    </div>
                ) }
                { user && (<div className="slide-pane__message">{ message }</div>) }
                <div className="slide-pane__content">{ children }</div>
                { footer !== '' && (
                    <div
                      className={ `slide-pane__footer 
                      ${ btnPrimaryDark ? 'FooterPrimaryDarkShadow' : '' }
                      ${ btnPrimaryBase ? 'FooterPrimaryBaseShadow' : '' }
                      ${ btnSecondaryDark ? 'FooterSecondaryDarkShadow' : '' }
                      ${ btnSecondaryBase ? 'FooterSecondaryBaseShadow' : '' }
                      ` }
                    >
                        { informationText && payableAmount && (
                            <p className="slide-pane__payableamount">
                                { informationText }
                                <strong className="payable-amount">
                                    { payableAmount || '' }
                                </strong>
                            </p>
                        ) }
                        <button
                          className={ `Button footer-btn 
                          ${ btnPrimaryDark ? 'btnPrimaryDark' : '' }
                          ${ btnPrimaryBase ? 'btnPrimaryBase' : '' }
                          ${ btnSecondaryDark ? 'btnSecondaryDark' : '' }
                          ${ btnSecondaryBase ? 'btnSecondaryBase' : '' }
                          ` }
                          onClick={ onButtonClick }
                        >
                            { footer }
                        </button>
                    </div>
                ) }

            </Modal>
        );
    }

    render() {
        return (
            <Suspense fallback={ <Loader isLoading /> }>
                <div className="SlidingPane">
                    { this.renderSlindingPanel() }
                </div>
            </Suspense>
        );
    }
}

export default SlidingPaneComponent;
