/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './OfferIcon.style';

/** @namespace LifelyScandi/Component/Custom/Icons/OfferIcon/Component/OfferIconComponent */
export class OfferIconComponent extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        fill: 'black',
        width: '25',
        height: '25'
    };

    render() {
        const { fill, width, height } = this.props;
        return (

            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width={ width }
              height={ height }
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={ fill } stroke="none">
                    <path d="M2471 5109 c-104 -20 -157 -57 -359 -250 -127 -121 -206 -189 -231
-198 -35 -13 -59 -11 -267 17 -126 18 -259 33 -295 33 -150 2 -286 -70 -370
-194 -50 -76 -61 -113 -109 -389 -22 -126 -47 -242 -55 -259 -19 -35 -45 -52
-250 -159 -279 -146 -327 -184 -385 -305 -32 -68 -41 -116 -42 -207 0 -84 15
-126 139 -380 81 -167 106 -227 106 -258 0 -31 -25 -91 -106 -258 -124 -254
-139 -296 -139 -380 1 -91 10 -139 42 -207 58 -121 106 -159 385 -305 205
-107 231 -124 250 -159 8 -17 33 -133 55 -260 22 -126 47 -250 54 -275 54
-180 222 -305 411 -307 39 -1 175 14 304 32 213 29 237 31 272 18 25 -9 104
-77 231 -198 153 -147 205 -190 255 -213 85 -40 175 -54 259 -40 125 21 171
51 382 253 127 121 206 189 231 198 35 13 59 11 267 -17 126 -18 259 -33 295
-33 150 -2 286 70 370 194 50 76 61 113 109 389 22 126 47 242 55 259 19 36
41 50 276 173 222 117 271 152 324 232 53 78 76 152 77 250 0 102 -10 131
-139 396 -81 167 -106 227 -106 258 0 31 25 91 106 258 129 265 139 294 139
396 -1 98 -24 172 -77 250 -53 80 -102 115 -324 232 -235 123 -257 137 -276
173 -8 17 -33 133 -55 259 -48 276 -59 313 -109 389 -84 124 -220 196 -370
194 -36 0 -169 -15 -295 -33 -208 -28 -232 -30 -267 -17 -25 9 -104 77 -231
198 -151 144 -206 190 -253 212 -96 45 -186 57 -284 38z m-388 -1234 c231 -49
406 -252 424 -492 19 -253 -155 -493 -404 -558 -183 -48 -375 7 -513 145 -164
164 -206 400 -109 607 107 227 356 350 602 298z m1525 -215 c64 -39 91 -126
58 -188 -18 -33 -1992 -2005 -2023 -2021 -34 -17 -95 -13 -131 9 -64 39 -91
126 -58 188 17 30 1990 2004 2021 2021 32 17 97 13 133 -9z m-317 -1366 c141
-42 277 -152 338 -273 104 -205 70 -453 -82 -612 -174 -182 -413 -227 -632
-120 -176 87 -288 253 -302 448 -19 250 154 491 400 558 72 19 211 19 278 -1z"
                    />
                    <path d="M1865 3567 c-88 -42 -137 -119 -138 -217 -1 -139 104 -244 243 -244
245 0 335 319 126 449 -68 41 -159 46 -231 12z"
                    />
                    <path d="M3095 2011 c-212 -55 -256 -331 -71 -445 66 -41 151 -47 224 -16 188
81 195 347 11 440 -42 22 -122 32 -164 21z"
                    />
                </g>
            </svg>
        );
    }
}

export default OfferIconComponent;
