import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Ribbon.style';

/** @namespace LifelyScandi/Component/Custom/Ribbon/Component/RibbonComponent */
export class RibbonComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string
    };

    static defaultProps = {
        title: 'Welcome To Lifely'
    };

    render() {
        const { title } = this.props;
        return (
                <div block="Ribbon" elem="ribbon">
                    <span block="Ribbon" elem="ribbon-content">{ title }</span>
                </div>
        );
    }
}

export default RibbonComponent;
