import PropTypes from 'prop-types';

import { CART } from 'Component/Header/Header.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { toggleScroll } from 'Util/Browser';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { currentSlidePane, toggleDrawerCart } from '../../store/custom/DrawerCart/DrawerCart.action';

/** @namespace LifelyScandi/Plugin/DrawerCart/HeaderContainer/Pplugin/HeaderContainerplugin */
export class HeaderContainerPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;
        return {
            ...callback(...args),
            isPaneOpen: state.DrawerCartReducer.isPaneOpen,
            slidePaneName: state.DrawerCartReducer.slidePaneName,
            currency_code: state.ConfigReducer.default_display_currency_code
        };
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;
        return {
            ...callback(...args),
            showNotification: (type, message) => dispatch(showNotification(type, message)),
            toggleDrawerCart: (type) => dispatch(toggleDrawerCart(type)),
            currentSlidePane: (slidePaneName) => dispatch(currentSlidePane(slidePaneName))
        };
    };

    aroundPropTypes = (originalMember) => ({
        ...originalMember,
        isPaneOpen: PropTypes.bool,
        slidePaneName: PropTypes.string,
        closeDrawerCart: PropTypes.func,
        secureCheckout: PropTypes.func,
        showNotification: PropTypes.func.isRequired,
        currency_code: PropTypes.string
    });

    aroundContainerFunctions = (originalMember, instance) => ({
        ...originalMember,
        closeDrawerCart: this.closeDrawerCart.bind(instance),
        secureCheckout: this.secureCheckout.bind(instance),
        continueShopping: this.continueShopping.bind(instance)
    });

    aroundContainerProps(args, callback, instance) {
        const { isPaneOpen, slidePaneName } = instance.props;
        return { ...callback.apply(instance, args), isPaneOpen, slidePaneName };
    }

    secureCheckout() {
        const { showNotification } = this.props;
        if (isSignedIn()) {
            history.push({
                pathname: appendWithStoreCode(`${CHECKOUT_URL}`)
            });
            window.scrollTo({ top: 0 });
            return;
        }
        showNotification('info', __('Please sign-in to complete checkout!'));
        history.push({
            pathname: appendWithStoreCode('/account/login')
        });
    }

    continueShopping() {
        history.push({
            pathname: appendWithStoreCode('/products.html')
        });
    }

    closeDrawerCart() {
        const { toggleDrawerCart, currentSlidePane } = this.props;
        currentSlidePane('');
        toggleDrawerCart(false);
        toggleScroll(true);
        return true;
    }

    onMinicartButtonClickActions = (args, callback, instance) => {
        const {
            device, toggleDrawerCart, currentSlidePane
        } = instance.props;

        if (!device.isMobile) {
            currentSlidePane('cart');
            toggleDrawerCart(true);
            toggleScroll(false);
            return;
        }
        history.push(appendWithStoreCode(`/${ CART }`));
    };
}

export const {
    mapStateToProps, aroundContainerProps, mapDispatchToProps, onMinicartButtonClickActions, aroundPropTypes,
    aroundContainerFunctions
} = new HeaderContainerPlugin();
export default {

    'Component/Header/Container': {
        'member-function': {
            onMinicartButtonClick: [
                {
                    position: 101,
                    implementation: onMinicartButtonClickActions
                }
            ],
            containerProps: [
                {
                    position: 101,
                    implementation: aroundContainerProps
                }
            ]

        },
        'member-property': {
            containerFunctions: [
                {
                    position: 101,
                    implementation: aroundContainerFunctions
                }
            ]
        },
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: aroundPropTypes
                }
            ]
        }
    },
    'Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/Header/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
