import {
    STAR_EMPTY as SOURCE_STAR_EMPTY,
    STAR_FULL as SOURCE_STAR_FULL,
    STAR_HALF_FULL as SOURCE_STAR_HALF_FULL
} from 'SourceComponent/StarIcon/StarIcon.config';

// TODO: implement STAR_EMPTY
export const STAR_EMPTY = SOURCE_STAR_EMPTY;

// TODO: implement STAR_HALF_FULL
export const STAR_HALF_FULL = SOURCE_STAR_HALF_FULL;

// TODO: implement STAR_FULL
export const STAR_FULL = SOURCE_STAR_FULL;
