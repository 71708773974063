/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getStoreConfigFieldsAction = (args, callback, instance) => [
    ...callback(...args),
    new Field('store_code')
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _getStoreConfigFieldsAction
        }
    }
};
