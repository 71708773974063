import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import Overlay from 'Component/Overlay/Overlay.component';
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.style';

/** @namespace LifelyScandi/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    static defaultProps = {
        ...Overlay.defaultProps,
        clickOutside: true,
        title: null
    };

    renderCloseButton() {
        const { isMobile } = this.props;
        if (isMobile) {
            return (
                <button
                  block="Popup"
                  id="CloseIconPopup"
                  elem="CloseBtn"
                  aria-label={ __('Close') }
                  onClick={ this.hidePopUp }
                >
                    <CloseIcon fill="#ffffff" />
                </button>
            );
        }

        return (
            <button
              block="Popup"
              id="CloseIconPopup"
              elem="CloseBtnContainer"
              aria-label={ __('Close') }
              onClick={ this.hidePopUp }
            >
                <div
                  block="Popup"
                  elem="CloseBtn"
                >
                    <CloseIcon fill="#ffffff" />
                </div>
            </button>
        );
    }

    renderTitle() {
        const { title } = this.props;

        return (
            <h3 block="Popup" elem="Heading">
                { title || '' }
            </h3>
        );
    }

    renderContent() {
        const {
            children, contentMix, isMobile, title
        } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        if (isMobile) {
            return (
                <ClickOutside onClick={ this.handleClickOutside }>
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        <header block="Popup" elem="Header">
                            { this.renderTitle() }
                            { this.renderCloseButton() }
                        </header>
                        { this.renderNotifications() }
                        { children }
                    </div>
                </ClickOutside>
            );
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="MainPopUpContainer">
                    { this.renderCloseButton() }
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        { title && title.length > 0 && (
                            <header block="Popup" elem="Header">
                                { this.renderTitle() }
                            </header>
                        ) }
                        <div block="Popup" elem="ChildrenContainer">
                            { children }
                        </div>
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
