/* eslint-disable max-lines */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/boolean-prop-naming */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CartCoupon from 'Component/CartCoupon';
import CheckoutOrderSummary from 'Component/CheckoutOrderSummary/CheckoutOrderSummary.container';
import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import LockIcon from 'Component/LockIcon';
import ProductLinks from 'Component/ProductLinks';
import { CROSS_SELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { TotalsType } from 'Type/MiniCart';
import media from 'Util/Media';
import { formatPrice } from 'Util/Price';

import CartItem from '../../CartItem';
import CheckEstimatedDelivery from '../CheckEstimatedDelivery';
import OfferIcon from '../Icons/OfferIcon';
import OfferDetailBottomSheet from '../OfferDetailBottomSheet';

import './AltCart.style';

/** @namespace LifelyScandi/Component/Custom/AltCart/Component/AltCartComponent */
export class AltCartComponent extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
        onCheckoutButtonClick: PropTypes.func.isRequired,
        hasOutOfStockProductsInCart: PropTypes.bool,
        onCouponCodeUpdate: PropTypes.func
    };

    static defaultProps = {
        hasOutOfStockProductsInCart: false,
        onCouponCodeUpdate: () => { }
    };

    renderOfferIcon() {
        const { toggleOffersListBottomSheet } = this.props;
        return (
            <button
              block="AltCart"
              elem="OffersIcon"
              onClick={ toggleOffersListBottomSheet }
              aria-label="Offers"
            >
                <OfferIcon fill="white" width="20" height="20" />
            </button>
        );
    }

    renderBottomSheet() {
        const {
            BottomSheetIsVisible, closeBottomSheet, showAllOffersDetail, showSpecificOfferDetail
        } = this.props;

        return (
            <div
              block="AltCart"
              elem="BottomSheetContainer"
            >
                { BottomSheetIsVisible && (
                    <OfferDetailBottomSheet
                      BottomSheetIsVisible={ BottomSheetIsVisible }
                      closeBottomSheet={ closeBottomSheet }
                      showAllOffersDetail={ showAllOffersDetail }
                      showSpecificOfferDetail={ showSpecificOfferDetail }
                    />
                ) }

            </div>
        );
    }

    renderCartItems() {
        const { totals: { items, quote_currency_code } } = this.props;
        if (!items || items.length < 1) {
            return (
                <div>
                    <div
                      block="AltCart"
                      elem="EmptryCartImageWrapper"
                    >
                        <img
                          src={ media('assets/empty-cart.webp') }
                          alt=""
                        />
                    </div>
                </div>
            );
        }

        return (
            <div block="AltCart" elem="Items" aria-label="List of items in cart">
                { items.map((item) => (
                    <CartItem
                      key={ item.item_id }
                      item={ item }
                      currency_code={ quote_currency_code }
                      isEditing
                      updateCrossSellsOnRemove
                    />
                )) }
            </div>
        );
    }

    renderDiscountCode() {
        const {
            totals: { coupon_code, items }, getProductItemSpecialPrice
        } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ __('') }
              mix={ { block: 'AltCart', elem: 'Discount' } }
              isArrow
            >
                <CartCoupon couponCode={ coupon_code } getProductItemSpecialPrice={ getProductItemSpecialPrice } />
            </ExpandableContent>
        );
    }

    renderCheckEstimateDelivery() {
        const {
            totals: { items }
        } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <CheckEstimatedDelivery isIntoCard />
        );
    }

    renderSecureCheckoutButton() {
        const { onCheckoutButtonClick, hasOutOfStockProductsInCart } = this.props;

        if (hasOutOfStockProductsInCart) {
            return (
                <div block="AltCart" elem="OutOfStockProductsWarning">
                    { __('Please, remove out of stock products from cart') }
                </div>
            );
        }

        return (
            <div block="AltCart" elem="CheckoutButtonWrapper">
                <button
                  block="AltCart"
                  elem="CheckoutButton"
                  mix={ { block: 'Button' } }
                  onClick={ onCheckoutButtonClick }
                >
                    <LockIcon />
                    { __('Proceed to checkout') }
                </button>
            </div>
        );
    }

    renderCrossSellProducts() {
        return (
            <ProductLinks
              linkType={ CROSS_SELL }
              isCartPageLinkedProduct
              title={ __('Also Recommended') }
            />
        );
    }

    renderSummary() {
        const {
            totals,
            onCouponCodeUpdate,
            device: { isMobile }
        } = this.props;

        return (
            <CheckoutOrderSummary
              totals={ totals }
                // eslint-disable-next-line react/jsx-no-bind
              renderCmsBlock={ () => this.renderPromo(true) }
              onCouponCodeUpdate={ onCouponCodeUpdate }
              showItems={ false }
            >
                { isMobile && this.renderSecureCheckoutButton() }
            </CheckoutOrderSummary>
        );
    }

    renderTotals() {
        return (
            <article
              block="AltCart"
              elem="Summary"
              mix={ { block: 'FixedElement', elem: 'Bottom' } }
            >
                { this.renderSummary() }
            </article>
        );
    }

    renderPromoContent() {
        const { cart_content: { cart_cms } = {} } = window.contentConfiguration;

        if (cart_cms) {
            return <CmsBlock identifier={ cart_cms } />;
        }

        return (
            <figure
              block="AltCart"
              elem="PromoBlock"
            >
                <figcaption block="AltCart" elem="PromoText">
                    { __('Free shipping on all orders.') }
                </figcaption>
            </figure>
        );
    }

    renderPromo() {
        return (
            <div
              block="AltCart"
              elem="Promo"
            >
                { this.renderPromoContent() }
            </div>
        );
    }

    renderHeading() {
        return (
            <h1 block="AltCart" elem="Heading">
                { __('') }
            </h1>
        );
    }

    renderTotalsSection() {
        const { totals: { items = [] } } = this.props;

        if (items.length < 1) {
            //  return this.renderPromo();
            return null;
        }

        return (
            <div block="AltCart" elem="Floating">
                { /* { this.renderPromo() } */ }
                { this.renderTotals() }
            </div>
        );
    }

    renderSavingTotalAmount() {
        const { specialPriceDiscount, totals: { quote_currency_code } } = this.props;
        const sum = specialPriceDiscount;
        if (!sum) {
            return null;
        }

        return (
            <div block="AltCart" elem="SavingTotalAmountContainer">
                <div>
                    <span>
                        🥳
                    </span>
                    { ` You are saving ${ formatPrice(sum, quote_currency_code) } for this order ` }
                    <span>
                        🥳
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const { device: { isMobile } } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <main block="AltCart" aria-label="AltCart Page">
                <ContentWrapper
                  wrapperMix={ { block: 'AltCart', elem: 'Wrapper' } }
                  label="AltCart page details"
                >
                    <div block="AltCart" elem="Static">
                        { this.renderSavingTotalAmount() }
                        { this.renderCartItems() }
                        { this.renderDiscountCode() }
                        { /* { this.renderCheckEstimateDelivery() } */ }
                        { this.renderCrossSellProducts() }
                    </div>
                    { this.renderTotalsSection() }
                </ContentWrapper>
                { this.renderOfferIcon() }
                { this.renderBottomSheet() }
            </main>
        );
    }
}

export default AltCartComponent;
