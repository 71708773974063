/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
// import { CartQuery } from 'Query/Cart.Query';
import { showNotification } from 'Store/Notification/Notification.action';
// import { getGuestQuoteId } from 'Util/Cart';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { fetchMutation, fetchQuery } from 'Util/Request';

import RazorpayQuery from '../../query/Razorpay.query';
import { RAZORPAY_CHECKOUT_JS_URL } from './Razorpay.config';

/** @namespace RazorpayPayments/Component/Razorpay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    razorpayKey: state.ConfigReducer.razorpay_key_id,
    razorpayMerchantName: state.ConfigReducer.razorpay_merchant_name_override,
    currency: state.CartReducer.cartTotals.base_currency_code,
    customer: state.MyAccountReducer.customer,
    logoSrc: state.ConfigReducer.header_logo_src
});
/** @namespace RazorpayPayments/Component/Razorpay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))

});

/** @namespace RazorpayPayments/Component/Razorpay/Container/RazorpayContainer */
export class RazorpayContainer extends PureComponent {
    // static propTypes = {
    //     isLoading: PropTypes.bool
    // };

    // static defaultProps = {
    //     isLoading: false
    // };

    state={
        isLoading: false
    };

    __construct(props) {
        super.__construct(props);
        this.handleScriptError = this.handleScriptError.bind(this);
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
        this.handleScriptCreate = this.handleScriptCreate.bind(this);
    }

    getCustomerCartId= async () => {
        if (this.customerCartId) {
            return this.customerCartId;
        }
        const { customerCart: { id } } = await fetchQuery(
            RazorpayQuery.getCustomerCart()
        );

        this.customerCartId = id;
        return this.customerCartId;
    };

    getRzpOrderId = async () => {
        const cartId = await this.getCustomerCartId();
        const result = await fetchMutation(RazorpayQuery.getRzpOrderIdMutation(cartId));
        return result;
    };

    setPaymentMethodOnCart = async () => {
        const cartId = await this.getCustomerCartId();
        const result = await fetchMutation(RazorpayQuery.setPaymentMethodOnCart(cartId));
        return result;
    };

    saveRzpResponse = async (response) => {
        const cartId = await this.getCustomerCartId();
        const options = {
            cart_id: cartId,
            rzp_payment_id: response.razorpay_payment_id,
            rzp_order_id: response.razorpay_order_id,
            rzp_signature: response.razorpay_signature
        };
        const mutation = RazorpayQuery.saveRzpResponseMutation(options);

        const result = await fetchMutation(mutation);
        return result;
    };

    getRazorPay() {
        const { Razorpay } = window;
        return Razorpay;
    }

    onRzpPaymentSuccess = async (resolve, response) => {
        await this.saveRzpResponse(response);
        resolve();
    };

    onDismissRzp = () => {
        const {
            showNotification

        } = this.props;

        showNotification('error', __('Payment Cancelled'));
    };

    getRzpOptions = ({
        rzpOrderId, totalAmount, customer, onRzpPaymentSuccess, onDismiss, logoSrc, currency,
        razorpayKey, razorpayMerchantName
    }) => ({
        key: razorpayKey,
        amount: totalAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency,
        name: razorpayMerchantName,
        description: '',
        image: media(logoSrc, LOGO_MEDIA),
        order_id: rzpOrderId,
        handler: onRzpPaymentSuccess,
        prefill: {
            name: `${customer.firstname} ${customer.lastname}`,
            email: customer.email,
            contact: customer.mobileNumber
        },
        theme: {
            color: getComputedStyle(document.body).getPropertyValue('--primary-base-color')
        },
        modal: {
            escape: false,
            confirm_close: true,
            ondismiss: onDismiss
        }
    });

    openRzp = ({
        rzpOrderId, totalAmount, customer, logoSrc, currency, razorpayKey, razorpayMerchantName
    }) => new Promise((resolve, reject) => {
        const rzpOptions = this.getRzpOptions({
            rzpOrderId,
            totalAmount,
            customer,
            logoSrc,
            currency,
            razorpayKey,
            razorpayMerchantName,
            onRzpPaymentSuccess: (response) => this.onRzpPaymentSuccess(resolve, response),
            onDismiss: () => this.onDismissRzp()
        });
        const Razorpay = this.getRazorPay();
        const rzp = new Razorpay(rzpOptions);

        rzp.open();
    });

    renderLoader() {
        const { isLoading } = this.state;
        return (<Loader isLoading={ isLoading } />);
    }

    async rzpInit() {
        const {
            showNotification,
            totals: { grand_total: grandTotal }, customer, logoSrc, currency, razorpayKey, razorpayMerchantName
        } = this.props;

        const totalAmount = grandTotal * 100;
        // const { cart: { selected_payment_method: { code } } } = await this.setPaymentMethodOnCart();
        this.setState({ isLoading: true });
        const { placeRazorpayOrder: { rzp_order_id, success, message } } = await this.getRzpOrderId();
        if (success) {
            this.setState({ isLoading: false });
            await this.openRzp({
                rzpOrderId: rzp_order_id, totalAmount, customer, logoSrc, currency, razorpayKey, razorpayMerchantName
            });
        } else {
            this.setState({ isLoading: false });
            showNotification('error', __('Something went wrong,please refresh page and try again'));
        }
    }

    handleScriptCreate() {
    }

    handleScriptError() {
    }

    handleScriptLoad() {
        const { setRazorpayRef } = this.props;
        setRazorpayRef(this);
    }

    render() {
        return (
            <>
                { this.renderLoader() }
                <Script
                  url={ RAZORPAY_CHECKOUT_JS_URL }
                  onError={ this.handleScriptError }
                  onLoad={ this.handleScriptLoad }
                  onCreate={ this.handleScriptCreate }
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RazorpayContainer);
