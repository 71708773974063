/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';

import CloseIcon from '../../CloseIcon';

import './OfferDetailBottomSheet.style';

/** @namespace LifelyScandi/Component/Custom/OfferDetailBottomSheet/Component/OfferDetailBottomSheetComponent */
export class OfferDetailBottomSheetComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderCloseIcon() {
        const {
            handleClickOutside,
            BottomSheetIsVisible
        } = this.props;

        if (!BottomSheetIsVisible) {
            return null;
        }

        return (
            <button
              block="OfferDetailBottomSheet"
              elem="CloseIconActionWrapper"
              onClick={ handleClickOutside }
              aria-label="Close Slider"
            >
                <div
                  block="OfferDetailBottomSheet"
                  elem="CloseIconAction"
                >
                    <CloseIcon width="30" height="30" fill="#ffffff" />
                </div>
            </button>
        );
    }

    renderAllOffersContent() {
        const {
            handleClickOutside, showAllOffersDetail, tapToCopyCouponCode, applyCouponCode
        } = this.props;
        const couponElement = document.getElementById('couponCode');
        return (
            <ClickOutside onClick={ handleClickOutside }>
                <div block="OfferDetailBottomSheet" elem="AllOffersMainContainer">
                    { this.renderCloseIcon() }
                    <div block="OfferDetailBottomSheet" elem="AllOffersContent">
                        <h3 block="OfferDetailBottomSheet" elem="Header">
                            { `Lifely Delicious Offers (${ showAllOffersDetail.length })` }
                        </h3>
                        <div
                          block="OfferDetailBottomSheet"
                          elem="AllOffersOuterContainer"
                          mods={ {
                              emptyData: !(showAllOffersDetail
                                    && showAllOffersDetail.length > 0)
                          } }
                        >
                            { showAllOffersDetail
                                && showAllOffersDetail.length > 0
                                && showAllOffersDetail.map((offer) => (
                                    <ExpandableContent
                                      mix={ { block: 'OfferDetailBottomSheet', elem: 'AllOffersInnerContainer' } }
                                      isContentExpanded
                                      heading={ offer.title }
                                      subHeading={ offer.subTitle }
                                      key={ offer }
                                      isArrow
                                    >
                                        { (offer.coupon || offer.description) && (
                                            <div block="OfferDetailBottomSheet" elem="OfferDetailsContainer">
                                                { offer.coupon && (
                                                    <>
                                                        { !!couponElement === false && (
                                                            <button
                                                              block="OfferDetailBottomSheet"
                                                              elem="OfferCouponCodeContainer"
                                                              className="ripple"
                                                              onClick={ () => tapToCopyCouponCode(offer.coupon) }
                                                            >
                                                                <div
                                                                  block="OfferDetailBottomSheet"
                                                                  elem="OfferCouponCode"
                                                                >
                                                                    <span>
                                                                        { offer.coupon }
                                                                    </span>
                                                                </div>
                                                                <div block="OfferDetailBottomSheet" elem="TapToCopy">
                                                                    Tap To Copy
                                                                </div>
                                                            </button>
                                                        ) }
                                                        { !!couponElement === true && (
                                                            <button
                                                              block="OfferDetailBottomSheet"
                                                              elem="OfferCouponCodeContainer"
                                                              className="ripple"
                                                              onClick={ () => applyCouponCode(offer.coupon) }
                                                            >
                                                                <div
                                                                  block="OfferDetailBottomSheet"
                                                                  elem="OfferCouponCode"
                                                                >
                                                                    <span>
                                                                        { offer.coupon }
                                                                    </span>
                                                                </div>
                                                                <div block="OfferDetailBottomSheet" elem="TapToCopy">
                                                                    Apply
                                                                </div>
                                                            </button>
                                                        ) }
                                                    </>
                                                ) }
                                                <div block="OfferDetailBottomSheet" elem="OfferDescriptionContainer">
                                                    { offer.description
                                                        && offer.description.length > 0
                                                        && offer.description.map((term) => (
                                                            <div block="OfferDetailBottomSheet" elem="OfferDescription">
                                                                { term }
                                                            </div>
                                                        )) }
                                                </div>
                                                { offer.applicable
                                                    && offer.applicable.length > 0
                                                    && (
                                                        <div
                                                          block="OfferDetailBottomSheet"
                                                          elem="OfferApplicableContainer"
                                                        >
                                                            <h4>
                                                                Applicable Only for:
                                                            </h4>
                                                            <div
                                                              block="OfferDetailBottomSheet"
                                                              elem="OfferApplicableCategoryContainer"
                                                            >
                                                                { offer.applicable.map((category) => (
                                                                    <div
                                                                      block="OfferDetailBottomSheet"
                                                                      elem="OfferApplicable"
                                                                    >
                                                                        { category }
                                                                    </div>
                                                                )) }

                                                            </div>
                                                        </div>
                                                    ) }
                                            </div>
                                        ) }
                                    </ExpandableContent>
                                )) }

                            { showAllOffersDetail
                                && showAllOffersDetail.length === 0
                                && this.renderNoOfferData() }
                        </div>
                    </div>
                </div>
            </ClickOutside>

        );
    }

    renderSpecificOfferContent() {
        const { handleClickOutside, showSpecificOfferDetail, tapToCopyCouponCode } = this.props;
        return (
            <ClickOutside onClick={ handleClickOutside }>
                <div block="OfferDetailBottomSheet" elem="SpecificOfferMainContainer">
                    { this.renderCloseIcon() }
                    <div block="OfferDetailBottomSheet" elem="SpecificOfferContent">
                        <h3 block="OfferDetailBottomSheet" elem="Header">
                            Offer Details
                        </h3>
                        <div block="OfferDetailBottomSheet" elem="SpecificOfferOuterContainer">
                            <div block="OfferDetailBottomSheet" elem="SpecificOfferInnerContainer">
                                <h2 block="OfferDetailBottomSheet" elem="SpecificOfferTitle">
                                    { showSpecificOfferDetail.title }
                                </h2>
                                <h4 block="OfferDetailBottomSheet" elem="SpecificOfferSubTitle">
                                    { showSpecificOfferDetail.subTitle }
                                </h4>
                                <div block="OfferDetailBottomSheet" elem="OfferDetailsContainer">
                                    { showSpecificOfferDetail.coupon && (
                                        <button
                                          block="OfferDetailBottomSheet"
                                          elem="OfferCouponCodeContainer"
                                          className="ripple"
                                          onClick={ () => tapToCopyCouponCode(showSpecificOfferDetail.coupon) }
                                        >
                                            <div block="OfferDetailBottomSheet" elem="OfferCouponCode">
                                                <span>
                                                    { showSpecificOfferDetail.coupon }
                                                </span>
                                            </div>
                                            <div block="OfferDetailBottomSheet" elem="TapToCopy">
                                                Tap To Copy
                                            </div>
                                        </button>
                                    ) }
                                    <div block="OfferDetailBottomSheet" elem="OfferDescriptionContainer">
                                        { showSpecificOfferDetail.description
                                            && showSpecificOfferDetail.description.length > 0
                                            && showSpecificOfferDetail.description.map((term) => (
                                                <div block="OfferDetailBottomSheet" elem="OfferDescription">
                                                    { term }
                                                </div>
                                            )) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClickOutside>

        );
    }

    renderNoOfferData() {
        return (
            <div block="OfferDetailBottomSheet" elem="NoOfferDataAvailable">
                Currently, No Offer Found!
            </div>
        );
    }

    render() {
        const {
            BottomSheetIsVisible, showSpecificOfferDetail, isLoading
        } = this.props;

        return createPortal(
            <div
              block="OfferDetailBottomSheet"
              mods={ { isVisible: BottomSheetIsVisible } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderAllOffersContent() }
                { showSpecificOfferDetail
                    && Object.keys(showSpecificOfferDetail).length > 0
                    && this.renderSpecificOfferContent() }
            </div>,
            document.body
        );
    }
}

export default OfferDetailBottomSheetComponent;
