/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/require-default-props */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-lines */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, { PureComponent, Suspense } from 'react';

import Loader from 'Component/Loader';
import { DeviceType } from 'Type/Device';
import media from 'Util/Media';

import './LifelyStoryMobile.style';

export const SlidingPane = React.lazy(() => import('../SlidingPane'));
export const SugarVariants = React.lazy(() => import('../SugarVariants'));
export const SugarImpacts = React.lazy(() => import('../SugarImpacts'));
/** @namespace LifelyScandi/Component/Custom/LifelyStoryMobile/Component/LifelyStoryMobileComponent */
export class LifelyStoryMobileComponent extends PureComponent {
    static propTypes = {
        animationNoRepeat: PropTypes.bool,
        redirectToShopMenu: PropTypes.func,
        differentName: PropTypes.func.isRequired,
        dangerousImpact: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        animationNoRepeat: true
    };

    componentDidMount() {
        gsap.registerPlugin(ScrollTrigger);
        const { device } = this.props;
        // Start: Progress bar
        this.masterTl = gsap.timeline();

        // section 1
        // this.s1b1();
        // this.s1b2();
        // this.s1b3();
        // this.s1b4();

        // this.s1b5IconPart();

        // this.s1b6IconPart();

        // // section 2

        // this.s2b1ContentRound();

        // this.s2b2Contenttext();

        // this.s2b3Contenttext();
        // // section 3
        // this.s3b1Contenttext();

        // this.s3b2Contenttext();

        // this.s4b1Contenttext();

        gsap.registerPlugin(ScrollTrigger);
        this.masterTl = gsap.timeline();
        this.section1 = document.getElementById('section1');
        this.section2 = document.getElementById('section2');
        this.section3 = document.getElementById('section3');
        this.section4 = document.getElementById('section4');
        this.progressLine = document.getElementsByClassName('progressLine');

        this.progressSteviaMob = document.getElementById('progressSteviaMob');
        this.progressFirstMob = document.getElementById('progressFirstMob');
        this.progressHourglassMob = document.getElementById('progressHourglassMob');
        this.progressLogoMob = document.getElementById('progressLogoMob');
        this.firstRect = document.getElementById('firstRect');
        this.secondRect = document.getElementById('secondRect');
        this.thirthRect = document.getElementById('thirthRect');
        this.fourthRect = document.getElementById('fourthRect');

        if (!device.safari) {
            window.scrollTo(0, 0);
            this.scrollTimelineTrigger();
        }
    }

    componentWillUnmount() {
        // gsap.killTweensOf('#section1');
        // gsap.killTweensOf('#section2');
        // gsap.killTweensOf('#section3');
    }

    scrollTimelineTrigger() {
        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: this.section1,
                anticipatePin: 1,
                scrub: 0.2,
                // markers: 'section1',
                start: 'top center',
                end: '90% center'
            }
        })
            .to(this.progressFirstMob, { opacity: 1, duration: 0.002 })
            .from(this.firstRect, { scaleX: 0, duration: 1 })
            .to(this.progressFirstMob, { opacity: 0, duration: 0.002 });

        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: this.section2, anticipatePin: 1, scrub: 0.2, start: 'top center', end: '95% center'
            }
        })
            .to(this.progressSteviaMob, { opacity: 1, duration: 0.002 })
            .from(this.secondRect, { scaleX: 0, duration: 1 })
            .to(this.progressSteviaMob, { opacity: 0, duration: 0.002 });

        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: this.section3, anticipatePin: 1, scrub: 0.2, start: 'top center', end: '95% center'
            }
        })
            .to(this.progressHourglassMob, { opacity: 1, duration: 0.002 })
            .from(this.thirthRect, { scaleX: 0, duration: 1 })
            .to(this.progressHourglassMob, { opacity: 0, duration: 0.002 });

        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: this.section4, anticipatePin: 1, scrub: 0.2, start: 'top center', end: '70% center'
            }
        })
            .to(this.progressLogoMob, { opacity: 1, duration: 0.002 })
            .from(this.ourthRect, { scaleX: 0, duration: 1 });

        const tl5 = gsap.timeline({
            scrollTrigger: {
                trigger: this.section1,
                scrub: 1,
                endTrigger: '#section4',
                anticipatePin: 1,
                // scrub: 0.2,
                // markers: 'section1',
                start: 'top top',
                end: '90% center'
            }
        }).to('.progressLineMob', { strokeDashoffset: 0 });

        const tl6 = gsap.timeline({
            scrollTrigger: {
                trigger: this.section1,
                start: 'top center',
                toggleActions: 'play none none reverse'
            }
        }).to('#MobileProgressbarWrapper', { opacity: 1 });
    }

    __construct(props) {
        super.__construct(props);
    }

    s1b1() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b1',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.storySectionTitleMobile', { scale: 0 }, {
            duration: 1, scale: 1.5
        }).fromTo('.storySectionSubTitleMobile', { opacity: 0 }, {
            duration: 1, opacity: 1
        })
            .fromTo('.s1b1spantag', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b1h2tag', { x: -190, opacity: 0, fontWeight: 100 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.worldImg', { opacity: 0 }, {
                duration: 1, opacity: 1
            })
            .to('.s1b1h2tag', {
                duration: 1, fontWeight: 800, scale: 1.1, marginLeft: '5%'
            })
            .fromTo('.sugarImg', { x: -100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b1ptag', { y: 190, opacity: 0 }, {
                duration: 0.8, y: 0, opacity: 1
            });
    }

    s1b2() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b2',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s1b2spantag', { opacity: 0 }, {
            duration: 1, opacity: 1
        }).fromTo('.s1b2h2tag', { opacity: 0, x: -50 }, {
            duration: 1, opacity: 1, x: 0
        }).fromTo('#s1b2ImageContent', { scale: 0.8, opacity: 0, rotateY: 180 }, {
            duration: 1, scale: 1, opacity: 1, rotateY: 0
        })
            .fromTo('.s1b2ptag', { y: 190, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            });
    }

    s1b3() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b3',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s1b3spantag', { opacity: 0 }, {
            duration: 1, opacity: 1
        }).fromTo('.s1b3h2tag', { x: 50, opacity: 0 }, {
            duration: 1, x: 0, opacity: 1
        }).fromTo('#s1b3ImageContent', { x: -100, opacity: 0 }, {
            duration: 1, x: 0, opacity: 1
        })
            .fromTo('.s1b3ptag', {
                x: 60, opacity: 0, scale: 0
            }, {
                duration: 3, x: 0, opacity: 1, scale: 1
            })
            .fromTo('.VillainsImage', {
                x: 100, opacity: 0
            }, {
                duration: 3, x: -15, opacity: 1
            })
            .fromTo('.s1b3btntag', { y: 0, opacity: 0 }, {
                duration: 1, y: -20, opacity: 1
            });
    }

    s1b4() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b4',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s1b4spantag', { x: -190, opacity: 0 }, {
            duration: 1, x: 0, opacity: 1
        }).fromTo('.s1b4h2tag', { opacity: 0 }, {
            duration: 1, opacity: 1
        })
            .fromTo('#s1b4ImageContent', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b4ptag', { y: 50 }, {
                duration: 1, y: 0
            })
            .fromTo('.s1b4h2tag', {
                y: 0
            }, {
                duration: 1, y: 20, scale: 1.1
            });
    }

    s1b5IconPart() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b5',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s1b5spantag', { opacity: 0 }, {
            duration: 1, opacity: 1
        })
            .fromTo('.s1b5h2tag', { opacity: 0, scale: 0 }, {
                duration: 1, color: '#923e16', opacity: 1, scale: 1, marginLeft: '0%'
            })
            .fromTo('.s1b5Icon1', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b5Icon2', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b5Icon3', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b5Icon4', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b5Icon5', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b5Icon6', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b5Icon1', { y: 30, scale: 1 }, {
                duration: 1, y: 0, scale: 0.98
            })
            .to('.s1b5Icon1h3tag', { color: '#923e16' })
            .fromTo('.s1b5Icon2', { y: 30, scale: 1 }, {
                duration: 1, y: 0, scale: 0.98
            })
            .to('.s1b5Icon2h3tag', { color: '#923e16' })
            .fromTo('.s1b5Icon3', { y: 30, scale: 1 }, {
                duration: 1, y: 0, scale: 0.98
            })
            .to('.s1b5Icon3h3tag', { color: '#923e16' })
            .fromTo('.s1b5Icon4', { y: 30, scale: 1 }, {
                duration: 1, y: 0, scale: 0.98
            })
            .to('.s1b5Icon4h3tag', { color: '#923e16' })
            .fromTo('.s1b5Icon5', { y: 30, scale: 1 }, {
                duration: 1, y: 0, scale: 0.98
            })
            .to('.s1b5Icon5h3tag', { color: '#923e16' })
            .fromTo('.s1b5Icon6', { y: 30, scale: 1 }, {
                duration: 1, y: 0, scale: 0.98
            })
            .to('.s1b5Icon6h3tag', { color: '#923e16' })
            .fromTo('.s1b5btntag', { y: 10, opacity: 1 }, {
                duration: 1, y: -10, opacity: 1
            });
    }

    s1b6IconPart() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s1b6',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s1b6h2tag', { x: -190, opacity: 0 }, {
            duration: 1, x: 0, opacity: 1
        }).fromTo('.s1b6spantag', { x: -190, opacity: 0 }, {
            duration: 1, x: 0, opacity: 1
        })
            .fromTo('.s1b6Icon1', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b6Icon2', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b6Icon3', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b6Icon4', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b6Icon5', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s1b6Icon1', { y: -20, scale: 0.98 }, {
                duration: 1, y: 0, scale: 1
            })
            .to('.s1b6Icon1h3tag', { color: '#923e16' })
            .fromTo('.s1b6Icon2', { y: -20, scale: 0.98 }, {
                duration: 1, y: 0, scale: 1
            })
            .to('.s1b6Icon2h3tag', { color: '#923e16' })
            .fromTo('.s1b6Icon3', { y: -20, scale: 0.98 }, {
                duration: 1, y: 0, scale: 1
            })
            .to('.s1b6Icon3h3tag', { color: '#923e16' })
            .fromTo('.s1b6Icon4', { y: -20, scale: 0.98 }, {
                duration: 1, y: 0, scale: 1
            })
            .to('.s1b6Icon4h3tag', { color: '#923e16' })
            .fromTo('.s1b6Icon5', { y: -20, scale: 0.98 }, {
                duration: 1, y: 0, scale: 1
            })
            .to('.s1b6Icon5h3tag', { color: '#923e16' })
            .fromTo('.s1b6pFootertag', {
                y: 20, opacity: 0, fontWeight: 400
            }, {
                duration: 1, y: -15, opacity: 1, color: '#923e16', fontWeight: 700
            });
    }

    s2b1ContentRound() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b1',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s2b1h2tag', { scale: 0, opacity: 0 }, {
            color: '#2c4c32', duration: 1, fontWeight: 800, scale: 1.2, opacity: 1, marginLeft: '9%'
        }).fromTo('#s2b1ImageContent', { x: -190, opacity: 0 }, {
            duration: 1, x: 0, opacity: 1
        }).fromTo('.s2b1h2tag', { y: 0 }, {
            duration: 1, y: 10
        })
            .fromTo('.s2b1ptag', { y: 50, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('#s2b1TextContent', {
                x: -190, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s2b1Round1', { y: -190, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s2b1Round2', { y: -190, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s2b1Round3', { y: -190, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s2b1Round4', { y: -190, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s2b1Round5', { y: -190, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            });
    }

    s2b2Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b2',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.s2b2spantag', { y: -40, opacity: 0 }, {
            duration: 1, y: 0, opacity: 1
        }).fromTo('.s2b2h2tag', {
            y: 200, opacity: 0, scale: 0
        }, {
            duration: 1, y: 0, opacity: 1, scale: 1.2, color: '#2c4c32'
        })
            .fromTo('#s2b2ImageContent', { x: 0, scale: 0 }, {
                duration: 1, x: 0, scale: 1
            })
            .fromTo('.s2b2ptag', {
                y: 190, opacity: 0, scale: 0
            }, {
                duration: 1, y: 0, opacity: 1, scale: 1
            })
            .fromTo('#s2b2TextContent', {
                x: 190, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            });
    }

    s2b3Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s2b3',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo('.s2b3h2tag', {
                y: 190, opacity: 0, scale: 0
            }, {
                duration: 1, y: 0, opacity: 1, scale: 1
            }).fromTo('#s2b3ImageContent', { x: 0, scale: 0 }, {
                duration: 1, x: 0, scale: 1
            })
            .fromTo('.s2b3h2tag', {
                scale: 1
            }, {
                duration: 1, scale: 1.2, color: '#2c4c32'
            })
            .fromTo('.s2b3ptag', {
                y: 190, opacity: 0
            }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('#s2b3TextContent', {
                x: -190, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            });
    }

    s3b1Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s3b1h2tag',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo('.s3b1h2tag', {
                y: -50, opacity: 0
            }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s3b1spantag', { y: -60, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s3b1h2tag', {
                scale: 1
            }, {
                duration: 1, scale: 1.2, color: '#2c4c32'
            })
            .fromTo('#s3b1ImageContent1', { x: 0, scale: 0 }, {
                duration: 1, x: 0, scale: 1
            })
            .fromTo('.s3b1ptag', {
                opacity: 0
            }, {
                duration: 1, opacity: 1
            })
            .fromTo('.s3b1h2Footertag', { y: -50, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            });
    }

    s3b2Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            onComplete: () => {
                gsap.timeline({ repeat: -1, yoyo: true }).fromTo('.imgthumbsUp', { y: 5 }, { ease: 'power0.easeNone', duration: 1, y: -5 });
                gsap.timeline({ repeat: -1, yoyo: true }).fromTo('.imgthumbsDown', { y: -5 }, { ease: 'power0.easeNone', duration: 1, y: 5 });
            },
            scrollTrigger: {
                trigger: '.s3b2',
                scrub: 3,
                start: 'top 70%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        }).fromTo('.IngredientHeading', {
            y: -70, opacity: 0
        }, {
            duration: 1, y: 0, opacity: 1
        })
            .fromTo('.s3b2ptag', {
                y: 50, opacity: 0
            }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.s3b2Round1', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s3b2Round2', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s3b2Round3', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s3b2Round4', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s3b2Round5', { x: -190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.s3b2Round6', { x: 190, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.imgbgImprove', { y: -100, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.imgthumbsUp', { x: -100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.Improvesh2tag', { x: 100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.ImprovesHeadingHorizontalLine', { width: 0 }, {
                duration: 1, width: '30%'
            })
            .fromTo('.ImprovesList', { x: 100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.imgbgControl', { y: 100, opacity: 0 }, {
                duration: 1, y: 0, opacity: 1
            })
            .fromTo('.imgthumbsDown', { x: -100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.Controlsh2tag', { x: 100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.ControlsHeadingHorizontalLine', { width: 0 }, {
                duration: 1, width: '30%'
            })
            .fromTo('.ControlsList', { x: 100, opacity: 0 }, {
                duration: 1, x: 0, opacity: 1
            });
    }

    s4b1Contenttext() {
        const { animationNoRepeat } = this.props;
        gsap.timeline({
            scrollTrigger: {
                trigger: '.s4b1',
                scrub: 3,
                start: 'top 80%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo('.s4b1spantag', {
                opacity: 0
            }, {
                duration: 1, opacity: 1
            })
            .fromTo('#s4b1hrLine', {
                width: 0
            }, {
                duration: 1, width: '30%'
            })
            .fromTo('.s4b1h2tag', {
                scale: 0
            }, {
                duration: 1, scale: 1.2
            });

        gsap.timeline({
            scrollTrigger: {
                trigger: '.s4b1SuperHero',
                scrub: 3,
                start: 'top 80%',
                end: 'center 75%'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo('.HeroRibbon', {
                x: -200, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.LifelyHero', {
                x: -100, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            });

        gsap.timeline({
            scrollTrigger: {
                trigger: '.s4b1SuperHero',
                scrub: 3,
                start: 'top 80%',
                end: 'center center'
            },
            autoRemoveChildren: animationNoRepeat
        })
            .fromTo('.HeroineRibbon', {
                x: 200, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.LifelyHeroine', {
                x: 100, opacity: 0
            }, {
                duration: 1, x: 0, opacity: 1
            })
            .fromTo('.ShopNowContent', {
                y: 150, opacity: 0
            }, {
                duration: 1, y: 0, opacity: 1
            });
    }

    renderStoryHeading() {
        return (

            <div className="Heading">
                <h2 className="storySectionTitleMobile">{ __('The Real Story') }</h2>
                <span className="storySectionSubTitleMobile">{ __('We want to convey openly') }</span>
            </div>

        );
    }

    renderStorySection1() {
        const {
            differentName,
            dangerousImpact,
            isPaneOpen,
            slidePaneName,
            closeSlidePane
        } = this.props;

        return (
            <div className="part" id="section1">

                { /* s1b1 */ }

                <div className="s1b1">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <span className="s1b1spantag">{ __('We are living in') }</span>
                        <h2 className="s1b1h2tag">{ __('The Sugar Age World') }</h2>
                        <div className="imgWorld ImageContent">
                            <img src={ media('/wysiwyg/lifely_story/world.webp') } alt="" loading="lazy" className="worldImg" />
                            <div className="imgSugerCubes">
                                <img src={ media('wysiwyg/lifely_story/sugar-cubes.webp') } alt="" loading="lazy" className="sugarImg" />
                            </div>
                        </div>
                        <p className="s1b1ptag">
                            { __('Bitter truth is most of people are not even aware of it and it\'s serious effect on their health and life ') }
                        </p>
                    </div>
                </div>

                { /* s1b2 */ }

                <div className="s1b2">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <span className="s1b2spantag">{ __('It\'s disturbing, it\'s') }</span>
                        <h2 className="s1b2h2tag">{ __('Everywhere') }</h2>
                        <div className="ImageContent" id="s1b2ImageContent">
                            <img src={ media('wysiwyg/lifely_story/its-everywhere.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s1b2ptag">{ __('in this form of ADDED sugar Not even your sugar free sweetners asre really free of sugar') }</p>
                    </div>
                </div>

                { /* s1b3 */ }

                <div className="s1b3">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <span className="s1b3spantag">{ __('Labeled with,') }</span>
                        <h2 className="s1b3h2tag">{ __('Different Names') }</h2>
                        <div className="ImageContent" id="s1b3ImageContent">
                            <img src={ media('wysiwyg/lifely_story/differant-labels.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s1b3ptag">{ __('like Sugar, Sugar Alchohol, Artificial Sweetners makes even harder to recognize it.A normal consumer may not even know what this strange ingredients are related to') }</p>
                        <img src={ media('wysiwyg/lifely_story/health-villains.webp') } loading="lazy" className="VillainsImage" alt="" />
                        <button className="Button listBtn s1b3btntag" onClick={ differentName }>More About Sugar</button>
                    </div>
                </div>

                { /* s1b4 */ }

                <div className="s1b4">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <span className="s1b4spantag">{ __('Your everyday food choice  is creating') }</span>
                        <h2 className="s1b4h2tag">{ __('Flood Of Sugar') }</h2>
                        <div className="ImageContent" id="s1b4ImageContent">
                            <img src={ media('wysiwyg/lifely_story/sugar-flood.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s1b4ptag">{ __('In your body. And you earn yourself real dangers of sugar impact upon your body') }</p>
                    </div>
                </div>

                { /* s1b5 */ }

                <div className="s1b5">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <span className="s1b5spantag">{ __('Sugar\'s') }</span>
                        <h2 className="s1b5h2tag">{ __('Dangerous Impact') }</h2>
                        <div className="IconContainer">
                            <div className="s1b5Icon1">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/addiction.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon1h3tag">Addiction</h3>
                                    <p className="s1b5Icon1ptag">Sugar is additive,more you eat more you get addicted</p>
                                </div>
                            </div>
                            <div className="s1b5Icon2">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/increased-hunger.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon2h3tag">Increased Hunger</h3>
                                    <p className="s1b5Icon2ptag">It does not make you full , instead creates more hunger</p>
                                </div>
                            </div>
                            <div className="s1b5Icon3">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/weight-gain.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon3h3tag">Weight Gain</h3>
                                    <p className="s1b5Icon3ptag">Sugar Causes weight gain and obesity</p>
                                </div>
                            </div>
                            <div className="s1b5Icon4">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/heart-disease.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon4h3tag">Heart Disease</h3>
                                    <p className="s1b5Icon4ptag">Sugar causes hardening of artaries</p>
                                </div>
                            </div>
                            <div className="s1b5Icon5">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/diabetes.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon5h3tag">Diabetes</h3>
                                    <p className="s1b5Icon5ptag">Sugar is leading cause for Diabetes</p>
                                </div>
                            </div>
                            <div className="s1b5Icon6">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/metabolic-disorder.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b5Icon6h3tag">Metabolic disorders</h3>
                                    <p className="s1b5Icon6ptag">Sugar Causes weight gain and obesity</p>
                                </div>
                            </div>
                        </div>
                        <div className="listbtnWrapper">
                            <button className="Button listBtn s1b5btntag" onClick={ dangerousImpact }>Sugar's Impact</button>
                        </div>
                    </div>
                </div>

                { /* s1b6 */ }

                <div className="s1b6">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <h2 className="s1b6h2tag">{ __('The Real Reason') }</h2>
                        <span className="s1b6spantag">{ __('Why most of the brand products are loaded with additional sugar of many forms') }</span>
                        <div className="IconContainer">
                            <div className="s1b6Icon1">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/addiction.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon1h3tag">Addiction</h3>
                                    <p className="s1b6Icon1ptag">Sugar is additive,more you eat more you get addicted</p>
                                </div>
                            </div>
                            <div className="s1b6Icon2">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/increased-hunger.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon2h3tag">Increased Hunger</h3>
                                    <p className="s1b6Icon2ptag">It does not make you full,instead creates more hunger</p>
                                </div>
                            </div>
                            <div className="s1b6Icon3">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/weight-gain.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon3h3tag">Craving</h3>
                                    <p className="s1b6Icon3ptag">Sugar Causes Resistance to a Hormone called Leptin,which is the Fullness Signal</p>
                                </div>
                            </div>
                            <div className="s1b6Icon4">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/heart-disease.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon4h3tag">Cheap Bulking Agent</h3>
                                    <p className="s1b6Icon4ptag">Sugar is the cheapest bulking agent,</p>
                                </div>
                            </div>
                            <div className="s1b6Icon5">
                                <div className="IconBox"><img src={ media('wysiwyg/lifely_story/diabetes.webp') } alt="" loading="lazy" /></div>
                                <div className="IconText">
                                    <h3 className="s1b6Icon5h3tag">Cheap Price and Discounts</h3>
                                    <p className="s1b6Icon5ptag">More Sugar means less price, less price means more business</p>
                                </div>
                            </div>
                        </div>
                        <div className="CmsFooter">
                            <p className="s1b6pFootertag">{ __('They don\'t care what impact sugar creates in consumer\'s body in long time') }</p>
                        </div>
                    </div>
                </div>
                { (slidePaneName === 'sugarVariants' || slidePaneName === 'sugarImpact') && (
                    <SlidingPane
                      key={ slidePaneName }
                      isOpen={ isPaneOpen }
                      title={ slidePaneName === 'sugarVariants' ? 'Types Of Sugar' : 'Sugar\'s Dangerous Impact' }
                      width="100%"
                      from="right"
                      footer="Close"
                      btnPrimaryBase
                      onRequestClose={ () => {
                          closeSlidePane();
                      } }
                      onButtonClick={ () => {
                          closeSlidePane();
                      } }
                    >
                        { slidePaneName === 'sugarVariants' && (<SugarVariants />) }
                        { slidePaneName === 'sugarImpact' && (<SugarImpacts />) }

                    </SlidingPane>
                ) }
            </div>
        );
    }

    renderStorySection2() {
        return (
            <div className="part" id="section2">

                { /* s2b1 */ }

                <div className="s2b1">
                    <div className="StorySectionContent">
                        <h2 className="s2b1h2tag">{ __('Stevia') }</h2>
                        <div className="ImageContent" id="s2b1ImageContent">
                            <img src={ media('wysiwyg/lifely_story/stevia.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s2b1ptag">{ __('A true blessing from mother nature to defeat sugar devil') }</p>
                        <div className="TextContent" id="s2b1TextContent">
                            <h2>{ __('100% Natural Sweetner') }</h2>
                            <p>{ __('which is,') }</p>
                            <ul className="ListContent">
                                <li>{ __('As pure as mother nature') }</li>
                                <li>{ __('Safe for all age ') }</li>
                                <li>{ __('Safe for Diebetic patients') }</li>
                                <li>{ __('Zero calories') }</li>
                                <li>{ __('Zero Glycemic index(GI)') }</li>
                            </ul>
                        </div>
                    </div>
                    <div className="CmsFooter">
                        <div className="RoundShape">
                            <div className="s2b1Round1">
                                <img src={ media('wysiwyg/lifely_story/natural.webp') } alt="" loading="lazy" />
                                <p>{ __('Natural') }</p>
                            </div>
                            <div className="s2b1Round2">
                                <img src={ media('wysiwyg/lifely_story/safe-for-kids.webp') } alt="" loading="lazy" />
                                <p>{ __('Safe For Children') }</p>
                            </div>
                            <div className="s2b1Round3">
                                <img src={ media('wysiwyg/lifely_story/safe-for-diabetic.webp') } alt="" loading="lazy" />
                                <p>{ __('Safe For Diabetic') }</p>
                            </div>
                            <div className="s2b1Round4">
                                <img src={ media('wysiwyg/lifely_story/safe-for-elders.webp') } alt="" loading="lazy" />
                                <p>{ __('Safe For Elders') }</p>
                            </div>
                            <div className="s2b1Round5">
                                <img src={ media('wysiwyg/lifely_story/safe-for-pregnant-women.webp') } alt="" loading="lazy" />
                                <p>{ __('Safe For Pregnant Women') }</p>
                            </div>
                        </div>
                    </div>
                </div>

                { /* s2b2 */ }

                <div className="s2b2">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <span className="s2b2spantag">{ __('Our Researched Stevia Variety') }</span>
                        <h2 className="s2b2h2tag">{ __('Clean Sweet') }</h2>
                        <div className="ImageContent" id="s2b2ImageContent">
                            <img src={ media('wysiwyg/lifely_story/cleansweet-research-variety.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s2b2ptag">{ __('Natural is our only inspiration for research') }</p>
                        <div className="TextContent" id="s2b2TextContent">
                            <ul className="ListContent">
                                <li>{ __('No Bitterness') }</li>
                                <li>{ __('No typical aftertaste of stevia') }</li>
                                <li>{ __('Non GMO Proprietary') }</li>
                            </ul>
                        </div>
                    </div>
                </div>

                { /* s2b3 */ }

                <div className="s2b3">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <h2 className="s2b3h2tag">{ __('100% Natural extraction process') }</h2>
                        <div className="ImageContent" id="s2b3ImageContent">
                            <img src={ media('wysiwyg/lifely_story/natural-extraction.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s2b3ptag">{ __('Stevia extraction by our world patented iNEx process, using only water as medium') }</p>
                        <div className="TextContent" id="s2b3TextContent">
                            <ul className="ListContent">
                                <li>{ __('No chemical') }</li>
                                <li>{ __('No solvent') }</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStorySection3() {
        return (
            <div className="part" id="section3">

                { /* s3b1 */ }
                <div className="s3b1">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <h2 className="s3b1h2tag">{ __('Prebiotic dietary fibres') }</h2>
                        <span className="s3b1spantag">{ __('extracted using our iNEx natural process ') }</span>
                        <div className="ImageContent" id="s3b1ImageContent1">
                            <img src={ media('wysiwyg/lifely_story/prebiotic-dietary-fibers.webp') } alt="" loading="lazy" />
                        </div>
                        <p className="s3b1ptag">{ __('Added from natural sources in all our products It\'s food for 100 trillion good Gut bacteria army') }</p>
                    </div>
                    <div className="CmsFooter">
                        <h2 className="s3b1h2Footertag">{ __('Healthy Gut flora, Healthy you') }</h2>
                    </div>
                </div>

                { /* s3b2 */ }

                <div className="s3b2">
                    <div className="HorizontalLine" />
                    <div className="StorySectionContent">
                        <div className="TitleContent">
                            <div className="TitlePart">
                                <div className="IngredientCount IngredientHeading">
                                    <h3 className="s3b2h3tag">{ __('6') }</h3>
                                </div>
                                <h2 className="s3b2h2tag IngredientHeading">{ __('Wellness Ingredients') }</h2>
                                <p className="s3b2ptag">{ __('extracted using our iNEx natural process ') }</p>
                            </div>
                            <div className="IngredientPart">
                                <div className="s3b2Round1">
                                    <div className="ImgWrapper">
                                        <img src={ media('wysiwyg/ingredients/used/fenugreek-extract.webp') } alt="" loading="lazy" />
                                    </div>
                                    <p>{ __('Fenugreek extract') }</p>
                                </div>
                                <div className="s3b2Round2">
                                    <div className="ImgWrapper">
                                        <img src={ media('wysiwyg/ingredients/used/ala-dha.webp') } alt="" />
                                    </div>
                                    <p>{ __('Omega 3 Ala & DHA') }</p>
                                </div>
                                <div className="s3b2Round3">
                                    <div className="ImgWrapper">
                                        <img src={ media('wysiwyg/ingredients/used/white-kidney-beans-extract.webp') } alt="" loading="lazy" />
                                    </div>
                                    <p>{ __('White kidney bean extract') }</p>
                                </div>
                                <div className="s3b2Round4">
                                    <div className="ImgWrapper">
                                        <img src={ media('wysiwyg/ingredients/used/green-coffee-beans-extract.webp') } alt="" loading="lazy" />
                                    </div>
                                    <p>{ __('Green coffeebean extract') }</p>
                                </div>
                                <div className="s3b2Round5">
                                    <div className="ImgWrapper">
                                        <img src={ media('wysiwyg/ingredients/used/mct-oil.webp') } alt="" loading="lazy" />
                                    </div>
                                    <p>{ __('MCT Oil') }</p>
                                </div>
                                <div className="s3b2Round6">
                                    <div className="ImgWrapper">
                                        <img src={ media('wysiwyg/ingredients/used/cla.webp') } alt="" loading="lazy" />
                                    </div>
                                    <p>{ __('Conjugated Linoleic acid') }</p>
                                </div>
                            </div>
                        </div>
                        <div className="TextContent">
                            <div className="ImprovesPart" id="s3b2ImprovesContent">
                                <div className="ImprovesThumbsUp">
                                    <div className="imgbgImprove">
                                        <img src={ media('wysiwyg/lifely_story/bgImprove.webp') } alt="" loading="lazy" />
                                        <div className="imgthumbsUp">
                                            <img src={ media('wysiwyg/lifely_story/thumbsUp.webp') } alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <h2 className="Improvesh2tag">Improves</h2>
                                <div className="ImprovesHeadingHorizontalLine" />
                                <div className="Improves">
                                    <div className="ImprovesList">
                                        <ul>
                                            <li>Blood glucose level regulation</li>
                                            <li>Immune system</li>
                                            <li>Digestive system</li>
                                            <li>Metabolism</li>
                                            <li>Heart Function</li>
                                            <li>Bone Health</li>
                                            <li>Liver and Kidney Function</li>
                                            <li>Eye Function</li>
                                            <li>Weight Loss</li>
                                            <li>High Ma,Ca and Vitamin B and E</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="ControlsPart" id="s3b2ControlsContent">
                                <div className="ControlsThumbsDown">
                                    <div className="imgbgControl">
                                        <img src={ media('wysiwyg/lifely_story/bgControl.webp') } alt="" loading="lazy" />
                                        <div className="imgthumbsDown">
                                            <img src={ media('wysiwyg/lifely_story/thumbsDown.webp') } alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <h2 className="Controlsh2tag">Controls</h2>
                                <div className="ControlsHeadingHorizontalLine" />
                                <div className="Controls">
                                    <div className="ControlsList">
                                        <ul>
                                            <li>Body Fat</li>
                                            <li>Mood Swing</li>
                                            <li>Depression</li>
                                            <li>Anxiety</li>
                                            <li>Helps To block Carbohydrate</li>
                                            <li>Fat in Liver</li>
                                            <li>Skin Pigmentation</li>
                                            <li>Cholesterole</li>
                                            <li>Blood Pressure</li>
                                            <li>Metalic Syndrome</li>
                                            <li>Dementia</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStorySection4() {
        const { redirectToShopMenu } = this.props;

        return (
            <div className="part" id="section4">
                <div className="s4b1">
                    <span className="s4b1spantag">Welcome To</span>
                    <div className="HorizontalLine" id="s4b1hrLine" />
                    <h2 className="s4b1h2tag">Lifely's Truly</h2>
                    <div className="s4b1SuperHero">
                        { /* <div className="LifelyHero">
                            <img src={ media('wysiwyg/lifely_story/lifely-hero.webp') } alt="" loading="lazy" />
                        </div> */ }
                        <div className="ShopNowContent">
                            <img
                              src={ media(
                                  'wysiwyg/lifely_story/lifely-store-mobile.webp'
                              ) }
                              loading="lazy"
                              className="lifelyStoreImg"
                              alt=""
                            />
                            <button className="Button superFoodBtn" onClick={ redirectToShopMenu }>shop now</button>
                        </div>
                        { /* <div className="LifelyHeroine">
                            <img src={ media('wysiwyg/lifely_story/lifely-heroine.webp') } alt="" loading="lazy" />
                        </div> */ }
                    </div>
                    <div className="s4b1SuperHeroin">
                        <div className="HeroRibbon">
                            <span className="ribbon-content">Super Tasty</span>
                        </div>
                        <div className="HeroineRibbon">
                            <span className="ribbon-content">Super Healthy</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStoryContent() {
        return (
            <>
                { /* <div className="sectionPlaceholder">
                    <h1>Content</h1>
                    <p>Lorem ipsum</p>
                </div> */ }
                { this.renderStoryHeading() }
                { this.renderStorySection1() }
                { this.renderStorySection2() }
                { this.renderStorySection3() }
                { this.renderStorySection4() }
            </>
        );
    }

    renderProgressbarWrapper() {
        return (
            <div className="progressbarContainer" id="MobileProgressbarWrapper">
                <div className="progressbarWrapper">
                    { this.renderProgressbar() }
                </div>
            </div>
        );
    }

    renderProgressbar() {
        return (
            <svg id="mobileProgressbarSvg" x="0px" y="0px" viewBox="0 0 400 100">
                <g id="progressLineMob">
                    <path
                      id="XMLID_243_"
                      fill="none"
                      stroke="#345F41"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      d="M38.5,35.1v9.6 M32.1,34
		v11.8 M41.7,36.1v7.5 M28.9,35.1v9.6 M25.7,36.1v7.5 M35.3,34v11.8 M22.5,37.2v5.3 M44.9,37.2v5.3 M104.8,35.1v9.6 M98.4,34v11.8
		 M108,36.1v7.5 M95.2,35.1v9.6 M92,36.1v7.5 M101.6,34v11.8 M88.8,37.2v5.3 M111.2,37.2v5.3 M62,35.1v9.6 M68.5,34v11.8 M58.8,36.1
		v7.5 M71.7,35.1v9.6 M74.9,36.1v7.5 M65.3,34v11.8 M78.1,37.2v5.3 M55.6,37.2v5.3 M137.9,35.1v9.6 M131.5,34v11.8 M141.1,36.1v7.5
		 M128.3,35.1v9.6 M125.1,36.1v7.5 M134.7,34v11.8 M121.9,37.2v5.3 M144.3,37.2v5.3 M171.1,35.1v9.6 M164.6,34v11.8 M174.3,36.1v7.5
		 M161.4,35.1v9.6 M158.2,36.1v7.5 M167.9,34v11.8 M155,37.2v5.3 M177.5,37.2v5.3 M204.2,35.1v9.6 M197.8,34v11.8 M207.4,36.1v7.5
		 M194.6,35.1v9.6 M191.4,36.1v7.5 M201,34v11.8 M188.2,37.2v5.3 M210.6,37.2v5.3 M270.5,35.1v9.6 M264,34v11.8 M273.7,36.1v7.5
		 M260.8,35.1v9.6 M257.6,36.1v7.5 M267.2,34v11.8 M254.4,37.2v5.3 M276.9,37.2v5.3 M227.7,35.1v9.6 M234.1,34v11.8 M224.5,36.1v7.5
		 M237.3,35.1v9.6 M240.5,36.1v7.5 M230.9,34v11.8 M243.7,37.2v5.3 M221.3,37.2v5.3 M303.6,35.1v9.6 M297.2,34v11.8 M306.8,36.1v7.5
		 M294,35.1v9.6 M290.8,36.1v7.5 M300.4,34v11.8 M287.6,37.2v5.3 M310,37.2v5.3 M336.7,35.1v9.6 M330.3,34v11.8 M339.9,36.1v7.5
		 M327.1,35.1v9.6 M323.9,36.1v7.5 M333.5,34v11.8 M320.7,37.2v5.3 M343.1,37.2v5.3 M370.9,35.1v9.6 M364.5,34v11.8 M374.1,36.1v7.5
		 M361.3,35.1v9.6 M358.1,36.1v7.5 M367.7,34v11.8 M354.9,37.2v5.3 M377.3,37.2v5.3 M394.4,35.1v9.6 M391.2,36.1v7.5 M397.6,34v11.8
		 M388,37.2v5.3 M6.5,35.1v9.6 M9.7,36.1v7.5 M3.3,34v11.8 M12.9,37.2v5.3"
                    />
                    <path
                      id="XMLID_32_"
                      fill="none"
                      stroke="white"
                      strokeOpacity="0.6"
                      strokeMiterlimit="10"
                      d="M398.8,32c0,0-12.1,0.1-16.5,8.4
		c-4.5,8.3-16.8,8.4-16.8,8.4l0,0c0,0-12.1-0.1-16.5-8.4c-4.5-8.3-16.8-8.3-16.8-8.3s-12,0-16.5,8.3c-4.5,8.3-16.8,8.4-16.8,8.4l0,0
		c0,0-12.1-0.2-16.5-8.5c-4.4-8.3-16.8-8.4-16.8-8.4v-0.1c0,0-12.1,0.1-16.5,8.5c-4.5,8.3-16.8,8.4-16.8,8.4l0,0
		c0,0-12.1-0.2-16.5-8.5s-16.8-8.4-16.8-8.4s-12,0.1-16.5,8.4c-4.5,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.2-16.5-8.5
		c-4.5-8.3-16.8-8.4-16.8-8.4v-0.1c0,0-12.1,0.2-16.5,8.5c-4.5,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.2-16.5-8.5s-16.8-8.4-16.8-8.4
		s-12,0.1-16.5,8.4c-4.5,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.3-16.5-8.7C12.2,31.8,0,31.7,0,31.7"
                    />
                    <path
                      id="XMLID_229_"
                      fill="none"
                      stroke="white"
                      strokeOpacity="0.6"
                      strokeMiterlimit="10"
                      d="M398.8,48.6c0,0-12.1-0.1-16.5-8.4
		c-4.5-8.3-16.8-8.4-16.8-8.4l0,0c0,0-12.1,0-16.5,8.3c-4.5,8.3-16.8,8.3-16.8,8.3s-12,0-16.5-8.3c-4.5-8.3-16.8-8.3-16.8-8.3l0,0
		c0,0-12.1,0.2-16.5,8.5c-4.4,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.2-16.5-8.5c-4.5-8.3-16.8-8.5-16.8-8.5l0,0c0,0-12.1,0-16.5,8.3
		s-16.8,8.3-16.8,8.3s-12,0.1-16.5-8.3c-4.5-8.3-16.8-8.3-16.8-8.3l0,0c0,0-12.1-0.1-16.5,8.2c-4.5,8.3-16.8,8.3-16.8,8.3
		s-12.1,0-16.5-8.3c-4.5-8.3-16.8-8.3-16.8-8.3l0,0c0,0-12.1-0.1-16.5,8.2c-4.4,8.3-16.8,8.2-16.8,8.2l0.1-0.1
		c0,0-12.1,0.1-16.5-8.2c-4.5-8.3-16.8-8.2-16.8-8.2l0,0c0,0-12.1-0.1-16.5,8.2C12.4,48.1,0.1,48,0.1,48"
                    />
                    <path
                      id="XMLID_32_"
                      className="progressLineMob"
                      fill="none"
                      stroke="white"
                      strokeMiterlimit="10"
                      d="M398.8,32c0,0-12.1,0.1-16.5,8.4
		c-4.5,8.3-16.8,8.4-16.8,8.4l0,0c0,0-12.1-0.1-16.5-8.4c-4.5-8.3-16.8-8.3-16.8-8.3s-12,0-16.5,8.3c-4.5,8.3-16.8,8.4-16.8,8.4l0,0
		c0,0-12.1-0.2-16.5-8.5c-4.4-8.3-16.8-8.4-16.8-8.4v-0.1c0,0-12.1,0.1-16.5,8.5c-4.5,8.3-16.8,8.4-16.8,8.4l0,0
		c0,0-12.1-0.2-16.5-8.5s-16.8-8.4-16.8-8.4s-12,0.1-16.5,8.4c-4.5,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.2-16.5-8.5
		c-4.5-8.3-16.8-8.4-16.8-8.4v-0.1c0,0-12.1,0.2-16.5,8.5c-4.5,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.2-16.5-8.5s-16.8-8.4-16.8-8.4
		s-12,0.1-16.5,8.4c-4.5,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.3-16.5-8.7C12.2,31.8,0,31.7,0,31.7"
                    />
                    <path
                      id="XMLID_229_"
                      className="progressLineMob"
                      fill="none"
                      stroke="white"
                      strokeMiterlimit="10"
                      d="M398.8,48.6c0,0-12.1-0.1-16.5-8.4
		c-4.5-8.3-16.8-8.4-16.8-8.4l0,0c0,0-12.1,0-16.5,8.3c-4.5,8.3-16.8,8.3-16.8,8.3s-12,0-16.5-8.3c-4.5-8.3-16.8-8.3-16.8-8.3l0,0
		c0,0-12.1,0.2-16.5,8.5c-4.4,8.3-16.8,8.5-16.8,8.5l0,0c0,0-12.1-0.2-16.5-8.5c-4.5-8.3-16.8-8.5-16.8-8.5l0,0c0,0-12.1,0-16.5,8.3
		s-16.8,8.3-16.8,8.3s-12,0.1-16.5-8.3c-4.5-8.3-16.8-8.3-16.8-8.3l0,0c0,0-12.1-0.1-16.5,8.2c-4.5,8.3-16.8,8.3-16.8,8.3
		s-12.1,0-16.5-8.3c-4.5-8.3-16.8-8.3-16.8-8.3l0,0c0,0-12.1-0.1-16.5,8.2c-4.4,8.3-16.8,8.2-16.8,8.2l0.1-0.1
		c0,0-12.1,0.1-16.5-8.2c-4.5-8.3-16.8-8.2-16.8-8.2l0,0c0,0-12.1-0.1-16.5,8.2C12.4,48.1,0.1,48,0.1,48"
                    />
                </g>
                <g id="progressLogoMob" className="progressMobInactive">
                    <path
                      id="XMLID_28_"
                      fill="#ba8416"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M199.5,72.7
		c15.6,0,18.2-16.1,18.2-16.1c0.4-3.7,0.2-20.4,0.2-25.1c0-2.4-0.3-4.8-1-7.1C214.5,17,207,12,199.3,11.7
		c-9.1-0.3-18.8,6.7-19.5,16.2c0,0.4,0,0.9-0.1,1.3c-0.4,7.3-0.2,20.5,0,27.8C179.8,57.1,181.7,72.7,199.5,72.7z"
                    />
                    <path
                      id="newInner"
                      fill="none"
                      stroke="white"
                      strokeMiterlimit="10"
                      d="M198.7,14.2c-4.4,0-8.6,1.7-11.8,4.9
		c-3.2,3.2-4.9,7.4-4.9,11.8v22.7c0,4.4,1.7,8.6,4.9,11.8c3.2,3.2,7.4,4.9,11.8,4.9s8.6-1.7,11.8-4.9c3.2-3.2,4.9-7.4,4.9-11.8V30.9
		c0-4.4-1.7-8.6-4.9-11.8C207.4,15.9,203.2,14.2,198.7,14.2z"
                    />
                    <line fill="none" stroke="white" strokeMiterlimit="10" x1="198.7" y1="47.8" x2="186.2" y2="47.8" />
                    <line fill="none" stroke="white" strokeMiterlimit="10" x1="198.7" y1="47.8" x2="211.2" y2="47.8" />
                    <g id="hourglass">
                        <path
                          id="newStroked"
                          fill="#ba8416"
                          stroke="white"
                          strokeMiterlimit="10"
                          d="M198.7,33.8c0.2-0.2,0.5-0.4,0.7-0.7
			c1.4-1.3,2.8-2.7,4.2-4.1c1.5-1.5,3.2-3.1,3.5-5.4c0.1-0.8,0-1.6-0.2-2.4c0-0.1-0.2-0.9-0.3-0.9h-16.2c-0.3,0.5-0.4,1.4-0.5,2.2
			c0,1.1,0.2,2.3,0.8,3.3c0.6,1,1.6,1.9,2.4,2.7c0.9,1,1.9,1.9,2.9,2.9c0.8,0.8,1.6,1.6,2.3,2.3l0,0c-0.8,0.8-1.6,1.6-2.4,2.4
			c-0.9,1-1.9,1.9-2.9,2.9c-0.8,0.8-1.8,1.7-2.4,2.8c-0.5,1-0.8,2.2-0.8,3.4c0,0.8,0.2,2.4,0.5,2.4h16.2c0.1,0,0.3-1,0.3-1.1
			c0.2-0.8,0.3-1.7,0.2-2.6c-0.3-2.2-2-3.8-3.5-5.4c-1.3-1.4-2.8-2.8-4.2-4.1C199.2,34.3,198.9,34,198.7,33.8"
                        />
                        <g id="leaf">

                            <line id="leafR1_1_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="46.6" x2="203.5" y2="43.5" />

                            <line id="leafL1_1_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="46.6" x2="193.9" y2="43.5" />

                            <line id="leafR2_1_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="43" x2="201.5" y2="41.1" />

                            <line id="leafL2_1_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="43" x2="195.8" y2="41.1" />

                            <line id="leafR3" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="40.5" x2="200.2" y2="39.5" />

                            <line id="leafL3_1_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="40.5" x2="197.5" y2="39.5" />

                            <line id="vertLineLeaf" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="198.7" y1="47.6" x2="198.7" y2="36.6" />
                            <path
                              id="leafStrokeLeft"
                              fill="none"
                              stroke="white"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              d="M192.1,47.6
				c0,0-1.5-3.1,1.2-6.1l5.6-5.6"
                            />
                            <path
                              id="leafStrokeRiht"
                              fill="none"
                              stroke="white"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              d="M204.9,47.7
				c0,0,1.6-3.5-0.7-6.3l-5.9-5.6"
                            />
                        </g>
                        <g id="topLines_1_">

                            <line id="XMLID_11_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="197.3" y1="30.6" x2="200.2" y2="30.6" />

                            <line id="XMLID_10_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="194.6" y1="28" x2="202.8" y2="28" />

                            <line id="XMLID_9_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="192.7" y1="25.4" x2="204.5" y2="25.4" />

                            <line id="XMLID_8_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="191.7" y1="22.6" x2="205.5" y2="22.6" />
                        </g>
                    </g>
                    <path
                      id="XMLID_27_"
                      fill="white"
                      stroke="none"
                      d="M186.7,51.5v9c0,0.6,0.4,1,1,1h2v0.9h-2
		c-0.5,0-0.9-0.2-1.3-0.5c0,0-0.1-0.1-0.2-0.2c-0.3-0.3-0.5-0.8-0.5-1.3v-9H186.7L186.7,51.5z M191.3,56.6h0.9v6h-0.9V56.6
		L191.3,56.6z M199.1,52.5v4.1h3v0.9h-3v2.9l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.5,0.5,0.9,1,0.9h1.9v0.9h-1.9
		c-0.5,0-0.9-0.1-1.3-0.5c-0.4-0.3-0.6-0.8-0.7-1.3v-9.2h3.9v0.9L199.1,52.5L199.1,52.5z M191.8,55.1c0.3,0,0.6,0.2,0.6,0.6
		s-0.2,0.6-0.6,0.6s-0.6-0.2-0.6-0.6C191.2,55.4,191.5,55.1,191.8,55.1L191.8,55.1z M210.2,60.4v2.2h-0.9v-2.2
		c-0.9-0.2-1.5-1-1.5-1.9v-6.9h0.9v6.8c0,0,0,0,0,0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.4,1-1v-6.9h0.9v6.9
		C211.7,59.4,211.1,60.2,210.2,60.4L210.2,60.4z M194.4,56.6h2.9v0.9h-2.9v2.8l0,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2l0,0v1.8h-0.9
		v-9.3l0,0c0-0.4,0.2-0.7,0.5-1c0,0,0.1-0.1,0.2-0.2c0.3-0.3,0.8-0.5,1.3-0.5h2v0.9h-2c-0.5,0-0.9,0.3-1,0.8c0,0,0,0.1,0,0.2
		c0,0.1,0,0.1,0,0.2l0,0V56.6L194.4,56.6z M204.3,51.5v9c0,0.6,0.4,1,1,1h2v0.9h-2c-0.5,0-0.9-0.2-1.3-0.5c0,0-0.1-0.1-0.2-0.2
		c-0.3-0.3-0.5-0.8-0.5-1.3v-9H204.3z"
                    />
                    <g id="XMLID_19_">
                        <path
                          id="XMLID_23_"
                          fill="#050507"
                          d="M217.7,12c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4
			c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4C218.3,12.9,218.1,12.4,217.7,12z
			 M216.2,15.2c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7C218,14.4,217.2,15.2,216.2,15.2z"
                        />
                        <path
                          id="XMLID_20_"
                          fill="#050507"
                          d="M216.7,13.5c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.4-0.1-0.7-0.1
			c-0.2,0-0.4,0-0.6,0v2.2h0.3v-1h0.4c0.3,0,0.5,0.3,0.6,1h0.3c0-0.3-0.1-0.6-0.2-0.7C216.9,13.7,216.8,13.6,216.7,13.5z
			 M216.2,13.4h-0.4v-0.8c0.1,0,0.2,0,0.3,0c0.4,0,0.6,0.1,0.6,0.4C216.8,13.3,216.6,13.4,216.2,13.4z"
                        />
                    </g>
                    <text id="XMLID_288_" transform="matrix(1 0 0 1 158 83)" fill="#ffffff" fontFamily="'ArialMT'" fontSize="8px">TASTE OF SUPERLIFE</text>
                    <rect id="fourthRect" x="0" y="88.7" fill="#ba8416" width="400" height="11.3" />
                    <rect id="XMLID_105_" x="179.6" y="91.3" fill="none" width="41.8" height="5.5" />
                    <text id="XMLID_106_" transform="matrix(1 0 0 1 149.5833 95.9385)" fill="#FFFFFF" fontFamily="'ArialMT'" fontSize="7px">WITH REAL TASTE & HEALTH</text>
                </g>
                <g id="progressHourglassMob" className="progressMobInactive">
                    <path
                      id="XMLID_85_"
                      fill="#ba8416"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M200.1,41c0.5-0.5,1.1-1,1.6-1.5
		c3-2.8,6-5.7,8.9-8.6c3.1-3.2,6.8-6.6,7.4-11.3c0.2-1.8,0.1-3.7-0.4-5.5c-0.1-0.2-0.5-2.3-0.8-2.3h-34.1c-0.6,1.6-1,3.3-1,5.1
		c-0.1,2.5,0.5,5,1.7,7.1c1.3,2.2,3.3,4,5.1,5.9c2,2.1,4.1,4.1,6.1,6.1c1.6,1.7,3.3,3.3,5,5l0.1,0.1c-1.7,1.7-3.3,3.3-5,5
		c-2,2.1-4.1,4.1-6.1,6.1c-1.8,1.8-3.9,3.6-5.1,5.9c-1.2,2.1-1.8,4.7-1.7,7.2c0.1,1.7,0.4,3.5,1,5.1h34.1c0.2,0,0.7-2.1,0.8-2.3
		c0.4-1.7,0.6-3.6,0.4-5.4c-0.6-4.7-4.3-8.1-7.4-11.4c-2.9-3-5.9-5.9-8.9-8.7C201.2,42.1,200.6,41.6,200.1,41"
                    />
                    <path
                      id="leafStrokeLeft_1_"
                      fill="none"
                      stroke="white"
                      strokeMiterlimit="10"
                      d="M185.6,70.3c0,0-3.6-6.6,2.1-12.9l12.5-12.4"
                    />
                    <path
                      id="leafStrokeRiht_1_"
                      fill="none"
                      stroke="white"
                      strokeMiterlimit="10"
                      d="M214.2,69.8c0,0,3.6-6.5-2.2-12.7L199.6,45"
                    />
                    <line id="vertLineLeaf_1_" fill="none" stroke="white" strokeMiterlimit="10" x1="200" y1="69.8" x2="200" y2="45.3" />

                    <line id="leafR1_3_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.1" y1="68.1" x2="212.3" y2="60.2" />

                    <line id="leafL1_3_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="199.9" y1="68.1" x2="187.7" y2="60.2" />

                    <line id="leafR2_3_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.1" y1="60.3" x2="207.4" y2="55.5" />

                    <line id="leafL2_3_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="199.8" y1="60.3" x2="192.4" y2="55.3" />

                    <line id="leafR3_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.1" y1="52.9" x2="203" y2="50.7" />

                    <line id="leafL3_3_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="199.8" y1="52.8" x2="196.9" y2="50.6" />
                    <g id="topLines_3_">

                        <line id="XMLID_80_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="196.6" y1="33.6" x2="203.7" y2="33.6" />

                        <line id="XMLID_79_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="190.6" y1="28" x2="209.8" y2="28" />

                        <line id="XMLID_78_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="186.2" y1="22.3" x2="214.2" y2="22.3" />

                        <line id="XMLID_77_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="185.4" y1="16.4" x2="215.2" y2="16.4" />
                    </g>
                    <text id="XMLID_256_" transform="matrix(1 0 0 1 157 7)" fill="white" fontFamily="'ArialMT'" fontSize="8px">NATURAL PROCESSES</text>
                    <text id="XMLID_286_" transform="matrix(1 0 0 1 150 82)" fill="white" fontFamily="'ArialMT'" fontSize="8px">SUPERFOOD INGREDIENTS</text>
                    <rect id="thirthRect" x="0" y="88.7" fill="#ba8416" width="400" height="11.3" />
                    <text id="XMLID_107_" transform="matrix(1 0 0 1 155.375 96.3958)" fill="#FFFFFF" fontFamily="'ArialMT'" fontSize="7px">CREATING 360° WELLNESS</text>
                </g>
                <g id="progressSteviaMob" className="progressMobInactive">
                    <path
                      id="XMLID_83_"
                      fill="#ba8416"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M171.6,63.3h57.7
		c0.4,0,1.2-3.7,1.3-3.9c0.8-3,1-6.2,0.7-9.2c-1-8-7.3-13.8-12.6-19.3c-4.9-5-9.9-9.9-15-14.7c-1-0.9-2-1.9-3-2.8
		c-2.9,2.9-5.7,5.8-8.6,8.7c-3.4,3.5-6.9,6.9-10.4,10.4c-3.1,3.1-6.6,6.1-8.7,9.9c-2,3.6-3.1,8.1-3,12.2
		C170,57.5,170.6,60.6,171.6,63.3z"
                    />

                    <line id="leafR1_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.7" y1="59.1" x2="222.8" y2="44.8" />

                    <line id="leafL1_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.8" y1="59.1" x2="178.7" y2="44.8" />

                    <line id="leafR2_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.8" y1="45.5" x2="214.3" y2="36.4" />

                    <line id="leafL2_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.7" y1="45.4" x2="187.2" y2="36.3" />

                    <line id="leafR3_1_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.8" y1="32.4" x2="206.3" y2="28.1" />

                    <line id="leafL3_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.8" y1="32.4" x2="195.3" y2="28.1" />

                    <line id="vertLineLeaf_2_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="200.8" y1="63.4" x2="200.8" y2="18.2" />
                    <path
                      id="leafStrokeRiht_2_"
                      display="inline"
                      fill="none"
                      stroke="white"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="
		M226.4,62.8c0,0,6.2-12.5-4.1-23.6l-21.5-21.1"
                    />
                    <path
                      id="leafStrokeLeft_2_"
                      display="inline"
                      fill="none"
                      stroke="white"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      d="
		M174.7,62.8c0,0-5.9-11.9,4.4-23l21.7-21.7"
                    />
                    <text id="XMLID_251_" transform="matrix(1 0 0 1 152 80)" fill="white" fontFamily="'ArialMT'" fontSize="8px">STEVIA, THE MAGIC LEAF</text>
                    <rect id="secondRect" x="0" y="88.7" fill="#ba8416" width="400" height="11.3" />
                    <rect id="XMLID_101_" x="167.3" y="91.6" fill="none" width="65.7" height="5.5" />
                    <text id="XMLID_102_" transform="matrix(1 0 0 1 167.3113 96.295)" fill="#FFFFFF" fontFamily="'ArialMT'" fontSize="7px">END OF SUGAR AGE</text>
                </g>
                <g id="progressFirstMob" className="progressMobInactive">
                    <path
                      id="XMLID_84_"
                      fill="#ba8416"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M170.9,14.3h58.5
		c0,0,6,13-3.9,24.1c-9.8,11.1-25,24.6-25.5,24.7s-26-25-26-25S164.8,28.5,170.9,14.3z"
                    />
                    <g id="topLines_2_">

                        <line id="XMLID_75_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="192.1" y1="50.2" x2="207.5" y2="50.2" />

                        <line id="XMLID_74_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="182.5" y1="40.6" x2="217.1" y2="40.6" />

                        <line id="XMLID_69_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="174.8" y1="31" x2="225.5" y2="31" />

                        <line id="XMLID_26_" fill="none" stroke="white" strokeLinecap="round" strokeMiterlimit="10" x1="172.8" y1="20.9" x2="226.7" y2="20.9" />
                    </g>
                    <text id="XMLID_251_" transform="matrix(1 0 0 1 140 80)" fill="white" fontFamily="'ArialMT'" fontSize="8px">THE NEED, LIKE NEVER BEFORE</text>
                    <rect id="firstRect" x="0" y="88.7" fill="#ba8416" width="400" height="11.3" />
                    <rect id="XMLID_1_" x="125.5" y="91.5" fill="none" width="149.2" height="8.5" />
                    <text id="XMLID_2_" transform="matrix(1 0 0 1 125.4543 96.1885)" fill="#FFFFFF" fontFamily="'ArialMT'" fontSize="6.548px">UNCOVER TRUTH, THE REALLY BITTER TRUTH</text>
                </g>
            </svg>
        );
    }

    renderContent() {
        return (
            <Suspense fallback={ <Loader /> }>
                { this.renderProgressbarWrapper() }
                { this.renderStoryContent() }
            </Suspense>
        );
    }

    render() {
        return (
            <div block="LifelyStoryMobile">
                { this.renderContent() }
            </div>
        );
    }
}

export default LifelyStoryMobileComponent;
