/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './LocationIcon.style';

/** @namespace LifelyScandi/Component/Custom/Icons/LocationIcon/Component/LocationIconComponent */
export class LocationIconComponent extends PureComponent {
  static propTypes = {
      width: PropTypes.string,
      height: PropTypes.string,
      fill: PropTypes.string
  };

  static defaultProps = {
      width: '25',
      height: '25',
      fill: 'white'
  };

  render() {
      const { width, height, fill } = this.props;

      return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={ width }
        height={ height }
        zoomAndPan="magnify"
        viewBox="0 0 37.5 37.499999"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
      >
        <defs>
          <clipPath id="location">
            <path d="M 0.675781 0.675781 L 36.675781 0.675781 L 36.675781 36.675781 L 0.675781 36.675781 Z M 0.675781 0.675781 " clipRule="nonzero" />
          </clipPath>
        </defs>
        <path fill={ fill } d="M 18.796875 8.335938 C 15.199219 8.335938 12.285156 11.246094 12.285156 14.839844 C 12.285156 18.4375 15.199219 21.347656 18.796875 21.347656 C 22.390625 21.347656 25.304688 18.4375 25.304688 14.839844 C 25.304688 11.246094 22.390625 8.335938 18.796875 8.335938 " fillOpacity="1" fillRule="nonzero" />
        <g clipPath="url(#location)">
          <path fill={ fill } d="M 25.796875 20.53125 L 18.796875 31.921875 L 11.785156 20.519531 C 10.527344 18.96875 9.769531 16.996094 9.769531 14.839844 C 9.769531 9.859375 13.8125 5.816406 18.796875 5.816406 C 23.78125 5.816406 27.820312 9.859375 27.820312 14.839844 C 27.820312 17 27.058594 18.980469 25.796875 20.53125 Z M 18.679688 0.679688 C 8.738281 0.679688 0.679688 8.738281 0.679688 18.679688 C 0.679688 28.621094 8.738281 36.683594 18.679688 36.683594 C 28.621094 36.683594 36.683594 28.621094 36.683594 18.679688 C 36.683594 8.738281 28.621094 0.679688 18.679688 0.679688 " fillOpacity="1" fillRule="nonzero" />
        </g>
      </svg>
      );
  }
}

export default LocationIconComponent;
