/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const OTP = 'OTP';

export const VERIFY_OTP = 'VERIFY_OTP';

export const SET_PASSWORD = 'SET_PASSWORD';

export const SUBMIT_SIGNUP = 'SUBMIT_SIGNUP';

export const TERMS_CONDITIONS = 'terms-and-conditions';

export const MAX_RESEND_OTP_TIMER = 30;

export const EMAIL_FORMAT = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
