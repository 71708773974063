import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    OfflineNoticeContainer as SourceOfflineNoticeContainer
} from 'SourceComponent/OfflineNotice/OfflineNotice.container';

/** @namespace LifelyScandi/Component/OfflineNotice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/OfflineNotice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/OfflineNotice/Container/OfflineNoticeContainer */
export class OfflineNoticeContainer extends SourceOfflineNoticeContainer {
    // TODO implement logic
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OfflineNoticeContainer)
);
