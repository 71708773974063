/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { IS_LOADING, UPDATE_VERIFY_OTP } from './SignIn.action';

export const getInitialState = () => ({
    loading: false,
    verifyOtp: false
});

export const SignInReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {

    case IS_LOADING:
        const { loading } = action;
        return {
            ...state,
            loading
        };

    case UPDATE_VERIFY_OTP:
        const { verifyOtp } = action;
        return {
            ...state,
            verifyOtp
        };

    default:
        return state;
    }
};

export default SignInReducer;
