/* eslint-disable react/no-did-update-set-state */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable react/no-unused-state */
/* eslint-disable max-lines */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CART } from 'Component/Header/Header.config';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { HistoryType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { TotalsType } from 'Type/MiniCart';
import { isSignedIn } from 'Util/Auth';
import { toggleScroll } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import {
    getCartShippingPrice,
    getCartShippingSubPrice,
    getCartSubtotal,
    getCartSubtotalSubPrice,
    getCartTotalSubPrice,
    getItemsCountLabel
} from 'Util/Cart';
import history from 'Util/History';
import { getProductInStock } from 'Util/Product/Extract';
import { fetchQuery } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import CustomVariableQuery from '../../../query/CustomVariable.query';
import { toggleDrawerCart } from '../../../store/custom/DrawerCart/DrawerCart.action';
import AltCart from './AltCart.component';

export const RewardPointsDispatcher = import(
    '../../../../packages/@lifely/mirasvit-reward-points/src/store/RewardsPoints/RewardsPoints.dispatcher'
);
export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace LifelyScandi/Component/Custom/AltCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    headerState: state.NavigationReducer[ TOP_NAVIGATION_TYPE ].navigationState,
    guest_checkout: state.ConfigReducer.guest_checkout,
    device: state.ConfigReducer.device,
    cartDisplayConfig: state.ConfigReducer.cartDisplayConfig,
    cartSubtotal: getCartSubtotal(state),
    cartSubtotalSubPrice: getCartSubtotalSubPrice(state),
    cartTotalSubPrice: getCartTotalSubPrice(state),
    cartShippingPrice: getCartShippingPrice(state),
    cartShippingSubPrice: getCartShippingSubPrice(state),
    isMobile: state.ConfigReducer.device.isMobile,
    isPaneOpen: state.DrawerCartReducer.isPaneOpen,
    storeCode: state.ConfigReducer.store_code
});

/** @namespace LifelyScandi/Component/Custom/AltCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(breadcrumbs, dispatch)
    ),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateCrossSellProducts: (items) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateCrossSellProducts(items, dispatch)
    ),
    toggleDrawerCart: (type) => dispatch(toggleDrawerCart(type)),
    getCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
});

/** @namespace LifelyScandi/Component/Custom/AltCart/Container/AltCartContainer */
export class AltCartContainer extends PureComponent {
    static propTypes = {
        updateBreadcrumbs: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        updateCrossSellProducts: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        guest_checkout: PropTypes.bool.isRequired,
        history: HistoryType.isRequired,
        totals: TotalsType.isRequired,
        device: DeviceType.isRequired
    };

    state = {
        specialPriceDiscount: '',
        BottomSheetIsVisible: false,
        showAllOffersDetail: [],
        cvQuickDeliveryItemOffers: BrowserDatabase.getItem('quickDeliveryItemOffers') || {}
    };

    containerFunctions = {
        onCheckoutButtonClick: this.onCheckoutButtonClick.bind(this),
        toggleOffersListBottomSheet: this.toggleOffersListBottomSheet.bind(this),
        closeBottomSheet: this.closeBottomSheet.bind(this)
    };

    componentDidMount() {
        const {
            updateMeta, getCartData
        } = this.props;

        getCartData();
        updateMeta({ title: __('Cart') });
        this._updateBreadcrumbs();
        this._changeHeaderState();
        this._updateCrossSellProducts();
        this.getProductItemSpecialPrice();
        this.shakingAnimationSavingStrip();
        this.fetchOffersCvData();
    }

    componentDidUpdate(prevProps) {
        const {
            changeHeaderState,
            totals: { items_qty = 0 },
            headerState,
            headerState: { name },
            device, location, toggleDrawerCart
        } = this.props;

        const {
            totals: { items_qty: prevItemsQty = 0 },
            headerState: { name: prevName }
        } = prevProps;

        if (name !== prevName) {
            if (name === CART) {
                this._changeHeaderState();
            }
        }

        if (items_qty !== prevItemsQty && prevItemsQty !== undefined) {
            if (items_qty === 0) {
                this.setState({ specialPriceDiscount: '' });
            }
            const title = getItemsCountLabel(items_qty);
            changeHeaderState({
                ...headerState,
                title
            });
            this.getProductItemSpecialPrice();
        }
        if (!device.isMobile) {
            if (location
                && (location.pathname.toLowerCase().indexOf('/cart') !== -1)) {
                history.push(appendWithStoreCode('/'));
                toggleDrawerCart(true);
                toggleScroll(false);
            }
        }
    }

    containerProps = () => {
        const {
            totals, totals: { items = [] } = {}, device, getCartData, rewardPoints
        } = this.props;
        const { specialPriceDiscount, BottomSheetIsVisible, showAllOffersDetail } = this.state;
        return {
            hasOutOfStockProductsInCart: this.hasOutOfStockProductsInCartItems(items),
            totals,
            device,
            getCartData,
            getProductItemSpecialPrice: this.getProductItemSpecialPrice.bind(this),
            specialPriceDiscount,
            rewardPoints,
            BottomSheetIsVisible,
            showAllOffersDetail
        };
    };

    hasOutOfStockProductsInCartItems = (items) => (
        items.some(({ product }) => !getProductInStock(product))
    );

    onCheckoutButtonClick(e) {
        const {
            history,
            guest_checkout,
            showOverlay,
            showNotification,
            device,
            totals
        } = this.props;

        // to prevent outside-click handler trigger
        e.nativeEvent.stopImmediatePropagation();

        if (this.hasOutOfStockProductsInCartItems(totals.items)) {
            return;
        }

        if (guest_checkout) {
            history.push({
                pathname: appendWithStoreCode(CHECKOUT_URL)
            });
            window.scrollTo({ top: 0 });

            return;
        }

        if (isSignedIn()) {
            history.push({
                pathname: appendWithStoreCode(CHECKOUT_URL)
            });
            window.scrollTo({ top: 0 });

            return;
        }
        // fir notification whatever device that is
        showNotification('info', __('Please sign-in to complete checkout!'));

        if (device.isMobile) { // for all mobile devices, simply switch route
            history.push({ pathname: appendWithStoreCode('/my-account') });

            return;
        }
        // for desktop, just open customer overlay
        showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
    }

    _updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const breadcrumbs = [
            { url: '/cart', name: __('Shopping cart') }
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    _changeHeaderState() {
        const { changeHeaderState, totals: { items_qty } } = this.props;
        const title = getItemsCountLabel(items_qty);

        changeHeaderState({
            name: CART,
            title,
            onCloseClick: () => history.goBack()
        });
    }

    _updateCrossSellProducts() {
        const {
            updateCrossSellProducts,
            totals: {
                items = []
            } = {}
        } = this.props;

        updateCrossSellProducts(items);
    }

    getProductItemSpecialPrice() {
        const { totals: { items, discount_amount } } = this.props;
        let count = [];
        if (items && items.length > 0) {
            items.map((item) => {
                const { product, qty } = item;
                if (product) {
                    const { price_range } = product;
                    if (price_range) {
                        const { maximum_price } = price_range;
                        if (maximum_price) {
                            const { discount } = maximum_price;
                            if (discount) {
                                const { amount_off } = discount;
                                count.push(amount_off * qty);
                                const sum = count.reduce((partialSum, a) => partialSum + a, 0);
                                const totalSum = sum + (-discount_amount);
                                this.setState({ specialPriceDiscount: totalSum });
                                this.shakingAnimationSavingStrip();
                            }
                        }
                    }
                }

                return true;
            });
        }
    }

    shakingAnimationSavingStrip() {
        setTimeout(() => {
            if (document.querySelector('.AltCart-SavingTotalAmountContainer')) {
                document.querySelector('.AltCart-SavingTotalAmountContainer').classList.add('shakeAnimationSavingStrip');
                setTimeout(() => {
                    document.querySelector('.AltCart-SavingTotalAmountContainer').classList.remove('shakeAnimationSavingStrip');
                }, 1000);
            }
        }, 300);
    }

    toggleOffersListBottomSheet() {
        const { BottomSheetIsVisible, cvQuickDeliveryItemOffers } = this.state;
        const { storeCode } = this.props;
        this.setState({ BottomSheetIsVisible: !BottomSheetIsVisible });
        this.setState({ showAllOffersDetail: cvQuickDeliveryItemOffers[ storeCode ] });
    }

    closeBottomSheet() {
        this.setState({ BottomSheetIsVisible: false });
    }

    fetchOffersCvData() {
        fetchQuery(CustomVariableQuery.getCustomVariableQuery('cv_quick_delivery_item_offers')).then(
            /** @namespace LifelyScandi/Component/Custom/AltCart/Container/fetchQuery/then */
            (data) => {
                if (data) {
                    const cvQuickDeliveryItemOffersData = data && data.variable && data.variable.html !== ''
                        ? JSON.parse(data.variable.html)
                        : {};

                    BrowserDatabase.setItem(cvQuickDeliveryItemOffersData, 'quickDeliveryItemOffers');
                    this.setState({ cvQuickDeliveryItemOffers: cvQuickDeliveryItemOffersData });
                }
            },
            /** @namespace LifelyScandi/Component/Custom/AltCart/Container/fetchQuery/then */
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        return (
            <AltCart
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AltCartContainer);
