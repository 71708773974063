import { connect } from 'react-redux';

import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
/** @namespace LifelyScandi/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace LifelyScandi/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    static defaultProps = {
        quantity: 1,
        configurableVariantIndex: 0,
        setQuantityToDefault: () => { },
        onProductValidationError: () => { },
        isLoading: false,
        disableHandler: false,
        mix: {},
        disabled: false,
        layout: GRID_LAYOUT,
        isCartPageLinkedProduct: false
    };

    containerProps() {
        const {
            product,
            mix,
            disabled,
            layout,
            quantity,
            isMobile,
            isCartPageLinkedProduct
        } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading,
            product,
            mix,
            disabled,
            layout,
            quantity,
            isMobile,
            isCartPageLinkedProduct
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
