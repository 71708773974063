import UpdateMobileno from '../component/UpdateMobileno';

export class MyAccountCustomerTablePlugin {
    renderCustomerMobileNumber = (args, callback, instance) => (
        <>
            { callback.apply(instance, args) }
            <UpdateMobileno />
        </>
    );
}

export const { renderCustomerMobileNumber } = new MyAccountCustomerTablePlugin();

export default {
    'Component/MyAccountCustomerTable/Component': {
        'member-function': {
            renderCustomerMobileNumber: {
                position: 101,
                implementation: renderCustomerMobileNumber
            }
        }
    }
};
