/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap-extras';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BrowserDatabase from 'Util/BrowserDatabase';
import { debounce } from 'Util/Request';

import AboutLifelyBrandInDetail from './AboutLifelyBrandInDetail.component';

/** @namespace LifelyScandi/Component/Custom/AboutLifelyBrandInDetail/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    atTop: _state.StickyHeaderReducer.atTop,
    isMobile: _state.ConfigReducer.device.isMobile
});

/** @namespace LifelyScandi/Component/Custom/AboutLifelyBrandInDetail/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace LifelyScandi/Component/Custom/AboutLifelyBrandInDetail/Container/AboutLifelyBrandInDetailContainer */
export class AboutLifelyBrandInDetailContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        registerPlugins: this.registerPlugins.bind(this),
        lifelyTheoryAnimation: this.lifelyTheoryAnimation.bind(this)
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        BrowserDatabase.setItem(false, 'homescripts_executed');
        this.debouncedHandleScroll = debounce(this.handleScroll, 10);
        window.addEventListener('scroll', this.debouncedHandleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedHandleScroll);
        BrowserDatabase.setItem(false, 'homescripts_executed');
    }

    containerProps() {
        const { isMobile } = this.props;
        return {
            isMobile
        };
    }

    registerPlugins() {
        gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MotionPathPlugin);
    }

    lifelyTheoryAnimation() {
        const toggleDnaVisibility = gsap.timeline({
            scrollTrigger: {
                trigger: '.LifelyTheory-in',
                scrub: 0.5,
                start: 'top top',
                end: '100%',
                toggleClass: { targets: '#dna', className: 'visible' }
            }
        });
        const pulses = gsap
            .timeline({
                defaults: {
                    duration: 0.05,
                    autoAlpha: 1,
                    scale: 2,
                    transformOrigin: 'center',
                    ease: 'elastic(2.5, 1)'
                }
            })
            .to('.ball02, .text01', {}, 0.13)
            // .to('.ball03, .text02', {}, 0.33)
            .to('.ball04, .text03', {}, 0.27)
            // .to('.ball05, .text04', {}, 0.60)
            .to('.ball06, .text05', {}, 0.5);
        // eslint-disable-next-line no-unused-vars
        const svg = document.getElementById('svgLifelyTheoryProgress');
        if (!svg) {
            return;
        }
        if (document.getElementById('svgLifelyTheoryProgress')) {
            document.getElementById('svgLifelyTheoryProgress').style.visibility = 'visible';
        }
        gsap.timeline({
            defaults: {
                duration: 1,
                repeat: 0
            },
            scrollTrigger: {
                trigger: '#svgLifelyTheoryProgress',
                scrub: true,
                start: 'top 50%',
                end: 'bottom center'
            }
        })
            .to('.ball01', { duration: 0.01, autoAlpha: 2 })
            .from('.theLine', { drawSVG: 0 }, 0)
            .to(
                '.ball01',
                {
                    motionPath: {
                        path: '.theLine',
                        align: '.theLine',
                        alignOrigin: [0.5, 0.5]
                    }
                },
                0
            )
            .add(pulses, 0)
            .add(toggleDnaVisibility, 0);

        // main.then(() => {
        //     alert('timeline finished');
        //     pulses.kill();
        //     main.kill();
        // });
    }

    handleScroll = () => {
        const svg = document.getElementById('svgLifelyTheoryProgress');
        const homescripts_executed = BrowserDatabase.getItem(
            'homescripts_executed'
        );

        if (svg && !homescripts_executed) {
            BrowserDatabase.setItem(true, 'homescripts_executed');
            this.registerPlugins();
            this.lifelyTheoryAnimation();
        }
    };

    render() {
        return (
            <AboutLifelyBrandInDetail
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutLifelyBrandInDetailContainer);
