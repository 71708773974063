/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CURRENT_SLIDE_PANE, TOGGLE_DRAWER_CART } from './DrawerCart.action';

/** @namespace LifelyScandi/Store/Custom/DrawerCart/Reducer/getInitialState */
export const getInitialState = () => ({
    isPaneOpen: false,
    slidePaneName: ''
});

/** @namespace LifelyScandi/Store/Custom/DrawerCart/Reducer/DrawerCartReducer */
export const DrawerCartReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_DRAWER_CART:
        const { isPaneOpen } = action;
        return {
            ...state,
            isPaneOpen
        };
    case CURRENT_SLIDE_PANE:
        const { slidePaneName } = action;
        return {
            ...state,
            slidePaneName
        };
    default:
        return state;
    }
};

export default DrawerCartReducer;
