/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
// import {
//     getMessaging, getToken, onMessage
// } from 'firebase/messaging';

import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

// import { appendGoogleMapAPIScript } from '../../util/Map/Map';
import './App.style';

/** @namespace LifelyScandi/Component/App/Component/AppComponent */
export class AppComponent extends SourceApp {
    state = {
        gMapInit: false
    };

    componentDidMount() {
        // appendGoogleMapAPIScript();
        window.oncontextmenu = function () {
            return false;
        };
    }
}

export default AppComponent;
