/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-bind */
import { isSignedIn } from 'Util/Auth';
import { formatPrice } from 'Util/Price';

import RewardPointsNotification from '../../../packages/@lifely/mirasvit-reward-points/src/component/RewardPointsNotification';
import AltCart from '../../component/custom/AltCart';
import SlidingPane from '../../component/custom/SlidingPane';

/** @namespace LifelyScandi/Plugin/DrawerCart/Header/Pplugin/Headerplugin */
export class HeaderPlugin {
    onrenderMinicart(args, callback, instance) {
        const {
            isPaneOpen, slidePaneName, closeDrawerCart, secureCheckout, continueShopping, device: { isMobile }, cartTotals: {
                subtotal_with_discount, quote_currency_code, items_qty
            }
        } = instance.props;
        const DRAWER_CART_TITLE = 'My Superfood Cart';
        const DRAWER_CART_FOOTER_BUTTON_CHECKOUT_TEXT = (isSignedIn() ? 'Checkout Now' : 'Login To Checkout');
        const DRAWER_CART_FOOTER_BUTTON_EMPTY_CART_TEXT = 'Continue Shopping';

        const DRAWER_CART_DIRECTION = 'right';
        const DRAWER_CART_WIDTH = '45%';
        const DRAWER_CART_FOOTER_INFORMATION_TEXT = 'Total Payable Amount :';
        return (
            <>
                { callback.apply(instance, args) }
                { !isMobile && slidePaneName === 'cart' && (
                    <SlidingPane
                      key={ DRAWER_CART_TITLE }
                      isOpen={ isPaneOpen }
                      title={ DRAWER_CART_TITLE }
                      message={ <RewardPointsNotification /> }
                      width={ DRAWER_CART_WIDTH }
                      from={ DRAWER_CART_DIRECTION }
                      user={ isSignedIn() }
                    //   informationText={ items_qty === 0 ? null : DRAWER_CART_FOOTER_INFORMATION_TEXT }
                    //   payableAmount={ items_qty === 0 ? null : formatPrice(subtotal_with_discount, quote_currency_code) }
                      footer={ items_qty === 0 ? DRAWER_CART_FOOTER_BUTTON_EMPTY_CART_TEXT : DRAWER_CART_FOOTER_BUTTON_CHECKOUT_TEXT }
                      onRequestClose={ () => {
                          closeDrawerCart();
                      } }
                      onButtonClick={ () => {
                          if (items_qty !== 0) {
                              secureCheckout();
                          }
                          if (items_qty === 0) {
                              continueShopping();
                          }
                          closeDrawerCart();
                      } }
                    >
                        <AltCart />
                    </SlidingPane>
                ) }
            </>
        );
    }
}

export const {
    onrenderMinicart
} = new HeaderPlugin();
export default {

    'Component/Header/Component': {
        'member-function': {
            renderMinicart: [
                {
                    position: 101,
                    implementation: onrenderMinicart
                }
            ]
        }
    }
};
