import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.style';

/** @namespace LifelyScandi/Component/ProductReviewRating/Component/ProductReviewRatingComponent */
export class ProductReviewRatingComponent extends SourceProductReviewRating {
    // TODO implement logic
}

export default ProductReviewRatingComponent;
