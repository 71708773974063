/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const TOGGLE_STICKY_PRODUCT_HEADER = 'TOGGLE_STICKY_PRODUCT_HEADER';

/** @namespace LifelyScandi/Store/Custom/ProductDetailStickyHeader/Action/toggleProductDetailStickyHeader */
export const toggleProductDetailStickyHeader = (stickyAtTop) => ({
    type: TOGGLE_STICKY_PRODUCT_HEADER,
    stickyAtTop
});
