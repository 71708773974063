import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.style';

/** @namespace LifelyScandi/Component/Overlay/Component/OverlayComponent */
export class OverlayComponent extends SourceOverlay {
    // TODO implement logic
}

export default OverlayComponent;
