/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React from 'react';

import { isSignedIn } from 'Util/Auth';

export const RewardPointsNotification = React.lazy(() => import('../component/RewardPointsNotification'));

const renderHeading = (args, callback, instance) => {
    if (isSignedIn()) {
        return (
            <>
                { callback.apply(instance, args) }
                { <RewardPointsNotification /> }
            </>
        );
    }

    return (callback.apply(instance, args));
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderHeading: {
                position: 100,
                implementation: renderHeading
            }
        }
    }
};
