import {
    ProductWishlistButton as SourceProductWishlistButton
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.style';

/** @namespace LifelyScandi/Component/ProductWishlistButton/Component/ProductWishlistButtonComponent */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    // TODO implement logic
}

export default ProductWishlistButtonComponent;
