/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const IS_LOADING = 'IS_LOADING';
export const VERIFY_OTP = 'VERIFY_OTP';
export const NEW_MOBILE_NUMBER = 'NEW_MOBILE_NUMBER';

export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading
});

export const isVerifyOtp = (verifyOtp) => ({
    type: VERIFY_OTP,
    verifyOtp
});

export const newMobileNumber = (newMobileNumber) => ({
    type: NEW_MOBILE_NUMBER,
    newMobileNumber
});
