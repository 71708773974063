
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { ACCOUNT_SIGNIN_OTP } from '../route/Url.config'

const onMyAccountButtonClickActions = (args, callback, instance) => {
    if (isSignedIn()) {
        history.push({ pathname: appendWithStoreCode('/my-account/dashboard') });
        return;
    }

    history.push({ pathname: appendWithStoreCode(ACCOUNT_SIGNIN_OTP) });
}

 export default {
    'Component/Header/Container': {
        'member-function': {
            onMyAccountButtonClick:[
                {
                    position: 100,
                    implementation: onMyAccountButtonClickActions
                }
            ]
        }
    }
};