import { Field } from 'Util/Query';

export class MyAccountPlugin {
    _getCustomerFields = (args, callback) => [
        ...callback(...args),
        new Field('customFields')
            .addFieldList(['health_consultation'])
    ];
}
export const {
    _getCustomerFields
} = new MyAccountPlugin();

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields
        }
    }
};
