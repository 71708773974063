/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';

import {
    // ACCOUNT_SIGNIN_PASSWORD,
    ACCOUNT_SIGNUP_OTP,
    ACCOUNT_SIGNUP_OTP_REFERRAL_CODE
    // FORGOT_PASSWORD_EMAIL,
    // FORGOT_PASSWORD_PHONE
} from '../route/Url.config';

/** @namespace Lifely/Auth/Plugin/Router/Pplugin/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const SignupWithOtp = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "auth" */
    '../route/SignupWithOtp'
));

export const Signin = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "auth" */
    '../route/Signin'
));

/** @namespace Lifely/Auth/Plugin/Router/Pplugin/SWITCH_ITEMS_TYPE */
export const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
            <Route
              path={ withStoreRegex('/:account*/login/') }
              exact
              render={ (props) => <Signin { ...props } /> }
            />
        ),
        position: 61
    },
    {
        component: (
            <Route
              path={ withStoreRegex('/:account*/signup/') }
              exact
              render={ (props) => <SignupWithOtp { ...props } /> }
            />
        ),
        position: 65
    },
    {
        component: (
            <Route
              path={ withStoreRegex('/:account*/signup/:referral_code') }
              exact
              render={ (props) => <SignupWithOtp { ...props } /> }
            />
        ),
        position: 66
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};
