/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import CartItem from 'Component/CartItem';
import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';
import LockIcon from 'Component/LockIcon';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { CheckoutOrderSummary as SourceCheckoutOrderSummary } from 'SourceComponent/CheckoutOrderSummary/CheckoutOrderSummary.component';
import { getItemsCountLabel } from 'Util/Cart';

import './CheckoutOrderSummary.style';

/** @namespace LifelyScandi/Component/CheckoutOrderSummary/Component/CheckoutOrderSummaryComponent */
export class CheckoutOrderSummaryComponent extends SourceCheckoutOrderSummary {
    renderOrderTotal() {
        const {
            totals: {
                grand_total,
                quote_currency_code
            },
            cartTotalSubPrice,
            checkoutStep
        } = this.props;
        const title = 'Payable Amount';
        if (checkoutStep !== BILLING_STEP) {
            return null;
        }

        // if (cartTotalSubPrice) {
        return (
            <CheckoutOrderSummaryPriceLine
              price={ grand_total }
              currency={ quote_currency_code }
              title={ title }
              value={ title }
              subPrice={ cartTotalSubPrice }
              mods={ { isTotal: true } }
            />
        );
        // }

        // return this.renderPriceLine(grand_total, title, { isTotal: true });
    }

    renderSubTotal() {
        const {
            isMobile,
            totals: { quote_currency_code, items = [] },
            cartSubtotal,
            cartSubtotalSubPrice
        } = this.props;
        const title = __('Subtotal');

        if (cartSubtotal) {
            return (
                <CheckoutOrderSummaryPriceLine
                  price={ cartSubtotal }
                  currency={ quote_currency_code }
                  title={ title }
                  subPrice={ cartSubtotalSubPrice }
                  isMobile={ isMobile }
                />
            );
        }

        return this.renderPriceLine(cartSubtotal, title);
    }

    renderProceedToPay() {
        const { isMobile, isBillingPage, shippingMethods } = this.props;
        if (isMobile) {
            return null;
        }
        if (isBillingPage) {
            return null;
        }
        if (!(location.pathname.toLowerCase().indexOf('/checkout') !== -1)) {
            return null;
        }

        // if (!shippingMethods || shippingMethods.length === 0) {
        //     return null;
        // }

        return (
            <div block="CheckoutOrderSummary" elem="PayButtonWrapper">
                <button
                  block="Button"
                  mix={ { block: 'CheckoutOrderSummary', elem: 'Button' } }
                  onClick={ () => {
                      document
                          .getElementsByClassName(
                              'CheckoutShipping-Button'
                          )[ 0 ]
                          .click();
                      window.scrollTo(0, 0);
                  } }
                >
                    <LockIcon />
                    { __('Secure Pay') }
                </button>
            </div>
        );
    }

    renderItem = (item) => {
        const {
            totals: { quote_currency_code }
        } = this.props;

        const { item_id } = item;
        return (
            <CartItem
              key={ item_id }
              item={ item }
              currency_code={ quote_currency_code }
              updateCrossSellsOnRemove
            />
        );
    };

    getShippingLabel() {
        const { checkoutStep } = this.props;

        if (checkoutStep === BILLING_STEP) {
            return __('Delivery Charges');
        }

        return null;
    }

    renderShipping() {
        const {
            totals: {
                quote_currency_code
            },
            cartShippingPrice,
            cartShippingSubPrice,
            checkoutStep
        } = this.props;

        if (checkoutStep !== BILLING_STEP) {
            return null;
        }
        const title = this.getShippingLabel();
        const mods = { divider: true };
        if (!cartShippingSubPrice) {
            return this.renderPriceLine(cartShippingPrice, title, mods);
        }

        return (
            <CheckoutOrderSummaryPriceLine
              price={ cartShippingPrice }
              currency={ quote_currency_code }
              title={ title }
              mods={ mods }
              subPrice={ cartShippingSubPrice }
            />
        );
    }

    renderTotals() {
        const { children, totals: { items = [] }, isMobile } = this.props;
        return (
            <div block="CheckoutOrderSummary" elem="OrderTotals" id="cartItemPaymentSummary">
                <ul>
                    { this.renderSubTotal() }
                    { this.renderTax() }
                    { this.renderDiscount() }
                    { this.renderShipping() }
                    <div block="CheckoutOrderSummary" elem="ButtonWrapper" mods={ { isEmpty: items.length < 1 } }>
                        { !isMobile && this.renderOrderTotal() }
                        { children }
                    </div>
                </ul>
            </div>
        );
    }

    renderContent() {
        const { isExpandable } = this.props;

        if (isExpandable) {
            return this.renderExpandableContent();
        }

        return (
            <>
                { this.renderHeading() }
                { this.renderItems() }
                { this.renderTotals() }
            </>
        );
    }

    renderItems() {
        const { showItems, totals: { items_qty, items = [] } } = this.props;

        if (!showItems) {
            return null;
        }

        return (
            <>
                <div block="CheckoutOrderSummary" elem="ItemsInCart">
                    { getItemsCountLabel(items_qty) }
                </div>
                <div block="CheckoutOrderSummary" elem="OrderItems">
                    <div block="CheckoutOrderSummary" elem="CartItemList">
                        { items.map(this.renderItem) }
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="OrderSummary">
                <article
                  block="CheckoutOrderSummary"
                  aria-label="Order Summary"
                >
                    { this.renderContent() }
                    { /* { this.renderProceedToPay() } */ }

                </article>
            </div>
        );
    }
}

export default CheckoutOrderSummaryComponent;
