/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-delete */
import PropTypes from 'prop-types';
import React from 'react';

import {
    MY_DOWNLOADABLE, NEWSLETTER_SUBSCRIPTION
} from 'Type/Account';

export const WELLNESS_PROGRAM = 'wellness-program';
export const WellnessProgram = React.lazy(() => import('../component/WellnessProgram'));

export class MyAccountPlugin {
    renderMap = (originalMember) => ({
        ...originalMember,
        [ WELLNESS_PROGRAM ]: WellnessProgram
    });

    renderPropTypes = (originalMember) => ({
        ...originalMember,
        activeTab: PropTypes.oneOfType([
            originalMember.activeTab,
            PropTypes.oneOf([WELLNESS_PROGRAM])
        ])
    });

    tabMap(originalMember) {
        delete originalMember[ MY_DOWNLOADABLE ];
        delete originalMember[ NEWSLETTER_SUBSCRIPTION ];
        return {
            ...originalMember,
            [ WELLNESS_PROGRAM ]: {
                url: '/wellness-program',
                name: __('Lifely Wellness Program')
            }
        };
    }
}
export const {
    renderMap,
    renderPropTypes,
    tabMap
} = new MyAccountPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: [
                {
                    position: 102,
                    implementation: renderMap
                }
            ]
        },
        'static-member': {
            propTypes: [
                {
                    position: 102,
                    implementation: renderPropTypes
                }
            ]
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: [
                {
                    position: 102,
                    implementation: tabMap
                }
            ]
        }
    }
};
