import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH as SOURCE_DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX as SOURCE_DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX as SOURCE_DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
} from 'SourceComponent/ProductPrice/ProductPrice.config';

// TODO: implement DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
export const DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX = SOURCE_DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX;

// TODO: implement DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
export const DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX = SOURCE_DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX;

// TODO: implement DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH
export const DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH = SOURCE_DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH;
