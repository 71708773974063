/* eslint-disable no-unused-vars */
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';
import {
    ProductLinks as SourceProductLinks
} from 'SourceComponent/ProductLinks/ProductLinks.component';

import './ProductLinks.style';

/** @namespace LifelyScandi/Component/ProductLinks/Component/ProductLinksComponent */
export class ProductLinksComponent extends SourceProductLinks {
    renderHeading() {
        const { title, isCartPageLinkedProduct } = this.props;

        return (
            <h2 block="ProductLinks" elem="Title" mods={ { isCartPageLinkedProduct } }>
                { title }
            </h2>
        );
    }

    renderItems() {
        const {
            linkType,
            linkedProducts: { [ linkType ]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        return items.slice(0, items.length).map(this.renderProductCard);
    }

    renderProductCard(product, i) {
        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            },
            isCartPageLinkedProduct
        } = this.props;
        const { id = i, stock_status, type_id } = product;

        if (stock_status !== 'IN_STOCK' && type_id !== 'bundle') {
            return null;
        }

        return (
            <ProductCard
              block="ProductLinks"
              elem="Card"
              isLinkedProduct
              isCartPageLinkedProduct={ isCartPageLinkedProduct }
              product={ product }
              key={ id }
              siblingsHaveBrands={ siblingsHaveBrands }
              siblingsHavePriceBadge={ siblingsHavePriceBadge }
              siblingsHaveTierPrice={ siblingsHaveTierPrice }
              siblingsHaveConfigurableOptions={ siblingsHaveConfigurableOptions }
              setSiblingsHaveBrands={ setSiblingsHaveBrands }
              setSiblingsHavePriceBadge={ setSiblingsHavePriceBadge }
              setSiblingsHaveTierPrice={ setSiblingsHaveTierPrice }
              setSiblingsHaveConfigurableOptions={ setSiblingsHaveConfigurableOptions }
            />
        );
    }

    render() {
        const { areDetailsLoaded, isCartPageLinkedProduct } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper', mods: { isCartPageLinkedProduct } } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <ul block="ProductLinks" elem="List" mods={ { isCartPageLinkedProduct } }>
                    { this.renderItems() }
                </ul>
            </ContentWrapper>
        );
    }
}

export default ProductLinksComponent;
