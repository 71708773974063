/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const TOGGLE_DRAWER_CART = 'TOGGLE_DRAWER_CART';
export const CURRENT_SLIDE_PANE = 'CURRENT_SLIDE_PANE';

/** @namespace LifelyScandi/Store/Custom/DrawerCart/Action/toggleDrawerCart */
export const toggleDrawerCart = (isPaneOpen) => ({
    type: TOGGLE_DRAWER_CART,
    isPaneOpen
});
/** @namespace LifelyScandi/Store/Custom/DrawerCart/Action/currentSlidePane */
export const currentSlidePane = (slidePaneName) => ({
    type: CURRENT_SLIDE_PANE,
    slidePaneName
});
