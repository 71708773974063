/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { OTP } from '../../route/SignupWithOtp/SignupWithOtp.config';
import { IS_LOADING, TAB } from './Signup.action';

/** @namespace Lifely/Auth/Store/Signup/Reducer/getInitialState */
export const getInitialState = () => ({
    loading: false,
    tab: OTP
});

/** @namespace Lifely/Auth/Store/Signup/Reducer/SignupReducer */
export const SignupReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case IS_LOADING:
        const { loading } = action;
        return {
            ...state,
            loading
        };

    case TAB:
        const { tab } = action;
        return {
            ...state,
            tab
        };

    default:
        return state;
    }
};

export default SignupReducer;
