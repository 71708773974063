/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AboutLifelyBrandInDetail from '../AboutLifelyBrandInDetail';
import ReferLifely from '../ReferLifely';

// import Faqs from '../Faqs';
import './FeatureWidget.style';

/** @namespace LifelyScandi/Component/Custom/FeatureWidget/Component/FeatureWidgetComponent */
export class FeatureWidgetComponent extends PureComponent {
    static propTypes = {
    };

    renderDynamicComponent() {
        const { name } = this.props;
        if (name === 'refer_lifely') {
            return (<ReferLifely />);
        }
        if (name === 'about_lifely_brand_details') {
            return (<AboutLifelyBrandInDetail />);
        }

        return true;
    }

    render() {
        return (
            <div block="FeatureWidget">
                { this.renderDynamicComponent() }
            </div>
        );
    }
}

export default FeatureWidgetComponent;
