// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './HamburgerIcon.style';

/** @namespace LifelyScandi/Component/Custom/Icons/HamburgerIcon/Component/HamburgerIconComponent */
export class HamburgerIconComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        return (
            <svg
              block="HamburgerIcon"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#000000"
            >
                <path d="M2 11H22V13H2zM2 5H22V7H2zM2 17H22V19H2z" />
            </svg>
        );
    }
}

export default HamburgerIconComponent;
