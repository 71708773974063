/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-props-no-spreading */
import FieldInput from 'Component/FieldInput';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import AddIcon from '../AddIcon';
import MinusIcon from '../MinusIcon';
import {
    CHECKBOX_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TELEPHONE_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

import './Field.style';

/** @namespace LifelyScandi/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    renderLabel() {
        const {
            id,
            label,
            validation,
            type
        } = this.props;

        let isRequired = '';
        if (validation && validation.length > 0) {
            isRequired = validation.includes('notEmpty');
        }
        const noRenderLabel = type === CHECKBOX_TYPE || type === RADIO_TYPE;
        if (!label || noRenderLabel) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label
                  block="Field"
                  elem="Label"
                  mods={ { isRequired } }
                  htmlFor={ id }
                >
                    { label }
                </label>

                { this.renderArrow() }
            </div>
        );
    }

    renderTypeNumber() {
        const {
            min, max, value, onKeyEnterDown, handleChange
        } = this.props;

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly
                    // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
                <button
                  block="Field"
                  elem="PlusButton"
                  disabled={ +value === max }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                  aria-label={ __('Add') }
                >
                    <AddIcon block="SubtractButton" />
                </button>
                <button
                  block="Field"
                  elem="MinusButton"
                  disabled={ +value === min }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                  aria-label={ __('Subtract') }
                >
                    <MinusIcon block="AddButton" />
                </button>
            </>
        );
    }

    renderTypeTelephone() {
        return (
            <FieldInput
              { ...this.props }
              type="tel"
            />
        );
    }

    renderInputOfType(type) {
        switch (type) {
        case CHECKBOX_TYPE:
            return this.renderCheckbox();
        case RADIO_TYPE:
            return this.renderRadioButton();
        case NUMBER_TYPE:
            return this.renderTypeNumber();
        case TEXTAREA_TYPE:
            return this.renderTextarea();
        case PASSWORD_TYPE:
            return this.renderTypePassword();
        case SELECT_TYPE:
            return this.renderSelectWithOptions();
        case EMAIL_TYPE:
            return this.renderTypeEmail();
        case FILE_TYPE:
            return this.renderFile();
        case TELEPHONE_TYPE:
            return this.renderTypeTelephone();
        default:
            return this.renderTypeText();
        }
    }
}

export default FieldComponent;
