import { Field } from 'Util/Query';

const _getSlideFieldsAction = (args, callback) => [
    ...callback(...args),
    new Field('position'),
    new Field('start_time'),
    new Field('end_time'),
    new Field('slide_link'),
    new Field('slide_link_text'),
    new Field('display_title'),
    new Field('slide_text'),
    new Field('embed_code'),
    new Field('mobile_image_2'),
    new Field('desktop_image_2'),
    new Field('embed_code_2'),
    new Field('slide_link_2'),
    new Field('display_title_2'),
    new Field('slide_text_2'),
    new Field('slide_text_position_2'),
    new Field('mobile_image_3'),
    new Field('desktop_image_3'),
    new Field('embed_code_3'),
    new Field('slide_link_3'),
    new Field('display_title_3'),
    new Field('slide_text_3'),
    new Field('slide_text_position_3'),
    new Field('slide_text_position'),
    new Field('slide_width_class')

];

export default {
    'Query/Slider': {
        'member-function': {
            _getSlideFields: _getSlideFieldsAction
        }
    }
};
