export const addRazorpayFields = (args, callback) => [
    ...callback(...args),
    'razorpay_key_id',
    'razorpay_merchant_name_override'
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: addRazorpayFields
        }
    }
};
