/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import React from 'react';

import { Field } from 'Util/Query';

export const HEALTH_POINTS = 'rewards';
export const MyAccountHealthPoints = React.lazy(() => import('../component/MyAccountHealthPoints'));

export class MyAccountPlugin {
    renderMap = (originalMember) => ({
        ...originalMember,
        [ HEALTH_POINTS ]: MyAccountHealthPoints
    });

    propTypes = (originalMember) => ({
        ...originalMember,
        activeTab: PropTypes.oneOfType([
            originalMember.activeTab,
            PropTypes.oneOf([HEALTH_POINTS])
        ])
    });

    tabMap = (originalMember) => ({
        ...originalMember,
        [ HEALTH_POINTS ]: {
            url: '/rewards',
            name: __('My Health Points')
        }
    });

    _getCustomerFields = (args, callback) => [
        ...callback(...args),
        new Field('mstRewardPointsBalance').addFieldList([
            'balance',
            'tier_id',
            this.getTransactionsFields()
        ])
    ];

    getTransactionsFields() {
        return new Field('transactions')
            .addFieldList([
                'activated_at',
                'amount',
                'amount_used',
                'code',
                'comment',
                'is_expired',
                'is_activated',
                'created_at',
                'transaction_id'
            ]);
    }
}

export const {
    tabMap, renderMap, propTypes, _getCustomerFields
} = new MyAccountPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: [
                {
                    position: 101,
                    implementation: renderMap
                }
            ]
        },
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    },
    'Component/MyAccountTabList/Component': {
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: [
                {
                    position: 101,
                    implementation: tabMap
                }
            ]
        }
    },
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields
        }
    }
};
