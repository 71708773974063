/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    QUICK_DELIVERY_PRODUCT_LIST
} from './QuickDeliveryProductList.action';

/** @namespace LifelyScandi/Store/QuickDeliveryProductList/Reducer/getInitialState */
export const getInitialState = () => ({
    items: []
});

/** @namespace LifelyScandi/Store/QuickDeliveryProductList/Reducer/QuickDeliveryProductListReducer */
export const QuickDeliveryProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        items
    } = action;

    switch (type) {
    case QUICK_DELIVERY_PRODUCT_LIST:
        return {
            items
        };

    default:
        return state;
    }
};

export default QuickDeliveryProductListReducer;
