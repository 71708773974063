/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getStoreConfigFieldsAction = (args, callback, instance) => {
    return [
        ...callback(...args),
        new Field('locale'),
        new Field('rewardssocial_facebook_application_id'),
        new Field('rewardssocial_facebook_api_version'),
        new Field('rewardssocial_facebook_is_active'),
        new Field('rewardssocial_facebook_show_fb_share'),
        new Field('rewards_display_options_rewards_logo')
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _getStoreConfigFieldsAction
        }
    }
};