/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SlidingPane from './SlidingPane.component';

/** @namespace LifelyScandi/Component/Custom/SlidingPane/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace LifelyScandi/Component/Custom/SlidingPane/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace LifelyScandi/Component/Custom/SlidingPane/Container/SlidingPaneContainer */
export class SlidingPaneContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // iconClose: this.iconClose.bind(this)
    };

    containerProps = () => {
        const {
            isOpen,
            title,
            subtitle,
            message,
            onRequestClose,
            onButtonClick,
            onAfterOpen,
            onAfterClose,
            children,
            footer,
            className,
            overlayClassName,
            user,
            from,
            width,
            shouldCloseOnEsc,
            hideHeader,
            payableAmount,
            informationText,
            key,
            btnPrimaryDark,
            btnPrimaryBase,
            btnSecondaryDark,
            btnSecondaryBase
        } = this.props;

        return {
            isOpen,
            title,
            subtitle,
            message,
            onRequestClose,
            onButtonClick,
            onAfterOpen,
            onAfterClose,
            children,
            footer,
            className,
            overlayClassName,
            user,
            from,
            width,
            shouldCloseOnEsc,
            hideHeader,
            payableAmount,
            informationText,
            key,
            btnPrimaryDark,
            btnPrimaryBase,
            btnSecondaryDark,
            btnSecondaryBase
        };
    };

    render() {
        return (
            <SlidingPane
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SlidingPaneContainer);
