/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React from 'react';

import { isSignedIn } from 'Util/Auth';

export const RewardPointsApply = React.lazy(() => import('../component/RewardPointsApply'));

const render = (args, callback, instance) => {
    if (isSignedIn()) {
        return (
            <>
                { <RewardPointsApply /> }
                { callback.apply(instance, args) }

            </>
        );
    }

    return (callback.apply(instance, args));
};

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            render: {
                position: 200,
                implementation: render
            }
        }
    }
};
