/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { currentSlidePane, toggleDrawerCart } from '../../../store/custom/DrawerCart/DrawerCart.action';
import LifelyStoryMobile from './LifelyStoryMobile.component';

/** @namespace LifelyScandi/Component/Custom/LifelyStoryMobile/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
    isPaneOpen: _state.DrawerCartReducer.isPaneOpen,
    slidePaneName: _state.DrawerCartReducer.slidePaneName,
    device: _state.ConfigReducer.device
});

/** @namespace LifelyScandi/Component/Custom/LifelyStoryMobile/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    toggleDrawerCart: (type) => _dispatch(toggleDrawerCart(type)),
    currentSlidePane: (slidePaneName) => _dispatch(currentSlidePane(slidePaneName))
});

/** @namespace LifelyScandi/Component/Custom/LifelyStoryMobile/Container/LifelyStoryMobileContainer */
export class LifelyStoryMobileContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        differentName: this.differentName.bind(this),
        dangerousImpact: this.dangerousImpact.bind(this),
        redirectToShopMenu: this.redirectToShopMenu.bind(this)
    };

    differentName() {
        const { toggleDrawerCart, currentSlidePane } = this.props;

        currentSlidePane('sugarVariants');
        toggleDrawerCart(true);
        return true;
    }

    dangerousImpact() {
        const { toggleDrawerCart, currentSlidePane } = this.props;

        currentSlidePane('sugarImpact');
        toggleDrawerCart(true);
        return true;
    }

    redirectToShopMenu() {
        history.push({
            pathname: appendWithStoreCode('/products.html')
        });
    }

    closeSlidePane() {
        const { toggleDrawerCart, currentSlidePane } = this.props;
        currentSlidePane('');
        toggleDrawerCart(false);
        return true;
    }

    containerProps = () => {
        const {
            isPaneOpen, slidePaneName, device
        } = this.props;

        return {
            closeSlidePane: this.closeSlidePane.bind(this),
            isPaneOpen,
            slidePaneName,
            device
        };
    };

    render() {
        return (
            <LifelyStoryMobile
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LifelyStoryMobileContainer);
