/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import { Field } from 'Util/Query';

const _getProductInterfaceFields = (args, callback, instance) => [
    ...callback(...args),
    new Field('description').addFieldList(['html']),
    new Field('short_description').addFieldList(['html'])
];

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields
        }
    }
};
