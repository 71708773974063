import { connect } from 'react-redux';

// import SwipeToDelete from 'Component/SwipeToDelete';
import {
    CartItemContainer as SourceCartItemContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CartItem/CartItem.container';
import { updateShippingPrice } from 'Store/Cart/Cart.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleScroll } from 'Util/Browser';

import { toggleDrawerCart } from '../../store/custom/DrawerCart/DrawerCart.action';
import CartItem from './CartItem.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
/** @namespace LifelyScandi/Component/CartItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isPaneOpen: state.DrawerCartReducer.isPaneOpen
});

/** @namespace LifelyScandi/Component/CartItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleDrawerCart: (type) => dispatch(toggleDrawerCart(type)),
    removeProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeProductFromCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateShippingPrice: (data) => dispatch(updateShippingPrice(data))

});

/** @namespace LifelyScandi/Component/CartItem/Container/CartItemContainer */
export class CartItemContainer extends SourceCartItemContainer {
    componentDidUpdate() {
        const {
            item: { item_id, product: { name, stock_status } }, removeProduct, showNotification
        } = this.props;

        if (stock_status && stock_status === 'OUT_OF_STOCK') {
            removeProduct(item_id);
            showNotification('error', `${ name } is out of stock`);
        }
    }

    containerFunctions = {
        handleChangeQuantity: this.handleChangeQuantity.bind(this),
        handleRemoveItem: this.handleRemoveItem.bind(this),
        getCurrentProduct: this.getCurrentProduct.bind(this),
        getProductVariant: this.getProductVariant.bind(this),
        closeDrawerOnClickItem: this.closeDrawerOnClickItem.bind(this)
    };

    closeDrawerOnClickItem() {
        const { toggleDrawerCart } = this.props;

        toggleDrawerCart(false);
        toggleScroll(true);
    }

    containerProps = () => {
        const {
            item,
            currency_code,
            isEditing,
            isPaneOpen,
            isMobile,
            removeProduct,
            showNotification
        } = this.props;
        const { isLoading } = this.state;

        return {
            item,
            currency_code,
            isEditing,
            isPaneOpen,
            isMobile,
            isLoading,
            linkTo: this._getProductLinkTo(),
            thumbnail: this._getProductThumbnail(),
            minSaleQuantity: this.getMinQuantity(),
            maxSaleQuantity: this.getMaxQuantity(),
            isProductInStock: this.productIsInStock(),
            optionsLabels: this.getConfigurableOptionsLabels(),
            isMobileLayout: this.getIsMobileLayout(),
            removeProduct,
            showNotification
        };
    };

    render() {
        // const { isLoading } = this.state;

        return (
            // <SwipeToDelete
            //   renderRightSideContent={ this.renderRightSideContent }
            //   onAheadOfDragItemRemoveThreshold={ this.containerFunctions.handleRemoveItem }
            //   isLoading={ isLoading }
            // >
            <CartItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
            // </SwipeToDelete>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
