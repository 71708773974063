import ConfigQuery from 'Query/Config.query';

export class StoreSwitcherPlugin {
    _getStoreListAction(args, callback, instance) {
        const { currentStoreCode } = instance.props;
        const currentStoreCountryCode = currentStoreCode.split('_')[ 1 ];
        instance.fetchData(
            [ConfigQuery.getStoreListField()],
            ({ storeList }) => {
                const filteredStoreList = storeList.filter((s) => s.code.indexOf(`_${ currentStoreCountryCode }`) > -1)
                    .map((s) => {
                        const cloned = { ...s };
                        cloned.name = cloned.name.split('-')[ 1 ];
                        return cloned;
                    });

                instance.setState({ storeList: instance._formatStoreList(filteredStoreList) });
            }

        );
    }
}
const { _getStoreListAction } = new StoreSwitcherPlugin();

export const config = {
    'Component/StoreSwitcher/Container': {
        'member-function': {
            _getStoreList: _getStoreListAction
        }

    }
};

export default config;
