import {
    LockIcon as SourceLockIcon
} from 'SourceComponent/LockIcon/LockIcon.component';

import './LockIcon.style';

/** @namespace LifelyScandi/Component/LockIcon/Component/LockIconComponent */
export class LockIconComponent extends SourceLockIcon {
    // TODO implement logic
}

export default LockIconComponent;
