import { Field } from 'Util/Query';

/** @namespace RazorpayPayments/Query/Razorpay/Query/RazorpayQuery */
export class RazorpayQuery {
    getCustomerCart() {
        return new Field('customerCart')
            .addField('id');
    }

    setPaymentMethodOnCart(cartId) {
        return new Field('setPaymentMethodOnCart')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('payment_method', 'PaymentMethodInput!', {
                code: 'razorpay'
            })
            .addField(
                new Field('cart').addField(new Field('selected_payment_method').addField('code'))
            );
    }

    getRzpOrderIdMutation(cartId) {
        return new Field('placeRazorpayOrder')
            .addArgument('cart_id', 'String!', cartId)
            .addField('success')
            .addField('rzp_order_id')
            .addField('message');
    }

    saveRzpResponseMutation(input) {
        return new Field('setRzpPaymentDetailsOnCart')
            .addArgument('input', 'SetRzpPaymentDetailsOnCartInput!', input)
            .addField((new Field('cart')).addField('id'));
    }
}

export default new RazorpayQuery();
