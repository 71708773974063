import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace LifelyScandi/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    url: state.MetaReducer.ogUrl,
    imageUrl: state.MetaReducer.ogImageUrl

    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
    containerProps() {
        const {
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix
        } = this.props;

        return {
            metadata: this._getMetadata(),
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix
        };
    }

    _getUrl() {
        const { url } = this.props;

        return url;
    }

    _getImage() {
        const { imageUrl } = this.props;

        return imageUrl;
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            'og:url': this._getUrl(),
            'og:title': this._getTitle(),
            'og:description': this._getDescription(),
            'og:image': this._getImage()
        };

        return this._generateMetaFromMetadata(meta);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
