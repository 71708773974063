/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { TOGGLE_STICKY_PRODUCT_HEADER } from './ProductDetailStickyHeader.action';

/** @namespace LifelyScandi/Store/Custom/ProductDetailStickyHeader/Reducer/getInitialState */
export const getInitialState = () => ({
    stickyAtTop: true
});

/** @namespace LifelyScandi/Store/Custom/ProductDetailStickyHeader/Reducer/ProductDetailStickyHeaderReducer */
export const ProductDetailStickyHeaderReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_STICKY_PRODUCT_HEADER:
        const { stickyAtTop } = action;
        return {
            ...state,
            stickyAtTop
        };
    default:
        return state;
    }
};

export default ProductDetailStickyHeaderReducer;
