import {
    ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

/** @namespace LifelyScandi/Component/ProductConfigurableAttributes/Container/ProductConfigurableAttributesContainer */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    // TODO implement logic
}

export default ProductConfigurableAttributesContainer;
