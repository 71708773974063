/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import CartItemPrice from 'Component/CartItemPrice';
import Field from 'Component/Field';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import {
    CartItem as SourceCartItem
} from 'SourceComponent/CartItem/CartItem.component';

import DeleteIcon from '../custom/Icons/DeleteIcon';

import './CartItem.style';

/** @namespace LifelyScandi/Component/CartItem/Component/CartItemComponent */
export class CartItemComponent extends SourceCartItem {
    renderQuantity() {
        const { item: { qty } } = this.props;

        return (
            <p
              block="CartItem"
              elem="Quantity"
            >
                { __('Qty: %s', qty) }
            </p>
        );
    }

    renderWrapperContent() {
        const { isEditing, isMobileLayout } = this.props;

        if (isMobileLayout) {
            return isEditing ? this.renderMobileContent() : this.renderMobileSummary();
        }

        return isEditing ? this.renderDesktopContent() : this.renderDesktopSummary();
    }

    renderDesktopSummary() {
        const {
            closeDrawerOnClickItem,
            linkTo = {}
        } = this.props;

        return (
            <div block="CartItem" elem="Wrapper" mods={ { isSummary: true } }>
                <Link to={ linkTo } block="CartItem" elem="Link" onClick={ closeDrawerOnClickItem }>
                    { this.renderImage() }
                </Link>
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo">
                        { this.renderTitle() }
                    </div>
                    <div block="CartItem" elem="ProductActions">
                        { this.renderQuantity() }
                        { this.renderProductPrice() }
                    </div>
                </div>
            </div>
        );
    }

    renderDesktopContent() {
        const {
            closeDrawerOnClickItem,
            linkTo = {}
        } = this.props;

        return (
            <div block="CartItem" elem="Wrapper" mods={ { isCart: true } }>
                <Link to={ linkTo } block="CartItem" elem="Link" onClick={ closeDrawerOnClickItem }>
                    { this.renderImage() }
                </Link>
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo">
                        { this.renderTitle() }
                        { this.renderDeleteButton() }
                    </div>
                    <div block="CartItem" elem="ProductActions">
                        { this.renderQuantityChangeField() }
                        { this.renderProductPrice() }
                    </div>
                </div>
            </div>
        );
    }

    renderMobileSummary() {
        const { isMobileLayout, isProductInStock } = this.props;

        return (
            <div
              block="CartItem"
              elem="Wrapper"
              mods={ { isMobileLayout, isProductOutOfStock: !isProductInStock, isSummary: true } }
            >
                { this.renderImage() }
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo" mods={ { isMobileLayout } }>
                        { this.renderTitle() }
                        { this.renderDeleteButton() }
                    </div>
                    <div block="CartItem" elem="ProductActions" mods={ { isMobileLayout } }>
                        { this.renderProductPrice() }
                        { this.renderQuantity() }
                    </div>
                </div>
            </div>
        );
    }

    renderMobileContent() {
        const { isMobileLayout, isProductInStock } = this.props;

        return (
            <div
              block="CartItem"
              elem="Wrapper"
              mods={ { isMobileLayout, isProductOutOfStock: !isProductInStock, isCart: true } }
            >
                { this.renderImage() }
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo" mods={ { isMobileLayout } }>
                        { this.renderTitle() }
                        { this.renderDeleteButton() }
                    </div>
                    <div block="CartItem" elem="ProductActions" mods={ { isMobileLayout } }>
                        { this.renderProductPrice() }
                        { this.renderQuantityChangeField() }
                    </div>
                </div>
            </div>
        );
    }

    renderOutOfStockMessage() {
        const { isProductInStock } = this.props;

        if (isProductInStock) {
            return null;
        }

        return (
            <p block="CartItem" elem="OutOfStock">
                (
                { ' ' }
                { __('Product is out of stock') }
                { ' ' }
                )
            </p>
        );
    }

    renderProductName() {
        const {
            item: {
                product: {
                    name,
                    sku,
                    weight
                }
            }
        } = this.props;

        return (
            <div block="CartItem" elem="CartItemInfo">
                <p
                  block="CartItem"
                  elem="Heading"
                >
                    { name }
                </p>
                { weight && (
                    <>
                        { sku.toLowerCase().indexOf('icer-') !== -1 ? `(${ weight }ml)` : (
                            (
                                <p block="CartItem" elem="Weight">
                                    (
                                    { weight < 1000 ? `${ weight }gm` : `${ weight }kg` }
                                    )
                                </p>
                            )
                        ) }
                    </>
                ) }

            </div>
        );
    }

    renderTitle() {
        const {
            isMobileLayout,
            closeDrawerOnClickItem,
            linkTo = {},
            item: {
                customizable_options,
                bundle_options,
                downloadable_links
            } = {}
        } = this.props;

        return (
            <Link
              to={ linkTo }
              block="CartItem"
              elem="Title"
              mods={ { isMobileLayout } }
              onClick={ closeDrawerOnClickItem }
            >
                { this.renderProductName() }
                { this.renderOutOfStockMessage() }
                { this.renderProductConfigurations() }
                { this.renderProductOptions(customizable_options) }
                { this.renderProductOptions(bundle_options) }
                { this.renderProductLinks(downloadable_links) }
            </Link>
        );
    }

    renderContent() {
        const { linkTo = {}, isProductInStock, isMobile } = this.props;

        if (!isProductInStock || Object.keys(linkTo).length === 0 || isMobile) {
            return (
                <span block="CartItem" elem="Link">
                    { this.renderWrapperContent() }
                </span>
            );
        }

        return (this.renderWrapperContent());
    }

    renderDeleteButton() {
        const { handleRemoveItem, isMobileLayout } = this.props;

        if (location.pathname.toLowerCase().indexOf('/checkout') !== -1) {
            return null;
        }

        return (
            <button
              block="CartItem"
              id="RemoveItem"
              name="RemoveItem"
              elem="Delete"
              mods={ { isMobileLayout } }
              aria-label="Remove item from cart"
              onClick={ handleRemoveItem }
            >
                <DeleteIcon width="20" height="20" />
            </button>
        );
    }

    renderProductPrice() {
        const {
            currency_code,
            item: {
                row_total,
                row_total_incl_tax
            },
            isPaneOpen,
            isMobileLayout
        } = this.props;

        return (
            <CartItemPrice
              row_total={ row_total }
              row_total_incl_tax={ row_total_incl_tax }
              currency_code={ currency_code }
              mix={ {
                  block: 'CartItem',
                  elem: 'Price',
                  mods: { isPaneOpen, isMobileLayout }
              } }
            />
        );
    }

    quantityClickHandler(e) {
        e.preventDefault();
    }

    renderQuantityChangeField() {
        const {
            item: { qty },
            minSaleQuantity,
            maxSaleQuantity,
            handleChangeQuantity,
            isProductInStock,
            isPaneOpen
        } = this.props;

        if (!isProductInStock) {
            return null;
        }

        return (
            <div
              block="CartItem"
              elem="QuantityWrapper"
              aria-label="Quantity"
              mods={ { isPaneOpen } }
              onClick={ this.quantityClickHandler }
              onKeyDown={ this.quantityClickHandler }
              role="button"
              tabIndex="-1"
            >
                <Field
                  id="item_qty"
                  name="item_qty"
                  type="number"
                  isControlled
                  min={ minSaleQuantity }
                  max={ maxSaleQuantity }
                  mix={ { block: 'CartItem', elem: 'Qty' } }
                  value={ qty }
                  onChange={ handleChangeQuantity }
                />
            </div>
        );
    }

    renderImage() {
        const { linkTo, isMobile } = this.props;
        if (isMobile) {
            return (
                <Link to={ linkTo } block="CartItem" elem="Link">
                    { this.renderImageElement() }
                </Link>
            );
        }

        return this.renderImageElement();
    }

    render() {
        const {
            isLoading, isEditing, isPaneOpen
        } = this.props;

        return (
            <div block="CartItem" mods={ { isEditing, isPaneOpen } }>
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </div>
        );
    }
}

export default CartItemComponent;
