import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.style';

/** @namespace LifelyScandi/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributesComponent */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    // TODO implement logic
}

export default ProductConfigurableAttributesComponent;
