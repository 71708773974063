/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import EditWithoutFrameIcon from '../../../../../../src/component/custom/Icons/EditWithoutFrameIcon';
import UpdateMobilePopup from '../UpdateMobilePopup';
import { UPDATE_MOBILE_POPUP_ID } from '../UpdateMobilePopup/UpdateMobilePopup.config';

import './UpdateMobileno.style';

/** @namespace Lifely/UpdateMobileno/Component/UpdateMobileno/Component/UpdateMobilenoComponent */
export class UpdateMobilenoComponent extends PureComponent {
    static propTypes = {
        showUpdateMobilePopup: PropTypes.func.isRequired
    };

    renderUpdateMobileBtn() {
        const { showUpdateMobilePopup } = this.props;

        return (
            <button
              id="updatemobilecustomer"
              block="UpdateMobileno"
              elem="UpdateMobileNoPopUpBtn"
              onClick={ showUpdateMobilePopup }
              type="button"
            >
                <EditWithoutFrameIcon fill="rgb(146,62,22)" width="16" height="16" />
            </button>
        );
    }

    renderUpdateMobilePopup() {
        return (
            <Popup
              id={ UPDATE_MOBILE_POPUP_ID }
              mix={ { block: 'UpdateMobilePopup' } }
            >
                <UpdateMobilePopup />
            </Popup>
        );
    }

    render() {
        return (
            <>
                { this.renderUpdateMobileBtn() }
                { this.renderUpdateMobilePopup() }
            </>
        );
    }
}
export default UpdateMobilenoComponent;
