import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';

/** @namespace LifelyScandi/Component/ProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/ProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/ProductLinks/Container/ProductLinksContainer */
export class ProductLinksContainer extends SourceProductLinksContainer {
    static defaultProps = {
        numberOfProductsToDisplay: 4,
        areDetailsLoaded: true,
        isCartPageLinkedProduct: false
    };

    containerProps() {
        const {
            areDetailsLoaded,
            linkType,
            linkedProducts,
            numberOfProductsToDisplay,
            title,
            isCartPageLinkedProduct
        } = this.props;

        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;

        return {
            areDetailsLoaded,
            linkType,
            linkedProducts,
            numberOfProductsToDisplay,
            title,
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            isCartPageLinkedProduct
        };
    }
}

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
