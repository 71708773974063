// import { Field } from 'Util/Query';
import { Field } from 'Util/Query';

/** @namespace LifelyScandi/Query/CustomVariable/Query/CustomVariableQuery */
export class CustomVariableQuery {
    getCustomVariableQuery(code) {
        const query = new Field('variable')
            .addFieldList(['plain', 'html'])
            .addArgument('code', 'String', code);

        return query;
    }
}

export default new CustomVariableQuery();
