import { showNotification } from 'Store/Notification/Notification.action';
import { toggleScroll } from 'Util/Browser';

import { currentSlidePane, toggleDrawerCart } from '../../store/custom/DrawerCart/DrawerCart.action';

export class AddToCartContainerPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;
        return {
            ...callback(...args),
            isMobile: state.ConfigReducer.device.isMobile
        };
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;
        return {
            ...callback(...args),
            toggleDrawerCart: (type) => dispatch(toggleDrawerCart(type)),
            currentSlidePane: (slidePaneName) => dispatch(currentSlidePane(slidePaneName)),
            showNotification: (type, message) => dispatch(showNotification(type, message))
        };
    };

    aroundContainerProps(args, callback, instance) {
        const { isMobile } = instance.props;
        return { ...callback.apply(instance, args), isMobile };
    }

    afterAddToCartActions = (args, callback, instance) => {
        const {
            setQuantityToDefault,
            isMobile, toggleDrawerCart, currentSlidePane, showNotification
        } = instance.props;

        setQuantityToDefault();
        instance.removeProductFromWishlist();
        instance.setState({ isLoading: false });
        showNotification('success', 'Item Added to Cart Successfully');
        if (!isMobile) {
            currentSlidePane('cart');
            toggleDrawerCart(true);
            toggleScroll(false);
        }
    };
}
export const {
    afterAddToCartActions, aroundContainerProps, mapStateToProps, mapDispatchToProps
} = new AddToCartContainerPlugin();
export default {
    'Component/AddToCart/Container': {
        'member-function': {
            afterAddToCart: [
                {
                    position: 100,
                    implementation: afterAddToCartActions
                }
            ],
            containerProps: aroundContainerProps
        }
    },
    'Component/AddToCart/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/AddToCart/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
