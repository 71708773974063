/* eslint-disable max-len */
import PropTypes from 'prop-types';

import {
    CartIcon as SourceCartIcon
} from 'SourceComponent/CartIcon/CartIcon.component';

import './CartIcon.style';

/** @namespace LifelyScandi/Component/CartIcon/Component/CartIconComponent */
export class CartIconComponent extends SourceCartIcon {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <>
                { !isActive && (
                    <svg
                      block="CartIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 37.5 37.499999"
                      zoomAndPan="magnify"
                      preserveAspectRatio="xMidYMid meet"
                      version="1.0"
                    >
                            <defs>
                                <clipPath id="clip-0">
                                <path d="M 3.011719 10.039062 L 34.511719 10.039062 L 34.511719 37.007812 L 3.011719 37.007812 Z M 3.011719 10.039062 " clipRule="nonzero" />
                                </clipPath>
                                <clipPath id="clip-1">
                                <path d="M 9 0.367188 L 28 0.367188 L 28 18 L 9 18 Z M 9 0.367188 " clipRule="nonzero" />
                                </clipPath>
                            </defs>
                            <g clipPath="url(#clip-0)">
                                <path fill="rgb(45.098877%, 45.098877%, 45.098877%)" d="M 5.71875 10.070312 L 31.8125 10.070312 C 32.121094 10.070312 32.371094 10.316406 32.386719 10.621094 L 34.511719 36.5 C 34.539062 36.816406 34.300781 37.09375 33.984375 37.121094 C 33.96875 37.121094 33.953125 37.121094 33.9375 37.121094 L 3.585938 37.121094 C 3.269531 37.121094 3.011719 36.867188 3.011719 36.546875 C 3.011719 36.523438 3.011719 36.5 3.015625 36.476562 L 5.140625 10.601562 L 5.714844 10.644531 L 5.140625 10.597656 C 5.164062 10.296875 5.421875 10.066406 5.71875 10.070312 Z M 31.28125 11.21875 L 6.242188 11.21875 L 4.210938 35.972656 L 33.316406 35.972656 L 31.28125 11.21875 " fillOpacity="1" fillRule="nonzero" />
                            </g>
                            <path fill="rgb(45.098877%, 45.098877%, 45.098877%)" d="M 27.15625 14.96875 C 27.777344 14.96875 28.277344 15.472656 28.277344 16.089844 C 28.277344 16.710938 27.777344 17.210938 27.15625 17.210938 C 26.539062 17.210938 26.035156 16.710938 26.035156 16.089844 C 26.035156 15.472656 26.539062 14.96875 27.15625 14.96875 " fillOpacity="1" fillRule="evenodd" />
                            <path fill="rgb(45.098877%, 45.098877%, 45.098877%)" d="M 10.367188 14.96875 C 10.988281 14.96875 11.488281 15.472656 11.488281 16.089844 C 11.488281 16.710938 10.988281 17.210938 10.367188 17.210938 C 9.75 17.210938 9.246094 16.710938 9.246094 16.089844 C 9.246094 15.472656 9.75 14.96875 10.367188 14.96875 " fillOpacity="1" fillRule="evenodd" />
                            <g clipPath="url(#clip-1)">
                                <path fill="rgb(45.098877%, 45.098877%, 45.098877%)" d="M 9.585938 16.542969 C 9.585938 16.859375 9.84375 17.117188 10.160156 17.117188 C 10.476562 17.117188 10.734375 16.859375 10.734375 16.542969 C 10.734375 9.257812 12.839844 4.625 15.535156 2.625 C 16.53125 1.886719 17.609375 1.515625 18.679688 1.515625 C 19.75 1.515625 20.828125 1.886719 21.824219 2.625 C 24.519531 4.625 26.625 9.257812 26.625 16.542969 C 26.625 16.859375 26.882812 17.117188 27.199219 17.117188 C 27.515625 17.117188 27.773438 16.859375 27.773438 16.542969 C 27.773438 8.855469 25.460938 3.902344 22.503906 1.707031 C 21.304688 0.816406 19.992188 0.367188 18.679688 0.367188 C 17.363281 0.367188 16.054688 0.816406 14.851562 1.707031 C 11.894531 3.902344 9.585938 8.855469 9.585938 16.542969 " fillOpacity="1" fillRule="nonzero" />
                            </g>
                    </svg>
                ) }
                { isActive && (
                    <svg
                      block="CartIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 37.5 37.499999"
                      zoomAndPan="magnify"
                      mods={ { isActive } }
                      preserveAspectRatio="xMidYMid meet"
                      version="1.0"
                    >
                            <defs>
                                <clipPath id="clipCartIcon1">
                                    <path d="M 2.875 0.289062 L 34.375 0.289062 L 34.375 37.007812 L 2.875 37.007812 Z M 2.875 0.289062 " clipRule="nonzero" />
                                </clipPath>
                            </defs>
                            <g clipPath="url(#clipCartIcon1)">
                                <path fill="rgb(72.938538%, 51.759338%, 8.628845%)" d="M 32.359375 10.308594 C 32.359375 10.164062 32.214844 10.050781 32.074219 10.050781 L 26.921875 10.050781 L 26.921875 8.613281 C 26.921875 4.019531 23.203125 0.289062 18.625 0.289062 C 14.046875 0.289062 10.324219 4.019531 10.324219 8.613281 L 10.324219 10.050781 L 5.175781 10.050781 C 5.03125 10.050781 4.886719 10.164062 4.886719 10.308594 L 2.886719 36.722656 C 2.886719 36.808594 2.914062 36.894531 2.972656 36.953125 C 3.027344 37.011719 3.113281 37.039062 3.171875 37.039062 L 34.074219 37.039062 C 34.160156 37.039062 34.21875 37.011719 34.277344 36.953125 C 34.332031 36.894531 34.363281 36.808594 34.363281 36.722656 Z M 18.625 2.585938 C 21.941406 2.585938 24.632812 5.285156 24.632812 8.613281 L 24.632812 10.050781 L 12.613281 10.050781 L 12.613281 8.613281 C 12.613281 5.285156 15.304688 2.585938 18.625 2.585938 Z M 11.46875 18.089844 C 9.894531 18.089844 8.609375 16.796875 8.609375 15.21875 C 8.609375 14.070312 9.179688 13.035156 10.324219 12.578125 L 10.324219 15.21875 C 10.324219 15.851562 10.839844 16.367188 11.46875 16.367188 C 12.097656 16.367188 12.613281 15.851562 12.613281 15.21875 L 12.613281 12.578125 C 13.757812 13.035156 14.332031 14.070312 14.332031 15.21875 C 14.332031 16.796875 13.042969 18.089844 11.46875 18.089844 Z M 25.777344 18.089844 C 24.203125 18.089844 22.914062 16.796875 22.914062 15.21875 C 22.914062 14.070312 23.488281 13.035156 24.632812 12.578125 L 24.632812 15.21875 C 24.632812 15.851562 25.148438 16.367188 25.777344 16.367188 C 26.40625 16.367188 26.921875 15.851562 26.921875 15.21875 L 26.921875 12.578125 C 28.066406 13.035156 28.640625 14.070312 28.640625 15.21875 C 28.640625 16.796875 27.351562 18.089844 25.777344 18.089844 Z M 25.777344 18.089844 " fillOpacity="1" fillRule="nonzero" />
                            </g>
                    </svg>
                ) }
                <span block="CartIcon" elem="Label" mods={ { isActive } }>Cart</span>
            </>
        );
    }
}

export default CartIconComponent;
