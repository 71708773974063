import { isSignedIn } from 'Util/Auth';
import { toggleScroll } from 'Util/Browser';
import { getErrorMessage } from 'Util/Request';

import { currentSlidePane, toggleDrawerCart } from '../../store/custom/DrawerCart/DrawerCart.action';

export class MyAccountMyWishlistContainerPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;
        return {
            ...callback(...args),
            isMobile: state.ConfigReducer.device.isMobile
        };
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;
        return {
            ...callback(...args),
            toggleDrawerCart: (type) => dispatch(toggleDrawerCart(type)),
            currentSlidePane: (slidePaneName) => dispatch(currentSlidePane(slidePaneName))
        };
    };

    aroundContainerProps(args, callback, instance) {
        const { isMobile } = instance.props;
        return { ...callback.apply(instance, args), isMobile };
    }

    addAllToCartActions = (callback, args, instance) => {
        const {
            moveWishlistToCart, toggleDrawerCart, isMobile, currentSlidePane
        } = instance.props;

        if (!isSignedIn()) {
            return null;
        }

        instance.setState({ isLoading: true });

        return moveWishlistToCart().then(
            /** @namespace Component/MyAccountMyWishlist/Container/moveWishlistToCartThen */
            () => {
                if (!isMobile) {
                    currentSlidePane('cart');
                    toggleDrawerCart(true);
                    toggleScroll(false);
                } else {
                    instance.showNotificationAndRemoveLoading('Available items moved to cart');
                }
            },
            /** @namespace Component/MyAccountMyWishlist/Container/moveWishlistToCartCatch */
            (error) => instance.showErrorAndRemoveLoading(getErrorMessage(error))
        );
    };
}

export const {
    addAllToCartActions, aroundContainerProps, mapStateToProps, mapDispatchToProps
} = new MyAccountMyWishlistContainerPlugin();
export default {
    'Component/MyAccountMyWishlist/Container': {
        'member-function': {
            addAllToCart: [
                {
                    position: 100,
                    implementation: addAllToCartActions
                }
            ],
            containerProps: aroundContainerProps
        }
    },
    'Component/MyAccountMyWishlist/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/MyAccountMyWishlist/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
