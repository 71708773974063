/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const ACCOUNT_SIGNUP_OTP = '/account/signup/';

export const ACCOUNT_SIGNUP_OTP_REFERRAL_CODE = '/account/signup/:referral_code';

export const ACCOUNT_SIGNIN_OTP = '/account/login/';

export const ACCOUNT_SIGNIN_PASSWORD = '/account/signinpassword';

export const FORGOT_PASSWORD_PHONE = '/account/forgotphone';

export const FORGOT_PASSWORD_EMAIL = '/account/forgotemail';

export const RESET_PASSWORD = '/account/resetpassword';
