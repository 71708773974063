/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrongContainer
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';
import history from 'Util/History';
import { setQueryParams } from 'Util/Url';

/** @namespace LifelyScandi/Route/SomethingWentWrong/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Route/SomethingWentWrong/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Route/SomethingWentWrong/Container/SomethingWentWrongContainer */
export class SomethingWentWrongContainer extends SourceSomethingWentWrongContainer {
    componentDidMount() {
        const { updateMeta } = this.props;

        updateMeta({ title: __('Something went wrong!') });
        if (process.env.NODE_ENV === 'production') {
            if (!location.search.includes('attempt')) {
                setQueryParams({ attempt: 1 }, location, history);
                return window.location.reload();
            }
            if (location.search.includes('attempt=')) {
                return false;
            }
        }

        return true;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
