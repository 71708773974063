/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const IS_LOADING = 'IS_LOADING';
export const REWARD_POINTS = 'REWARD_POINTS';
export const REWARD_TIRES = 'REWARD_TIRES';
export const REMAINING_POINT = 'REMAINING_POINT';
export const CURRENT_TIER = 'CURRENT_TIER';
export const NEXT_TIER = 'NEXT_TIER';
export const CART_ID = 'CART_ID';
export const REWARDS_REFERRALS = 'REWARDS_REFERRALS';
export const SHARE_LINK = 'SHARE_LINK';

export const isLoading = (loading) => ({
    type: IS_LOADING,
    loading
});

export const rewardPoints = (rewardPoints) => ({
    type: REWARD_POINTS,
    rewardPoints
});

export const rewardTires = (rewardTires) => ({
    type: REWARD_TIRES,
    rewardTires
});

export const remainingPoint = (remainingPoint) => ({
    type: REMAINING_POINT,
    remainingPoint
});

export const currentTier = (currentTier) => ({
    type: CURRENT_TIER,
    currentTier
});

export const nextTier = (nextTier) => ({
    type: NEXT_TIER,
    nextTier
});

export const cartId = (cartId) => ({
    type: CART_ID,
    cartId
});

export const rewardsReferrals = (rewardsReferrals) => ({
    type: REWARDS_REFERRALS,
    rewardsReferrals
});

export const shareLink = (shareLink) => ({
    type: SHARE_LINK,
    shareLink
});



