import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';
import media from 'Util/Media';

import './Loader.style';

/** @namespace LifelyScandi/Component/Loader/Component/LoaderComponent */
export class LoaderComponent extends SourceLoader {
    // TODO implement logic
    renderMain() {
        return (
            <img block="Loader" elem="Image" alt="loader" src={ media('assets/loader.svg') } />
        );
    }
}

export default LoaderComponent;
