/* eslint-disable max-len */
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';

/** @namespace LifelyScandi/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/Menu/Container/MenuContainer */
export class MenuContainer extends SourceMenuContainer {
    state = {
        firstLevelItemId: '2'
    };

    containerProps() {
        const { device } = this.props;
        const { activeMenuItemsStack, menu, firstLevelItemId } = this.state;

        return {
            activeMenuItemsStack,
            menu,
            device,
            topLevelItemBtnAction: this.topLevelItemBtnAction.bind(this),
            firstLevelItemId
        };
    }

    topLevelItemBtnAction(firstLevelItem) {
        const { item_id } = firstLevelItem;
        this.setState({ firstLevelItemId: item_id });
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuContainer)
);
