/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PureComponent } from 'react';
import Countdown from 'react-countdown';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2';

import Form from 'Component/Form';

import EditIcon from '../../../../../../src/component/custom/Icons/EditIcon';

import 'react-phone-input-2/lib/style.css';
import './UpdateMobilePopup.style';

/** @namespace Lifely/UpdateMobileno/Component/UpdateMobilePopup/Component/UpdateMobilePopupComponent */
export class UpdateMobilePopupComponent extends PureComponent {
    renderMobileNumberField() {
        const {
            newMobileNumber, setMobile, verifyOtp, default_country, device
        } = this.props;
        let readonlyClass = '';
        if (verifyOtp) {
            readonlyClass = 'readonly';
        }

        return (
            <div block="control" className={ readonlyClass }>
                { /* <PhoneInput
                  country={ default_country.toLowerCase() }
                  value={ newMobileNumber }
                  onChange={ setMobile }
                  countryCodeEditable={ false }
                /> */ }
                <PhoneInput
                  inputStyle={ {
                      backgroundColor: device.isMobile
                          ? 'rgb(209,214,194)'
                          : 'white',
                      borderRadius: '10px'
                  } }
                  placeholder="Enter phone number"
                  country={ newMobileNumber === '' ? default_country.toLowerCase() : '' }
                  value={ newMobileNumber }
                  onChange={ setMobile }
                  countryCodeEditable={ false }
                    //   masks={ { in: '(...)-...-....' } }
                  specialLabel="Phone number"
                  inputProps={ {
                      name: 'phone',
                      required: true,
                      autoFocus: true
                  } }
                />
            </div>
        );
    }

    renderChangeNumberBtn() {
        const { verifyOtp, numberOnChangeClick } = this.props;
        if (verifyOtp) {
            return (
                <div block="Change" elem="Number">
                    <button
                      block="Button"
                      type="button"
                      mods={ { likeLink: true } }
                      mix={ { block: 'change' } }
                      onClick={ numberOnChangeClick }
                    >
                        <EditIcon fill="#737373" />
                    </button>
                </div>
            );
        }
    }

    renderResendOtp() {
        const {
            verifyOtp, handleOTPInputChange, otp, hasError
        } = this.props;

        if (verifyOtp) {
            return (
                <div block="Mobile" elem="OtpField">
                    <div block="Mobile" elem="OtpFieldLabel">Enter OTP</div>
                    <OtpInput
                      isInputNum
                      placeholder="000000"
                      shouldAutoFocus
                      hasErrored={ hasError }
                      errorStyle="otpInputErrorUpdateMob"
                      containerStyle="otpInputContainerUpdateMob"
                      inputStyle="otpInputUpdateMob"
                      focusStyle="otpInputFocusUpdateMob"
                      value={ otp }
                      onChange={ handleOTPInputChange }
                      numInputs={ 6 }
                      separator={ <span>&nbsp;</span> }
                    />
                    <div block="Mobile" elem="otpResend">
                        { this.resendOtp() }
                    </div>
                </div>
            );
        }
    }

    resendOtp() {
        const { reSendOtpBtn } = this.props;

        const renderer = ({ minutes, seconds, completed }) => {
            if (completed) {
                return (
                    <button
                      type="button"
                      mods={ { likeLink: true } }
                      block="Button"
                      mix={ { block: 'Resend', elem: 'Count' } }
                      onClick={ reSendOtpBtn }
                    >
                        { __('Resend Otp') }
                    </button>
                );
            }

            return (
                <div block="Resend" elem="Count">
                    { `${ __('Resend in: ') + minutes } : ${ seconds }` }
                </div>
            );
        };

        return <Countdown date={ Date.now() + 120000 } renderer={ renderer } />;
    }

    renderRequestBtn() {
        const { verifyOtp } = this.props;
        let btnTitle = __('Request OTP');
        if (verifyOtp) {
            btnTitle = __('Verify & Update');
        }

        return (
            <div block="UpdateMobileNumber" elem="Actions">
                <button
                  id="requestOtp"
                  block="UpdateMobileNumber"
                  elem="OtpSendVerify"
                  type="submit"
                  className="ripple"
                >
                    { btnTitle }
                </button>
            </div>
        );
    }

    resendUpdateMobileFields() {
        const { submitBtnClick, isLoading } = this.props;
        return (
            <Form
              onSubmitSuccess={ submitBtnClick }
              mix={ { block: 'UpdateMobileNumber' } }
            >
                <div
                  block="UpdateMobileNumber"
                  elem="Fieldset"
                  mix={ { block: 'UpdateMobileNumber', elem: 'Fieldset' } }
                  mods={ { isLoading } }
                >
                    <div block="MobileOtp" elem="Field">
                        <div block="Mobile" elem="Field">
                            { this.renderMobileNumberField() }
                            { this.renderChangeNumberBtn() }
                        </div>
                        { this.renderResendOtp() }
                    </div>
                </div>
                { this.renderRequestBtn() }
            </Form>
        );
    }

    renderFormWrapper() {
        return (
            <div block="UpdateMobileNumber" elem="FormWrapper">
                { this.resendUpdateMobileFields() }
            </div>
        );
    }

    render() {
        return (
            <div
              block="UpdateMobileNumber"
              elem="InnerWrapper"
              mix={ { block: 'UpdateMobileNo' } }
            >
                { this.renderFormWrapper() }
            </div>
        );
    }
}
export default UpdateMobilePopupComponent;
