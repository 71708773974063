/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Html as SourceHtml,
    WidgetFactory as SourceWidgetFactory
} from 'SourceComponent/Html/Html.component';

import FeatureWidget from '../custom/FeatureWidget';

import './Html.style';

// TODO: implement WidgetFactory
export const WidgetFactory = SourceWidgetFactory;

/** @namespace LifelyScandi/Component/Html/Component/HtmlComponent */
export class HtmlComponent extends SourceHtml {
    rules = [
        {
            query: { name: ['custom-feature'] },
            replace: this.replaceCustomFeature
        },
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        }
    ];

    replaceCustomFeature({ attribs }) {
        const { name } = attribs;
        return (
            <FeatureWidget name={ name } />
        );
    }
}

export default HtmlComponent;
