import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

/** @namespace LifelyScandi/Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace LifelyScandi/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/ProductWishlistButton/Container/ProductWishlistButtonContainer */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
