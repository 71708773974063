import PropTypes from 'prop-types';

import {
    MinusIcon as SourceMinusIcon
} from 'SourceComponent/MinusIcon/MinusIcon.component';

import './MinusIcon.style';

/** @namespace LifelyScandi/Component/MinusIcon/Component/MinusIconComponent */
export class MinusIconComponent extends SourceMinusIcon {
    static propTypes = {
        fill: PropTypes.string.isRequired,
        height: PropTypes.bool.isRequired,
        width: PropTypes.bool.isRequired
    };

    static defaultProps = {
        fill: 'black',
        height: '20',
        width: '20'
    };

    render() {
        const {
            fill, height, width
        } = this.props;

        return (
            <svg
              block="MinusIcon"
              fill={ fill }
              width={ width }
              height={ height }
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5 11H19V13H5V11Z" />
            </svg>
        );
    }
}

export default MinusIconComponent;
