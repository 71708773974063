import PropTypes from 'prop-types';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';

/** @namespace LifelyScandi/Plugin/SliderWidget/Pluginn/SliderWidgetPluginn */
export class SliderWidgetPlugin {
    aroundPropTypes = (originalMember) => ({
        ...originalMember,
        slider: PropTypes.shape({
            title: PropTypes.string,
            slideSpeed: PropTypes.number,
            slides: PropTypes.arrayOf(
                PropTypes.shape({
                    desktop_image: PropTypes.string,
                    mobile_image: PropTypes.string,
                    slide_text: PropTypes.string,
                    isPlaceholder: PropTypes.bool,
                    slide_link: PropTypes.string
                })
            )
        })
    });

    renderSlide = (args, callback, instance) => {
        const _slide = { ...args[ 0 ] };
        // const _i = { ...args[ 1 ] };
        const _i = Math.random();
        const {
            slide_text,
            isPlaceholder,
            title: block,
            slide_link
        } = _slide;

        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ _i }
            >
                <Link
                  to={ slide_link }
                  aria-label="Shop Now"
                  key="slide"
                >

                    <Image
                      mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                      ratio="custom"
                      src={ instance.getSlideImage(_slide) }
                      isPlaceholder={ isPlaceholder }
                    />
                </Link>
                <figcaption
                  block="SliderWidget"
                  elem="Figcaption"
                  mix={ { block } }
                >
                    <Html content={ slide_text || '' } />
                </figcaption>
            </figure>
        );
    };
}

export const { aroundPropTypes, renderSlide } = new SliderWidgetPlugin();

export default {
    'Component/SliderWidget/Component': {
        'static-member': {
            propTypes: [
                {
                    position: 100,
                    implementation: aroundPropTypes
                }
            ]
        },
        'member-function': {
            renderSlide: {
                position: 100,
                implementation: renderSlide
            }
        }
    }

};
