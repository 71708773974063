export const HEALTH_POINTS = 'rewards';
export class MenuPlugin {
    _getMenuItemFields = (args, callback) => [
        ...callback(...args),
        'icon_alt'
    ];
}
/* eslint-disable-next-line */
export const {
    _getMenuItemFields
} = new MenuPlugin();

export default {
    'Query/Menu': {
        'member-function': {
            _getMenuItemFields
        }
    }
};
