/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MobileMenuPanel from './MobileMenuPanel.component';

/** @namespace LifelyScandi/Component/Custom/MobileMenuPanel/Container/MobileMenuPanelContainer */
export class MobileMenuPanelContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        const {
            key,
            activateTab,
            activeTab,
            index,
            panel
        } = this.props;

        return {
            key,
            activeTab,
            activateTab,
            index,
            panel
        };
    };

    render() {
        return (
            <MobileMenuPanel
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default MobileMenuPanelContainer;
