import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutOrderSummary/CheckoutOrderSummary.container';

import CheckoutOrderSummary from './CheckoutOrderSummary.component';

/** @namespace LifelyScandi/Component/CheckoutOrderSummary/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile

});

/** @namespace LifelyScandi/Component/CheckoutOrderSummary/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutOrderSummary);
