/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { TOGGLE_STICKY_HEADER } from './Header.action';

/** @namespace LifelyScandi/Store/Header/Reducer/getInitialState */
export const getInitialState = () => ({
    atTop: true,
    itemCount: 0
});

/** @namespace LifelyScandi/Store/Header/Reducer/StickyHeaderReducer */
export const StickyHeaderReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_STICKY_HEADER:
        const { atTop } = action;
        return {
            ...state,
            atTop
        };
    default:
        return state;
    }
};

export default StickyHeaderReducer;
