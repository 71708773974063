/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';

import { isVerifyOtp } from '../../store/UpdateMobile/UpdateMobile.action';
import UpdateMobilePopup from './UpdateMobilePopup.component';

export const UpdateMobileDispatcher = import(
    '../../store/UpdateMobile/UpdateMobile.dispatcher'
);

/** @namespace Lifely/UpdateMobileno/Component/UpdateMobilePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    verifyOtp: state.UpdateMobileReducer.verifyOtp,
    default_country: state.ConfigReducer.default_country,
    customerId: state.MyAccountReducer.customer.id,
    website_id: state.ConfigReducer.website_id,
    mobileNumber: state.MyAccountReducer.customer.mobileNumber,
    newMobileNumber: state.UpdateMobileReducer.newMobileNumber
});
/** @namespace Lifely/UpdateMobileno/Component/UpdateMobilePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMobileOTP: (input) => UpdateMobileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateMobileOTP(dispatch, input)
    ),
    isVerifyOtp: (value) => dispatch(isVerifyOtp(value)),
    updateMobileOTPVerify: (input) => UpdateMobileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateMobileOTPVerify(dispatch, input)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    newMobileNumberFun: (value) => UpdateMobileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.newMobileNumber(dispatch, value)
    )
});
/** @namespace Lifely/UpdateMobileno/Component/UpdateMobilePopup/Container/UpdateMobilePopupContainer */
export class UpdateMobilePopupContainer extends PureComponent {
    containerFunctions = {
        setMobile: this.setMobile.bind(this),
        numberOnChangeClick: this.numberOnChangeClick.bind(this),
        submitBtnClick: this.submitBtnClick.bind(this),
        handleOTPInputChange: this.handleOTPInputChange.bind(this),
        reSendOtpBtn: this.reSendOtpBtn.bind(this)
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            mobile: '',
            resendOtp: false,
            otp: '',
            hasError: false
        };
        const { isVerifyOtp } = this.props;
        isVerifyOtp(false);
    }

    setMobile(value) {
        const { newMobileNumberFun } = this.props;
        this.setState({ mobile: value });
        newMobileNumberFun(value);
    }

    handleOTPInputChange(value) {
        this.setState({ otp: value });
    }

    async numberOnChangeClick() {
        const { newMobileNumberFun } = this.props;
        this.setState({ mobile: '' });
        newMobileNumberFun('');
        this.setState({ otp: '' });
        await document.querySelector('#updatemobilecustomer').click();
        await document.querySelector('#updatemobilecustomer').click();
    }

    async reSendOtpBtn() {
        this.setState({ otp: '' });
        await document.querySelector('#updatemobilecustomer').click();
        await document.querySelector('#updatemobilecustomer').click();
        await this.callRequestOtp();
    }

    getMobileNumber() {
        const { newMobileNumber } = this.props;
        return newMobileNumber;
    }

    submitBtnClick() {
        const { verifyOtp } = this.props;

        // Request Otp
        if (!verifyOtp) {
            this.callRequestOtp();
            return;
        }
        this.callOtpVerify();
    }

    async callRequestOtp() {
        const { website_id, updateMobileOTP, mobileNumber } = this.props;
        const params = {
            newMobileNumber: this.getMobileNumber(),
            oldMobileNumber: mobileNumber,
            websiteId: website_id

        };

        await updateMobileOTP(params);
    }

    async callOtpVerify() {
        const { website_id, updateMobileOTPVerify, mobileNumber } = this.props;
        const { otp } = this.state;
        const params = {
            newMobileNumber: this.getMobileNumber(),
            oldMobileNumber: mobileNumber,
            otp: otp.toString(),
            websiteId: website_id
        };

        this.setState({ otp: '' });
        await updateMobileOTPVerify(params).then(
            /** @namespace Lifely/UpdateMobileno/Component/UpdateMobilePopup/Container/updateMobileOTPVerify/then */
            (response) => {
                this.setState({ hasError: !response });
            }
        );
    }

    render() {
        return (
            <UpdateMobilePopup
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMobilePopupContainer);
