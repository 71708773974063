/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import {
    AddToCart as SourceAddToCart
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.style';

/** @namespace LifelyScandi/Component/AddToCart/Component/AddToCartComponent */
export class AddToCartComponent extends SourceAddToCart {
    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            layout,
            disabled,
            isMobile,
            isCartPageLinkedProduct
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        return (
            <button
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading, layout } }
              disabled={ isLoading || disabled }
            >
                { this.renderCartIcon() }
                { isMobile && !disabled && isCartPageLinkedProduct && <span>{ isLoading ? __('+') : '+' }</span> }
                { isMobile && !disabled && !isCartPageLinkedProduct && <span>{ isLoading ? __('Adding...') : 'Add to cart' }</span> }
                { isMobile && !isLoading && <span>{ disabled ? __('Out of Stock') : null }</span> }
                { !isMobile && !disabled && isCartPageLinkedProduct && <span>{ isLoading ? __('+') : '+' }</span> }
                { !isMobile && !disabled && !isCartPageLinkedProduct && <span>{ isLoading ? __('Adding...') : __('Add to cart') }</span> }
                { !isMobile && !isLoading && <span>{ disabled ? __('Out of Stock') : null }</span> }
            </button>
        );
    }
}

export default AddToCartComponent;
