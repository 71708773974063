import SignupReducer from '../store/Signup/Signup.reducer';
import SignInReducer from '../store/SignIn/SignIn.reducer';

const getStaticReducers = (args, callback, instance) => ({
    ...callback(...args),
    SignupReducer,
    SignInReducer
 });

export default {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};
