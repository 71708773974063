/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import LifelyStoryDesktop from '../LifelyStoryDesktop';
import LifelyStoryMobile from '../LifelyStoryMobile';

import './AboutLifelyBrandInDetail.style';

/** @namespace LifelyScandi/Component/Custom/AboutLifelyBrandInDetail/Component/AboutLifelyBrandInDetailComponent */
export class AboutLifelyBrandInDetailComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const { isMobile } = this.props;
        return (
            <div block="AboutLifelyBrandInDetail">
                { isMobile && (
                    <LifelyStoryMobile />
                ) }
                { !isMobile && (
                    <LifelyStoryDesktop />
                ) }
            </div>
        );
    }
}

export default AboutLifelyBrandInDetailComponent;
