const trimCustomerAddress = (args, callback) => {
    const _args = { ...args[0] };

    let delivery_preferance_sat = false;
    if (_args.delivery_preferance_sat) {
        delivery_preferance_sat = Boolean(_args.delivery_preferance_sat);
    }

    let delivery_preferance_sun = false;
    if (_args.delivery_preferance_sun) {
        delivery_preferance_sun = Boolean(_args.delivery_preferance_sun);
    }

    const customFields = {
        address_instructions: _args.address_instructions,
        address_security_code: _args.address_security_code,
        delivery_preferance_sat,
        delivery_preferance_sun
    };

    return {
        ...callback(...args),
        ...customFields
    };
};

export default {
    'Util/Address/trimCustomerAddress': {
        function: trimCustomerAddress
    }
};
