import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckEstimatedDelivery from './CheckEstimatedDelivery.component';

/** @namespace LifelyScandi/Component/Custom/CheckEstimatedDelivery/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace LifelyScandi/Component/Custom/CheckEstimatedDelivery/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace LifelyScandi/Component/Custom/CheckEstimatedDelivery/Container/CheckEstimatedDeliveryContainer */
export class CheckEstimatedDeliveryContainer extends PureComponent {
    static propTypes = {
        isIntoCard: PropTypes.bool
    };

    static defaultProps = {
        isIntoCard: false
    };

    state = {
        enteredPincode: '',
        description: {},
        isSucceed: false,
        isLoading: false
    };

    containerFunctions = {
        handlePinCodeChange: this.handlePinCodeChange.bind(this),
        checkEstimateLocation: this.checkEstimateLocation.bind(this),
        changePinInput: this.changePinInput.bind(this)
    };

    containerProps() {
        const {
            enteredPincode, message, isSucceed, isLoading, description
        } = this.state;
        const { isIntoCard } = this.props;

        return {
            enteredPincode,
            isIntoCard,
            message,
            isSucceed,
            isLoading,
            description
        };
    }

    handlePinCodeChange(e) {
        this.setState({ enteredPincode: e.target.value });
    }

    async checkEstimateLocation() {
        const { enteredPincode } = this.state;
        this.setState({ message: enteredPincode });
        this.setState({ isLoading: true });
        const response = await fetch(
            `/rest/V1/custom/pickrr-check-pincode-service-api?from_pincode=110065&to_pincode=${ enteredPincode }`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({ data: { name: enteredPincode } })
            }
        );
        const data = await response.json();
        const parsedData = await JSON.parse(data);

        this.setState({ isLoading: false });
        if (parsedData) {
            const { message, success } = parsedData;
            this.setState({ isSucceed: success });
            this.setState({ description: message });
        }
    }

    changePinInput() {
        this.setState({ enteredPincode: '' });
        this.setState({ message: '' });
    }

    render() {
        return (
            <CheckEstimatedDelivery
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckEstimatedDeliveryContainer);
