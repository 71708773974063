import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuItemContainer as SourceMenuItemContainer
} from 'SourceComponent/MenuItem/MenuItem.container';

/** @namespace LifelyScandi/Component/MenuItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace LifelyScandi/Component/MenuItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace LifelyScandi/Component/MenuItem/Container/MenuItemContainer */
export class MenuItemContainer extends SourceMenuItemContainer {
    state={
        secondLevelItemId: ''
    };

    containerProps() {
        const {
            activeMenuItemsStack,
            isExpandable,
            isLink,
            item,
            itemMods,
            isMobile,
            isSubLevelItems,
            isFirstLevelItems
        } = this.props;
        const { secondLevelItemId } = this.state;
        return {
            activeMenuItemsStack,
            isExpandable,
            isLink,
            item,
            itemMods,
            isMobile,
            mobileMenuItemCardAction: this.mobileMenuItemCardAction.bind(this),
            secondLevelItemId,
            isSubLevelItems,
            isFirstLevelItems
        };
    }

    mobileMenuItemCardAction(item) {
        const { secondLevelItemId } = this.state;
        const { item_id } = item;
        if (item_id === secondLevelItemId) {
            this.setState({ secondLevelItemId: '' });
        }
        if (item_id !== secondLevelItemId) {
            this.setState({ secondLevelItemId: item_id });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
