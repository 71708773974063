/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EditIcon.style';

/** @namespace LifelyScandi/Component/Custom/Icons/EditIcon/Component/EditIconComponent */
export class EditIconComponent extends PureComponent {
    static propTypes = {
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string
    };

    static defaultProps = {
        fill: '#000000',
        width: '25',
        height: '25'
    };

    render() {
        const { fill, width, height } = this.props;
        return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={ width }
              viewBox="0 0 37.5 37.5"
              height={ height }
              version="1.0"
            >
                <defs>
                    <clipPath id="edit1">
                        <path d="M 3.75 6 L 32 6 L 32 33.75 L 3.75 33.75 Z M 3.75 6" />
                    </clipPath>
                    <clipPath id="edit2">
                        <path d="M 26 3.75 L 33.75 3.75 L 33.75 12 L 26 12 Z M 26 3.75" />
                    </clipPath>
                </defs>
                <g clipPath="url(#edit1)">
                    <path
                      fill={ fill || '#737373' }
                      d="M 28.941406 31.460938 L 6.039062 31.460938 L 6.039062 8.558594 L 19.78125 8.558594 L 19.78125 6.269531 L 6.039062 6.269531 C 4.777344 6.269531 3.75 7.296875 3.75 8.558594 L 3.75 31.460938 C 3.75 32.722656 4.777344 33.75 6.039062 33.75 L 28.941406 33.75 C 30.203125 33.75 31.230469 32.722656 31.230469 31.460938 L 31.230469 17.71875 L 28.941406 17.71875 Z M 28.941406 31.460938"
                    />
                </g>
                <path
                  fill={ fill || '#737373' }
                  d="M 12.910156 24.625 L 17.730469 24.625 L 29.414062 12.941406 L 24.558594 8.085938 L 12.910156 19.734375 Z M 12.910156 24.625"
                />
                <g clipPath="url(#edit2)">
                    <path
                      fill={ fill || '#737373' }
                      d="M 32.851562 6.269531 L 31.230469 4.648438 C 30.335938 3.753906 28.886719 3.753906 27.992188 4.648438 L 26.175781 6.464844 L 31.035156 11.324219 L 32.851562 9.507812 C 33.746094 8.613281 33.746094 7.164062 32.851562 6.269531 Z M 32.851562 6.269531"
                    />
                </g>
            </svg>
        );
    }
}

export default EditIconComponent;
