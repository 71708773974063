/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MobileMenuPanel from '../MobileMenuPanel';

import './MobileMenuAccordian.style';

/** @namespace LifelyScandi/Component/Custom/MobileMenuAccordian/Component/MobileMenuAccordianComponent */
export class MobileMenuAccordianComponent extends PureComponent {
    static propTypes = {
    };

    state = {
        activeTab: null
    };

    activateTab(index) {
        this.setState((prev) => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }

    render() {
        const { panels } = this.props;
        const { activeTab } = this.state;
        return (
            <div block="MobileMenuAccordian">
                <div className="mobileMenuAccordian" role="tablist">
                    { panels && panels.length > 0 && panels.map((panel, index) => (
                        <MobileMenuPanel
                          key={ Math.random() }
                          activeTab={ activeTab }
                          index={ index }
                            //   title={ panel.title || '' }
                            //   contentImage={ panel.contentImage || '' }
                            //   titleImage={ panel.titleImage || '' }
                            //   content={ panel.content || '' }
                            //   url={ panel.url || '' }
                            //   icon={ panel.icon || '' }
                          panel={ panel }
                          activateTab={ this.activateTab.bind(this, index) }
                        />
                    )) }
                </div>
            </div>
        );
    }
}

export default MobileMenuAccordianComponent;
